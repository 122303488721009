<template>
  <div class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main>
        <div class="Portfolio_container">
          <PortfolioEdit/>
        </div>

      </el-main>
    </el-container>
    <BottomMenu/>
  </div>
</template>

<script>
// import MasonryGrid from '../components/MasonryGrid.vue';

import BottomMenu from "@/components/BottomMenu.vue";
import PortfolioEdit from "@/components/PortfolioEdit.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";

export default {
  components: {
    LeftSidebar,
    PortfolioEdit,
    BottomMenu,
  },
  data() {
    return {

    };
  }
};
</script>

<style scoped>
.img_container{
  max-width: 967px;
  margin: 30px auto;
}

@media (max-width: 767px) {
  .el-main {
    --el-main-padding: 0px !important;
    box-sizing: border-box;
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    padding: 10px 0px !important;
  }
}

</style>

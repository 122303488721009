<template>
  <div class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main >
        <div class="img_container">
          <ImgTest/>
        </div>

      </el-main>
    </el-container>
    <BottomMenu/>

  </div>
</template>

<script>
// import MasonryGrid from '../components/MasonryGrid.vue';
import ImgTest from "@/components/ImgTest.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";

export default {
  components: {
    LeftSidebar,
    BottomMenu,
    ImgTest,
  },
  data() {
    return {

    };
  }
};
</script>

<style>

</style>
<style scoped>
.img_container{
  margin: 30px auto;
}

@media (min-width: 1001px) {
  .img_container{
    max-width: 1000px;
  }
}
@media (max-width: 1000px) {
  .img_container {
    margin: 30px 0px !important;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .el-main {
    --el-main-padding: 0px !important;
    box-sizing: border-box;
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    padding: 10px 0px !important;
  }
}



</style>

<template>
  <div class="profile-edit-container">
    <!-- 用户信息 -->
    <div class="profile-header">
      <el-avatar :src="form.avatarUrl" class="avatar"></el-avatar>
      <div class="user-info">
        <p class="username">未绑定微信</p>
<!--        <p class="sub-username">{{ form.nickname }}</p>-->
      </div>
      <el-button type="primary" size="large" class="change-avatar-btn" @click="goBindWechat()">
        扫码绑定
      </el-button>
    </div>
  </div>
</template>

<script>
import {getRequest} from "@/api/api";
export default {

  name: 'EditProfile',
  data() {
    return {
      form: {
        avatarUrl: require('@/assets/hwk_1.png'), // 初始头像
        username: 'daguye918', // 示例用户名
        nickname: 'daguye', // 示例昵称
        bio: '',
        gender: 'unknown', // 初始性别
      },
    };
  },
  methods: {
    goBindWechat() {
      getRequest("/auth/wechat/redirect-url",{})
          .then(res=>{
            if(res.url){
              window.location.href = res.url;
            }
          })
    },
  },
};
</script>

<style scoped>
.el-textarea .el-input__count{
  background: none !important;
}
.zy{
  font-size: 19px;
  font-weight: 800;
  color: var(--text-color);
  margin-bottom: 40px;
}
.btn_sub{
  width: 253px !important;
  height: 44px !important;
  color: #fff !important;
  background: #0095f6 !important;
  font-size: 16px !important;
  border: none !important;
}
.profile-form .el-form-item__label{
  justify-content: flex-start !important;
}
.username {
  font-size: 18px;
  color: var(--text-color);
}
@media (max-width: 768px){
  .profile-header {
    padding: 0 10px !important;
  }
  .username{
    font-size: 13px !important;
  }
}
.profile-form .el-select__wrapper{
  background-color: var(--background-color) !important;
  height: 53px !important;
}
.el-select-dropdown__item.is-hovering {
  background-color: rgb(60,60,60) !important;
}
.el-select-dropdown__item.is-hovering span {
  color: #fff !important;
}
.el-popper__arrow {
  display: none !important;
}
.el-select__placeholder{
  color: var(--text-color) !important;
}
.el-select__placeholder span {
  color: var(--text-color) !important;
}
.profile-edit-container {
  max-width: 750px;
  margin: 25px auto;
  padding: 20px;
  background-color: var(--background-color);
}
.profile-header {
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0 20px;
  background-color: var(--set-header-bg);
  border-radius: 8px;
  position: relative;
  margin-bottom: 30px;
}

.avatar {
  margin-right: 15px;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.username {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: var(--text-color);
}

.sub-username {
  color: #aaa;
  margin-top: 2px;
}

.change-avatar-btn {
  position: absolute;
  right: 20px;

  border: none;
}

.change-avatar-btn:hover {

}

.profile-form {
  width: 100%;
}

.el-form-item {
  flex-direction: column !important;
  margin-bottom: 18px;
}

.input-hint {
  font-size: 0.85em;
  color: #888;
  margin-top: 4px;
}

.submit-btn {
  width: 100%;
}

.submit-btn:hover {
}
</style>

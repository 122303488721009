<template>
  <el-drawer
      title="搜索"
      :model-value="visible"
      custom-class="recommend-drawer"
      direction="ltr"
      :show-close="false"
      size="400px"
      @close="handleClose"
      append-to-body
      :z-index="1001"
      :modal="true"
      :modal-append-to-body="true"
  >
    <div class="drawer-content " @click.stop>
      <SearchComponent/>
    </div>
  </el-drawer>
</template>

<script>
import SearchComponent from "@/components/SearchComponent.vue";

export default {
  name: "SearchDrawer",
  components: {SearchComponent},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:visible"],
  data() {
    return {
      recommendedUsers: [
        {
          username: "tnt1111117",
          avatar: "https://i.pravatar.cc/150?img=5",
          info: "已关注 tnt_songyaxuan0304 和其他人",
        },
        {
          username: "cc141208z",
          avatar: "https://i.pravatar.cc/150?img=8",
          info: "已关注 xiaozhan_911005",
        },
        {
          username: "hu.hu6556",
          avatar: "https://i.pravatar.cc/150?img=10",
          info: "已关注 xiaozhan_911005",
        },
        // 添加更多推荐用户
      ],
    };
  },
  methods: {

    handleClose() {
      this.$emit("update:visible", false); // 通知父组件更新 `visible` 状态
    },
  },
};
</script>
<style>
.drawer-content {
  z-index: 900 !important; /* 确保低于菜单 */
}
</style>
<style scoped>

.recommend-drawer {
  padding: 0;
  border-left: var(--left-sidebar-border-right);
  left: 60px !important;
  box-shadow: var(--el-drawer-box-shadow) !important;
}
.el-overlay {
  background-color: transparent !important;
}

.drawer-content {
  padding: 20px 2px;
  box-sizing: border-box;
}

.notification {
  text-align: center;
  margin-bottom: 20px;
}

.icon-heart {
  color: #888;
}

.recommendations {
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

.user-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.user-info {
  flex: 1;
  margin-left: 10px;
}

.username {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: -7px;
}

.follow-info {
  color: #888;
  font-size: 12px;
}

.el-button {
  margin-left: auto;
}
</style>

<template>
  <div class="new_plan">
    <el-dialog
        class="theme_bg"
        v-model="isVisible"
        :width="dialogWidth"
        @close="closeModal"
        @open="initStatus"
        :title="is_alone?'创建独立作品':'创建新计划'"
    >
        <el-form :model="form" :rules="rules" ref="planForm" label-width="120px">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="form.name" :maxlength="500" show-word-limit/>
          </el-form-item>

          <el-form-item label="项目介绍" prop="intro">
            <el-input type="textarea" v-model="form.intro" rows="4" :maxlength="500" show-word-limit/>
          </el-form-item>

          <el-switch style="margin-bottom: 15px;"
              v-model="isPlan"
              active-text="计划"
              inactive-text="独立作品"
          ></el-switch>

          <div class="plan_time">
            <div class="plan_time_left">
              <transition name="fade">
                <div v-if="!is_alone">


                  <el-form-item label="开始时间" prop="startDate">
                    <el-date-picker
                        v-model="form.startDate"
                        type="date"
                        value-format="YYYY-MM-DD"
                        placeholder="选择开始日期"
                        style="width: 100%;"
                        :disabled="is_alone || isEdit"
                    />
                  </el-form-item>

                  <!-- 更新间隔和更新次数字段 -->
                  <el-form-item label="更新间隔(天)" prop="updateInterval">
                    <el-input-number
                        v-model="form.updateInterval"
                        :min="1"
                        placeholder="每几天更新一次"
                        :disabled="is_alone || isEdit"
                    />
                  </el-form-item>

                  <el-form-item label="更新次数" prop="updateCount">
                    <el-input-number
                        v-model="form.updateCount"
                        :min="1"
                        placeholder="总共更新几次"
                        :disabled="is_alone || isEdit"
                    />
                  </el-form-item>

                  <el-form-item label="预计完成时间">
                    <p style="margin-top: 0px">
                      预计总天数：{{ estimatedDays }} 天<br/>预计完成日期：{{ estimatedEndDate }}
                    </p>
                  </el-form-item>
                  <el-form-item label="当前所在时区">
                    <p style="margin-top: 0px">
                      {{ timeZone }}，{{now_time}}，计划将以此时区为准，请注意时区差异
                    </p>
                  </el-form-item>
                </div>
              </transition>
            </div>
            <div class="plan_time_right"  v-if="!is_alone">
              <!--       新建/编辑计划表单 -->
              <TimeComponent
                  :startDate="form.startDate"
                  :uploadCount="form.updateCount"
                  :intervalDays="form.updateInterval"
              />
            </div>
          </div>
        </el-form>

        <div class="form-actions" style="margin-top: 20px;text-align: right">
          <el-button type="info" @click="backToList">返回</el-button>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from 'element-plus';
import defaultCover from '@/assets/default-cover.png';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {createPlanRequest} from "@/api/api";
import TimeComponent from "@/components/TimeComponent.vue";
import {modalStore} from "@/stores/modalStore";
import { mapActions, mapState } from 'pinia';
export default {
  name: 'NewPlanModal',
  components: {
    TimeComponent,
  },
  props: {
  },
  watch: {
    'form.startDate': 'calculateEstimatedValues',
    'form.updateInterval': 'calculateEstimatedValues',
    'form.updateCount': 'calculateEstimatedValues',
    is_alone(is_plan_val) {
      this.form.isPlan = !is_plan_val
      this.isPlan=!is_plan_val
    },
    isPlan(new_status){
      this.form.isPlan = !new_status
      this.is_alone=!new_status
    }
  },

  data() {
    return {
      dialogWidth: '60%',
      is_alone:this.isIndependent,
      isPlan:!this.is_alone,
      isEdit: false,
      form: {
        id: null,
        name: '',
        cover: '',
        coverFileList: [],
        isPlan: this.isPlan,
        intro: '',
        startDate: null,
        updateInterval: null, // 更新间隔（天）
        updateCount: null, // 更新次数
        days: 0,
        endDate: '',
      },
      estimatedDays: 0, // 预计总天数
      estimatedEndDate: '未填写完整', // 预计完成日期
      rules: {
        name: [{required: true, message: '项目名称不能为空', trigger: 'blur'}],
        intro:[{required: true, message: '项目介绍不能为空', trigger: 'blur'}],
        startDate: [{type: 'date', required: true, message: '请选择开始日期', trigger: 'change'}],
        updateInterval: [{required: true, message: '请输入更新间隔', trigger: 'change'}],
        updateCount: [{required: true, message: '请输入更新次数', trigger: 'change'}],
      },
      defaultCover: defaultCover,
      newPlanId: null, // 新增的计划ID
      timeZone: 'Asia/Shanghai',
      now_time: dayjs.tz(),
    };
  },
  computed: {
    ...mapState(modalStore, ['isShowPlan','isIndependent','selectPlan']),
    isVisible() {
      return this.isShowPlan;
    },
  },
  mounted() {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    this.timeZone=dayjs.tz.guess();
    this.now_time=dayjs.tz().format('YYYY-MM-DD HH:mm:ss');
  },
  methods: {
    ...mapActions(modalStore, ['changeIsIndependent','hidePlanModal','showCreateModal','showPlanMenuModal','setSelectPlan']),
    initStatus(){
      this.is_alone=this.isIndependent
    },
    closeModal() {
      this.hidePlanModal();
    },
    getWidthValue() {
      return window.innerWidth < 768 ? '360px' : '400px';
    },
    handleResize() {
      this.widthValue = this.getWidthValue();
    },
    calculateEstimatedValues() {
      // 计算预计天数
      if (this.form.updateInterval && this.form.updateCount) {
        // 新的计算方式：预计天数 = 更新间隔 * (更新次数 - 1) + 1
        this.estimatedDays = this.form.updateInterval * (this.form.updateCount - 1) + 1;
        this.form.days = this.estimatedDays; // 同时更新表单中的天数字段
      } else {
        this.estimatedDays = 0;
      }

      // 计算预计完成日期
      if (this.form.startDate && this.form.updateInterval && this.form.updateCount) {
        this.estimatedEndDate = dayjs.tz(this.form.startDate)
            .add(this.estimatedDays-1, 'day') // 减去 1 天，因为开始日期算作第 1 天
            .format('YYYY-MM-DD');
        this.form.endDate = this.estimatedEndDate; // 同时更新表单中的结束日期字段
      } else {
        this.estimatedEndDate = '未填写完整';
      }
    },

    backToList() {
      this.showPlanMenuModal();
      this.hidePlanModal();
    },
    submitForm() {
      this.$refs.planForm.validate((valid) => {
        if (valid) {
          const newPlan = {
            ...this.form,
            isPlan:this.isPlan,
            days: this.estimatedDays,
            endDate: this.estimatedEndDate,
          };
          // 新建计划
          newPlan.isModified = false; // 新计划默认未被修改
          newPlan.progress = 0; // 新计划默认进度为 0%
          newPlan.status = 0;

          //将开始日期换算成对应时区的时间戳
          newPlan.date_start = dayjs.tz(newPlan.startDate).startOf('day').unix();
          createPlanRequest(newPlan).then(res => {
            this.newPlanId = res.id; // 设置新计划的ID
            newPlan.id = res.id;
            this.setSelectPlan(res);
            ElMessage.success('计划已保存');
            // 关闭自己的弹窗
            this.$nextTick(() => {
              this.showCreateModal();
              this.hidePlanModal();
            });
          })
        } else {
          ElMessage.error('请完善表单信息');
          return false;
        }
      });
    },

    resetForm() {
      this.form.id = null;
      this.form.name = '';
      this.form.cover = '';
      this.form.coverFileList = [];
      this.form.isPlan = false;
      this.form.intro = '';
      this.form.startDate = null;
      this.form.updateInterval = null;
      this.form.updateCount = null;
      this.form.days = 0;
      this.form.endDate = '';
      this.estimatedDays = 0;
      this.estimatedEndDate = '未填写完整';
    },
  },

  created() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {  // Vue 3 中使用 beforeUnmount 代替 beforeDestroy
    window.removeEventListener('resize', this.handleResize);  // 移除监听
  },


};
</script>


<style>
.new_plan .el-form-item__label{
  color: var(--text-color);
  opacity: 0.7;
}
.el-textarea__inner {
  background-color: var(--background-color) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

/* 更改日期选择器面板的背景颜色 */
.el-picker-panel {
  background-color: #2c2c2c; /* 将 #f0f0f0 替换为你想要的背景颜色 */
}

/* 如果你想更改日期选择器中日期的背景颜色 */
.el-date-table td.available {
  background-color: var(--background-color); /* 将 #ffffff 替换为你想要的背景颜色 */
}

.el-date-table td.current {
  background-color: #cce4ff; /* 将 #cce4ff 替换为当前日期的背景颜色 */
}

::v-deep .new_plan .el-picker-panel__body-wrapper {
  background: var(--background-color) !important;
  border: 1px solid #666666;
}


@media (max-width: 768px) {
  .new_plan_two {
    width: 50%;
  }
}

.step_1_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  .step_1_content{
    flex-direction: column;
  }


}


.new_plan .el-dialog__close {
  background: #666666 !important;
  color: #fff !important;
  top: -7px;
  position: absolute;
  right: -13px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.new_plan .el-dialog__headerbtn svg {
  fill: var(--text-color) !important;
}

.new_plan .el-picker-panel__body-wrapper {
  background: var(--background-color) !important;
}

.el-picker-panel__icon-btn .el-icon {
  color: var(--text-color) !important;
}

.plan_lb {
  position: relative;
}


.new_plan .el-carousel__item {
  left: 0px;
}

.new_plan .el-overlay-dialog {
  background: rgba(0, 0, 0, 0.6);
}

.new_plan .el-carousel__arrow--right {
  right: 0px !important;
}

.new_plan .el-carousel__arrow--left {
  left: 0px !important;
}

.new_plan .el-dialog {
  background: var(--background-color) !important;
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.2) !important;
  color: var(--text-color);
  max-width: 1200px;
}

@media (max-width: 800px) {
  .new_plan .el-dialog {
    width: 100% !important;
  }
}
</style>
<style scoped>
.plan_time {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.plan_time_left {
  width: 50%;
}

.plan_time_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1000px) {
  .plan_time {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .plan_time_left {
    width: 100%;
  }

  .plan_time_right {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.pl_intrs {
  height: 25px;
  line-height: 25px;
  margin: 0px;
}

.pl_name {
  height: 25px;
  line-height: 25px;
  margin: 0px;
}

.plan-list .el-carousel.el-carousel--horizontal {
  width: 100%;
}

.plan-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 60%;
}


.modified-label {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 0px;
  height: 32px;
  width: 32px;
  display: flex;
}









.plan-cover {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
}

.plan-info {
  text-align: center;
  margin-top: 10px;
}

.actions {
  text-align: center;
  margin-top: 10px;
}
</style>

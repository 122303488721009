// src/stores/messageStore.js
import { defineStore } from 'pinia';

export const modalStore = defineStore('modalStore', {
    state: () => ({
        isShowCreate: false,
        isShowPlan: false,
        isShowPlanMenu: false,
        isShowEditPlan: false,
        isShowNewMsg: false,
        myPlanList:[],
        selectPlan:{},//选择的计划信息
        isIndependent:false,//是否独立作品
        select_node_info:{},//选择的节点信息
        select_node_id:0,
        edit_node_info:{},//编辑的节点信息
        edit_node_id:0,
        isShowEditNode:false,
    }),
    actions: {
        setEditNodeId(id){
            this.edit_node_id=id;
        },
        setEditNodeInfo(edit_node_info){
            this.edit_node_info=edit_node_info;
        },
        showEditNodeModal(){
            this.isShowEditNode=true;
        },
        hideEditNodeModal(){
            this.isShowEditNode=false;
        },
        getEditNodeInfo(){
            return this.edit_node_info;
        },
        setSelectPlan(selectPlan){
            this.selectPlan=selectPlan;
        },
        clearSelectPlan(){
            this.selectPlan={};
        },
        getSelectPlan(){
            return this.selectPlan;
        },
        setMyPlanList(myPlanList){
            this.myPlanList=myPlanList;
        },
        getMyPlanList(){
            return this.myPlanList;
        },
        changeIsIndependent(newValue){
            this.isIndependent=newValue;
        },
        showNewMsgModal(){
            this.isShowNewMsg=true;
        },
        hideNewMsgModal(){
            this.isShowNewMsg=false;
        },

        // 显示创建计划
        showPlanModal(isIndependent) {
            this.isIndependent=isIndependent;
            this.isShowPlan=true;
        },
        hidePlanModal() {
            this.isShowPlan=false;
        },

        // 显示创建计划
        showEditPlanModal(isIndependent) {
            this.isIndependent=isIndependent;
            this.isShowEditPlan=true;
        },
        hideEditPlanModal() {
            this.isShowEditPlan=false;
        },

        // 显示创建计划
        showPlanMenuModal() {
            this.isShowPlanMenu=true;
        },
        hidePlanMenuModal() {
            this.isShowPlanMenu=false;
        },
        setSelectNodeInfo(select_node_info){
            this.select_node_info=select_node_info;
        },
        setSelectNodeId(id){
            this.select_node_id=id;
        },
        // 显示创建计划
        showCreateModal() {
            this.isShowCreate=true;
        },
        hideCreateModal() {
            this.isShowCreate=false;
        }
    },
});

<template>
  <div class="chat-container">
    <!-- 用户列表 -->
    <NewMessageModal  @start-chat="startChat"/>
    <div class="user-list">
      <div class="new_btn" @click="openModal">
        新建消息
        <svg aria-label="新消息" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>新消息</title><path d="M12.202 3.203H5.25a3 3 0 0 0-3 3V18.75a3 3 0 0 0 3 3h12.547a3 3 0 0 0 3-3v-6.952" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><path d="M10.002 17.226H6.774v-3.228L18.607 2.165a1.417 1.417 0 0 1 2.004 0l1.224 1.225a1.417 1.417 0 0 1 0 2.004Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="16.848" x2="20.076" y1="3.924" y2="7.153"></line></svg>
      </div>

      <h3>用户列表</h3>
      <div v-if="chats.length>0">
        <div
            v-for="user in chats"
            :key="user.id"
            @click="selectUser(user)"
            :class="['user_chat', { active: selectedChat && selectedChat.id === user.id}]"
        >
          <el-avatar  :src="user.user.avatar?user.user.avatar:ava_bg" size="small"></el-avatar>
          <span class="user-name" v-if="user.sender.id===user_info.id">{{ user.receiver.name }}</span>
          <span class="user-name" v-else>{{ user.sender.name }}</span>
        </div>
      </div>
      <div v-else>暂无对话</div>
    </div>

    <!-- 消息界面 -->
    <div class="message-container" v-if="selectedChat">
      <h3 class="chat_per">与 {{ selectedChat.user.name }} 的对话</h3>

      <!-- 消息列表 -->
      <div class="message-list">
        <div v-for="message in message_list" :key="message.id" :class="['message', message.sender_id===user_info.id?'self':'other']">
          <div class="message-text">{{ message.content }}</div>
          <small class="timestamp">{{ message.created_time }}</small>
        </div>
      </div>

      <!-- 输入框和发送按钮 -->
      <div class="message-input">
        <!-- 表情选择按钮 -->
        <button @click="toggleEmojiPicker" class="emoji-button" style="margin-right: 3px;">
          <svg data-v-4d9c2806="" class="icon icon_bq" viewBox="0 0 1040 1024" xmlns="http://www.w3.org/2000/svg" p-id="20459" width="24" height="24"><path data-v-4d9c2806="" d="M512.075261
                 1024A511.774217 511.774217 0 1 1 730.482434
                 48.769072a37.630457 37.630457 0 1 1-32.061149
                 68.035867 436.513303 436.513303 0 1 0
                 250.468323 395.270322 37.630457 37.630457 0 0 1
                 75.260914 0 512.526826 512.526826 0 0
                 1-512.075261 511.924739z" fill="" p-id="20460"></path><path data-v-4d9c2806="" d="M333.857416
                 344.0929a57.348817 57.348817 0 1 0 57.348817
                 57.348817 57.499339 57.499339 0 0
                 0-57.348817-57.348817zM686.53006
                 344.0929a57.348817 57.348817 0 1 0 57.348817
                 57.348817 57.348817 57.348817 0 0
                 0-57.348817-57.348817zM515.236219
                 783.165074c-162.864619
                 0-262.359547-141.942084-262.359547-219.159782a30.104366
                 30.104366 0 0 1 60.208731 0c0
                 48.618551 76.314567 158.951051
                 202.150816 158.951051s193.571072-134.114949
                 193.571072-158.951051a30.104366 30.104366 0 0
                 1 60.208731 0c0 54.488902-90.012054
                 219.159782-253.779803
                 219.159782zM1009.549904
                 207.720123h-67.132735V139.985301a30.104366
                 30.104366 0 1 0-60.208732
                 0v67.734822h-67.734822a30.104366 30.104366
                 0 0 0-30.104366 30.104366 30.104366
                 30.104366 0 0 0 30.104366
                 30.104366h67.734822v67.734823a30.104366
                 30.104366 0 0 0 60.208732
                 0v-67.734823h67.734823a30.104366 30.104366
                 0 0 0 30.104365-30.104366 30.104366
                 30.104366 0 0 0-30.706453-30.104366z" fill="" p-id="20461"></path></svg>
        </button>
        <el-input
            type="text"
            v-model="newMessage"
            placeholder="发送消息..."
            @keyup.enter="handleSend"
            :maxlength="500" show-word-limit
        ></el-input>
        <el-button type="primary" @click="handleSend">发送</el-button>

        <!-- 表情选择器 -->
        <emoji-picker
            v-if="showEmojiPicker"
            ref="emojiPicker"
            @emoji-click="addEmoji"
            style="position: absolute; bottom: 60px; left: 10px; z-index: 1000;"
        ></emoji-picker>
      </div>
    </div>
  </div>
</template>

<script>
import 'emoji-picker-element';
import { messageHistoryRequest, messageSendRequest, RecentConversationsRequest} from "@/api/api";
import NewMessageModal from "@/components/NewMessageModal.vue";
import {modalStore} from "@/stores/modalStore";
import {mapActions} from "pinia";
import {useMessageStore} from "@/stores/messageStore";
import ava_bg from "@/assets/ava_bg.png";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {ElInput} from "element-plus";
dayjs.extend(utc)
dayjs.extend(timezone)
export default {
  name: 'ChatComponent',
  components: {ElInput, NewMessageModal},
  data() {
    return {
      newMessage: '', // 输入框的消息内容
      user_info:JSON.parse(localStorage.getItem('user_info')),
      showEmojiPicker: false, // 控制 Emoji Picker 的显示状态
      message_list:[],
      selectChatInfo:{},
      select_user_id:0,
      ava_bg:ava_bg,
      timezone: 'utc',
    };
  },
  computed: {
    chatStore() {
      return useMessageStore();
    },
    chats() {
      return this.chatStore.chats;
    },
    selectedChat() {
      return  this.chatStore.chats.find(chat => chat.user.id ===  this.chatStore.selectedChatId);
    },

  },
  watch: {
    selectedChat(newChat) {
      if (newChat) {
        this.getChatHistory(newChat);
      }
    }
  },
  mounted() {
    this.timezone = dayjs.tz.guess();
    this.chatStore.getChats();
  },
  methods: {
    ...mapActions(modalStore, ['showNewMsgModal']),
    openModal() {
      this.showNewMsgModal();
    },
    // 处理从 NewMessageModal 传来的开始聊天事件
     startChat (user){
      const existingChat = this.chatStore.chats.find(chat => chat.user.id === user.id);
      if (existingChat) {
        this.chatStore.selectChat(existingChat.user.id);
      } else {
        const newChat = this.chatStore.createChat(user);
        this.chatStore.selectChat(newChat.user.id);
      }
    },
    getChatUsers(){
      RecentConversationsRequest().then(res=>{
        if(res.code===200){
          this.users=res.data;
          if(this.users.length>0){
            if(this.users[0].sender.id===this.user_info.id){
              this.selectedUser=this.users[0].receiver;
            }else{
              this.selectedUser=this.users[0].sender;
            }

            this.getChatHistory(this.selectedUser);
          }
        }
      })
    },
    getChatHistory(user){
      messageHistoryRequest({user_id:user.user.id}).then(res=>{
        if(res.code===200){
          //倒序显示
          this.message_list=res.data.data.reverse();
          this.message_list.forEach(item=>{
            item.created_time = dayjs.utc(item.created_at).tz(this.timezone).format('MM-DD HH:mm');
          })
        }
      })
    },
    // 选择用户
    selectUser(user) {
        this.chatStore.selectChat(user.user.id);
        this.newMessage = ''; // 清空输入框
    },

    // 发送消息
    handleSend() {
      if (this.newMessage.trim() === '' || !this.selectedChat) return;
      if(this.newMessage.trim().length>=1000){
        this.$message.error('消息长度不能超过1000个字符');
        return;
      }
      messageSendRequest({receiver_id:this.selectedChat.user.id,content:this.newMessage}).then(res=>{
        if(res.code===200){
          this.getChatHistory(this.selectedChat);
        }
      })

      // 清空输入框
      this.newMessage = '';

    },

    // 显示或隐藏 Emoji Picker
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },

    // 添加表情到消息输入框，并隐藏 Emoji Picker
    addEmoji(event) {
      this.newMessage += event.detail.unicode;
      this.showEmojiPicker = false; // 选择表情后关闭表情选择器
    },
  },

};
</script>



<style scoped>
.chat_list{
  margin-left: 10px;
}
.icon_bq {
  fill: var(--text-color) !important;
}
.chat_per{
  margin-left: 20px;
}
.new_btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}
.new_btn:hover{
  background: #444;
  color: #fff;
}
.chat-container {
  display: flex;
  height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
}

.user-list {
  width: 25%;
  min-width: 250px;
  padding: 10px;
  background-color: var(--background-color);
  border-right: 1px solid #333;
}

.user_chat{
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  gap: 5px;
}

.user_chat.active {
  background-color: #444;
  color: #fff;
}
.user_chat.active .user-name {
  color: #fff;
}
.message-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;  /* 增加消息之间的间距 */
  word-wrap: break-word;  /* 让文本换行 */
  max-width: 100%;  /* 限制宽度 */
}

.message.self {
  align-items: flex-end;
}

.message.other {
  align-items: flex-start;
  ax-width: 70%;
}

.message-text {
  background-color: #3a3a3a;
  color: #fff;
  padding: 10px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
  word-break: break-word;  /* 让长单词或 URL 自动换行 */
  white-space: pre-wrap;  /* 保留空格换行，支持多行显示 */
}

.message.self .message-text {
  background-color: #0084ff;
}

.timestamp {
  font-size: 0.8em;
  color: #888;
  margin-top: 4px;
}

.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--background-color);
  border-top: 1px solid #333;
  position: relative;
}

.message-input input {
  flex: 1;
  background: var(--background-color);
  color: var(--text-color);
  border-radius: 20px;
  padding: 10px;
  margin: 0 10px;
  border: 1px solid #5c5c5c;
  font-size: 15px;
}



.emoji-button {
  background-color: var(--background-color) !important;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}


</style>

<template>
  <div class="image-container" ref="scrollContainer"  @scroll="handleScroll">
    <div
        v-for="(group, index) in mockImages"
        :key="index"
        class="image-group"
        :class="{ 'reverse': index % 2 === 1 }"
    >
      <!-- 大正方形 -->
      <div class="big-square">
        <div class="image-wrapper" @mouseenter="showOverlay(index, 'big')" @mouseleave="hideOverlay"  @click="go_detail(group.big_info)">
          <el-image
              v-lazy
              :src="'https://bbs-oss.realtime.xyz/'+group.big"
              fit="cover"
              class="image"
              loading="lazy"
          />
          <!-- 遮罩层 -->
          <div v-if="overlayIndex === index && overlayType === 'big'" class="overlay smooth-transition">
            <div class="explore_icon_content">
              <div class="explore_icon_1">
                <svg t="1737467884180" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4477" id="mx_n_1737467884181" width="14" height="14"><path d="M724.032 564.608v-105.6c0-10.56 3.52-22.72 8.384-27.776l115.584-118.976c37.824-38.912 81.344-49.856 81.344-8.128v415.424c0 41.664-43.52 30.784-81.344-8.128l-115.584-118.976c-4.864-4.992-8.384-17.216-8.384-27.84zM669.376 681.792a64 64 0 0 1-64 64h-446.72a64 64 0 0 1-64-64V341.76a64 64 0 0 1 64-64h446.72a64 64 0 0 1 64 64v340.032z" fill="" p-id="4478"></path></svg>
              </div>
              <div class="explore_icon_1">
                <svg t="1737467988107" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8100" width="14" height="14"><path d="M278.698667 270.506667c24.746667 0 46.08 8.704 64 26.282666 17.749333 17.664 26.624 39.082667 26.624 64.512 0 24.576-8.874667 45.653333-26.709334 63.146667-17.749333 17.664-39.082667 26.453333-64 26.453333-25.6 0-47.104-8.789333-64.512-26.453333a86.784 86.784 0 0 1-26.112-63.146667c0-25.429333 8.704-46.933333 26.112-64.512 17.408-17.578667 38.912-26.368 64.597334-26.368z m-93.866667-142.506667h568.917333c78.506667 0 142.250667 63.658667 142.250667 142.250667v568.832c0 78.506667-63.658667 142.250667-142.250667 142.250666H184.917333C106.410667 981.333333 42.666667 917.674667 42.666667 839.082667V270.250667c0-78.506667 63.658667-142.250667 142.250666-142.250667z m632.917333 128.853333a54.186667 54.186667 0 0 0-13.056-36.181333c-8.704-10.24-22.528-15.36-41.557333-15.36H170.581333a73.728 73.728 0 0 0-31.061333 8.021333 45.824 45.824 0 0 0-14.848 11.690667 27.562667 27.562667 0 0 0-6.229333 18.432v329.045333c6.656 7.338667 15.957333 16.213333 27.904 26.368 12.032 10.24 26.112 19.626667 42.24 28.16 16.213333 8.704 34.133333 16.042667 54.101333 22.186667 19.797333 6.144 41.386667 9.216 64.512 9.216 34.816 0 65.28-5.546667 91.306667-16.554667 26.112-11.093333 50.346667-25.002667 72.704-41.813333 14.933333-11.093333 29.610667-22.869333 44.117333-35.157333l21.674667-18.773334c21.589333-19.285333 45.397333-37.546667 71.424-54.698666a411.050667 411.050667 0 0 1 89.429333-43.52c33.536-11.946667 73.557333-18.688 119.893333-20.309334v-170.666666z" fill="" p-id="8101"></path><path d="M810.666667 0a213.333333 213.333333 0 0 1 212.906666 199.338667L1024 213.333333v426.666667a42.666667 42.666667 0 0 1-84.650667 7.68L938.666667 640v-426.666667a128 128 0 0 0-115.712-127.402666L810.666667 85.333333h-426.666667a42.666667 42.666667 0 0 1-7.68-84.650666L384 0h426.666667z" fill="" p-id="8102"></path></svg>
              </div>
            </div>
            <div class="explore_info">
              <el-avatar class="explore_info_user_header"  :src="group.big_info.user.avatar">
              </el-avatar>
              <div class="explore_info_right">
                <div class="explore_info_right_1">
                  {{group.big_info.user.name}}
                </div>
                <div class="explore_info_right_2">
                  {{group.big_info.user.name}}
                </div>
              </div>
            </div>

            <div class="overlay-info">
              <div style="color: white">
                <svg t="1729510917057" class="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="6137" width="24" height="24">
                  <path
                      d="M709.692235 120.470588C612.111059 120.470588 542.117647 208.414118 512 254.976 481.822118 208.474353 411.888941 120.470588 314.307765 120.470588 174.200471 120.470588 60.235294 246.723765 60.235294 401.889882c0 72.583529 49.212235 176.549647 108.965647 231.002353 82.642824 106.194824 314.970353 297.321412 343.642353 297.321412 29.214118 0 256.542118-187.392 340.690824-296.056471C914.311529 578.740706 963.764706 474.654118 963.764706 401.889882 963.764706 246.723765 849.799529 120.470588 709.692235 120.470588"
                      fill="#ffffff" p-id="6138"></path>
                </svg>
                {{ group.big_info.likes }}
              </div>
              <div style="color: white">
                <svg t="1729510999646" class="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="7311" width="24" height="24">
                  <path
                      d="M832 96H192a96 96 0 0 0-96 96v512a96 96 0 0 0 96 96h384l231.52 144.704a16 16 0 0 0 24.48-13.568V800a96 96 0 0 0 96-96V192a96 96 0 0 0-96-96zM310.624 537.376a32 32 0 0 0-45.248 45.248c0.864 0.864 2.016 2.112 3.456 3.648 9.504 10.112 31.68 33.76 66.496 55.04 40.64 24.832 98.336 46.688 176.672 46.688s136.064-21.856 176.672-46.72c34.816-21.248 56.96-44.896 66.496-55.008 1.44-1.568 2.592-2.784 3.456-3.648a32 32 0 0 0-45.248-45.248 292.448 292.448 0 0 0-5.216 5.44c-10.112 10.624-26.112 27.552-52.832 43.872-31.36 19.2-77.664 37.312-143.328 37.312s-111.936-18.144-143.328-37.312a244.384 244.384 0 0 1-52.8-43.904 296.896 296.896 0 0 0-5.248-5.44z"
                      fill="#ffffff" p-id="7312"></path>
                </svg>

                {{ group.big_info.comment_count }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 小正方形 -->
      <div class="small-squares">
        <div v-for="(smallImage, i) in group.small" :key="i" class="small-square" @click="go_detail(smallImage)">
          <div class="image-wrapper" @mouseenter="showOverlay(index, i)" @mouseleave="hideOverlay">
            <el-image
                v-lazy
                :src="'https://bbs-oss.realtime.xyz/'+smallImage.cover"
                fit="cover"
                class="image"
                loading="lazy"
            />
            <!-- 遮罩层 -->
            <div v-if="overlayIndex === index && overlayType === i" class="overlay smooth-transition">
              <div class="explore_icon_content">
                <div class="explore_icon_1">
                  <svg t="1737467884180" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4477" id="mx_n_1737467884181" width="14" height="14"><path d="M724.032 564.608v-105.6c0-10.56 3.52-22.72 8.384-27.776l115.584-118.976c37.824-38.912 81.344-49.856 81.344-8.128v415.424c0 41.664-43.52 30.784-81.344-8.128l-115.584-118.976c-4.864-4.992-8.384-17.216-8.384-27.84zM669.376 681.792a64 64 0 0 1-64 64h-446.72a64 64 0 0 1-64-64V341.76a64 64 0 0 1 64-64h446.72a64 64 0 0 1 64 64v340.032z" fill="" p-id="4478"></path></svg>
                </div>
                <div class="explore_icon_1">
                  <svg t="1737467988107" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8100" width="14" height="14"><path d="M278.698667 270.506667c24.746667 0 46.08 8.704 64 26.282666 17.749333 17.664 26.624 39.082667 26.624 64.512 0 24.576-8.874667 45.653333-26.709334 63.146667-17.749333 17.664-39.082667 26.453333-64 26.453333-25.6 0-47.104-8.789333-64.512-26.453333a86.784 86.784 0 0 1-26.112-63.146667c0-25.429333 8.704-46.933333 26.112-64.512 17.408-17.578667 38.912-26.368 64.597334-26.368z m-93.866667-142.506667h568.917333c78.506667 0 142.250667 63.658667 142.250667 142.250667v568.832c0 78.506667-63.658667 142.250667-142.250667 142.250666H184.917333C106.410667 981.333333 42.666667 917.674667 42.666667 839.082667V270.250667c0-78.506667 63.658667-142.250667 142.250666-142.250667z m632.917333 128.853333a54.186667 54.186667 0 0 0-13.056-36.181333c-8.704-10.24-22.528-15.36-41.557333-15.36H170.581333a73.728 73.728 0 0 0-31.061333 8.021333 45.824 45.824 0 0 0-14.848 11.690667 27.562667 27.562667 0 0 0-6.229333 18.432v329.045333c6.656 7.338667 15.957333 16.213333 27.904 26.368 12.032 10.24 26.112 19.626667 42.24 28.16 16.213333 8.704 34.133333 16.042667 54.101333 22.186667 19.797333 6.144 41.386667 9.216 64.512 9.216 34.816 0 65.28-5.546667 91.306667-16.554667 26.112-11.093333 50.346667-25.002667 72.704-41.813333 14.933333-11.093333 29.610667-22.869333 44.117333-35.157333l21.674667-18.773334c21.589333-19.285333 45.397333-37.546667 71.424-54.698666a411.050667 411.050667 0 0 1 89.429333-43.52c33.536-11.946667 73.557333-18.688 119.893333-20.309334v-170.666666z" fill="" p-id="8101"></path><path d="M810.666667 0a213.333333 213.333333 0 0 1 212.906666 199.338667L1024 213.333333v426.666667a42.666667 42.666667 0 0 1-84.650667 7.68L938.666667 640v-426.666667a128 128 0 0 0-115.712-127.402666L810.666667 85.333333h-426.666667a42.666667 42.666667 0 0 1-7.68-84.650666L384 0h426.666667z" fill="" p-id="8102"></path></svg>
                </div>
              </div>
              <div class="explore_info">
                <el-avatar class="explore_info_user_header"  :src="smallImage.user.avatar">
                </el-avatar>
                <div class="explore_info_right">
                  <div class="explore_info_right_1">
                    {{smallImage.title}}
                  </div>
                  <div class="explore_info_right_2">
                    {{smallImage.user.name}}
                  </div>
                </div>
              </div>
              <div class="overlay-info ">
                <div style="color: white">
                  <svg t="1729510917057" class="icon" viewBox="0 0 1024 1024" version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="6137" width="24" height="24">
                    <path
                        d="M709.692235 120.470588C612.111059 120.470588 542.117647 208.414118 512 254.976 481.822118 208.474353 411.888941 120.470588 314.307765 120.470588 174.200471 120.470588 60.235294 246.723765 60.235294 401.889882c0 72.583529 49.212235 176.549647 108.965647 231.002353 82.642824 106.194824 314.970353 297.321412 343.642353 297.321412 29.214118 0 256.542118-187.392 340.690824-296.056471C914.311529 578.740706 963.764706 474.654118 963.764706 401.889882 963.764706 246.723765 849.799529 120.470588 709.692235 120.470588"
                        fill="#ffffff" p-id="6138"></path>
                  </svg>
                  {{ smallImage.likes }}
                </div>
                <div style="color: white">
                  <svg t="1729510999646" class="icon" viewBox="0 0 1024 1024" version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="7311" width="24" height="24">
                    <path
                        d="M832 96H192a96 96 0 0 0-96 96v512a96 96 0 0 0 96 96h384l231.52 144.704a16 16 0 0 0 24.48-13.568V800a96 96 0 0 0 96-96V192a96 96 0 0 0-96-96zM310.624 537.376a32 32 0 0 0-45.248 45.248c0.864 0.864 2.016 2.112 3.456 3.648 9.504 10.112 31.68 33.76 66.496 55.04 40.64 24.832 98.336 46.688 176.672 46.688s136.064-21.856 176.672-46.72c34.816-21.248 56.96-44.896 66.496-55.008 1.44-1.568 2.592-2.784 3.456-3.648a32 32 0 0 0-45.248-45.248 292.448 292.448 0 0 0-5.216 5.44c-10.112 10.624-26.112 27.552-52.832 43.872-31.36 19.2-77.664 37.312-143.328 37.312s-111.936-18.144-143.328-37.312a244.384 244.384 0 0 1-52.8-43.904 296.896 296.896 0 0 0-5.248-5.44z"
                        fill="#ffffff" p-id="7312"></path>
                  </svg>
                  {{ smallImage.comment_count }}
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 加载中 -->
    <div style="cursor: pointer;margin-top: 10px;" v-if="hasMore" class="loading display_center" @click="loadMoreImages">
      点击加载更多
      <span v-if="loading" class="loading-spinner"></span>
    </div>
    <div v-else class="loading">没有更多数据了</div>
  </div>
</template>

<script>
import {exploreRequest} from "@/api/api";
import {ElLoading} from "element-plus";

export default {
  name: "ImgTest",
  data() {
    return {
      visibleImages: [], // 可见图片列表
      mockImages: [], // 全部模拟数据
      loading: false, // 是否加载中
      overlayIndex: null, // 当前显示遮罩的索引
      overlayType: null, // 当前遮罩的类型 ('big' 或小正方形索引)
      loadingInstance: null, // 加载动画实例
      page:1,
      hasMore:false,
    };
  },
  mounted() {
    this.loading=true;
    this.initImages();
    // this.loadMoreImages();
  },
  methods: {
    // 初始化图片数据
    initImages(showOverlay = true) {
      let loadingInstance;
      if (showOverlay) {
        loadingInstance = ElLoading.service({
          lock: true,
          text: '加载中...',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      }
      exploreRequest({page:this.page,limit:20}).then(res => {
        // this.mockImages = res.data;
        //组合数据，每组包含五个数据，计算总的组数量N。取数据前面N个为每组的大图，再把剩余的数据放到每个分组里面的小图
        let data = res.data;
        if(data.length<=0){
          this.loading=false;
          this.hasMore=false;
          return;
        }
        let group = [];
        let N = Math.ceil(data.length / 5);
        for (let i = 0; i < N; i++) {
          let big = data[i];
          //从第N+1个开始。每组取4个数据
          let small = data.slice(N + i * 4, N + i * 4 + 4);

          if(!big.cover){
            if(big.nodes[0].medias){
              big.cover = big.nodes[0].medias[0].media_url;
            }
          }
          for(let j = 0; j < small.length; j++){
            if(!small[j].cover){
              if(small[j].nodes[0]){
                small[j].cover = small[j].nodes[0].medias[0].media_url;
              }
            }
          }
          group.push({
            big: big.cover,
            big_user:big.user,
            big_info:big,
            likes: Math.floor(Math.random() * 1000), // 随机点赞数
            comments: Math.floor(Math.random() * 500), // 随机评论数
            small: small,
            smallLikes: Array.from({length: 5}, () => Math.floor(Math.random() * 1000)),
            smallComments: Array.from({length: 5}, () => Math.floor(Math.random() * 500)),
          });
        }
        this.mockImages.push(...group);
        this.loading=false;
        this.hasMore=true;
      }).finally(()=>{
        if (showOverlay && loadingInstance) {
          loadingInstance.close();
        }
      })
    },


    //跳转详情
    go_detail(item){
      let last_node=item.nodes[item.nodes.length-1]
      this.$router.push({name: 'TimeLine', params: {id: last_node.id}});
    },
    // 滚动事件处理
    handleScroll() {
      // const el = this.$refs.scrollContainer;
      // console.log("loadMore")
      // if (el.scrollHeight - el.scrollTop <= el.clientHeight + 100 && !this.loading) {
      //   this.loadMoreImages();
      // }
    },
    // 加载更多图片（循环加载）
    loadMoreImages() {
      if (this.loading) return;
      this.loading = true;
      this.page++;
      this.initImages(false);
      // 显示加载动画
      // this.loadingInstance = ElLoading.service({
      //   fullscreen: true,
      //   text: '加载中...'
      // });
      //
      // nextTick(() => {
      //   setTimeout(() => {
      //     this.visibleImages.push(...this.mockImages);
      //     this.loading = false;
      //     // 关闭加载动画
      //     this.loadingInstance.close();
      //   }, 1000);
      // });
    },
    // 显示遮罩
    showOverlay(index, type) {
      this.overlayIndex = index;
      this.overlayType = type;
    },
    // 隐藏遮罩
    hideOverlay() {
      this.overlayIndex = null;
      this.overlayType = null;
    },
  },
};
</script>

<style scoped>
.smooth-transition {
  transition: all 0.6s ease-in-out; /* 平滑效果 */
}

.image-container {
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.image-group {
  display: flex;
  background: none;
}

.image-group.reverse {
  flex-direction: row-reverse;
}

.big-square,
.small-squares {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
}

.small-square {
  width: 50%;
  height: auto;
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
}

.image-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  aspect-ratio: 1;
}

.image-wrapper .image {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  z-index: 1;
}

.overlay-info {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.explore_info{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px 5px;
}
.explore_icon_content{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px 5px;
}
.explore_icon_1{
  width: 20px;
  height: 20px;
  background: rgb(190,190,194);
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore_icon_1 svg{
  fill: rgb(95,93,92);
}
.explore_info_user_header{
  margin-right: 5px;
}
.explore_info_right{
  width: calc(100% - 45px);
}
.explore_info_right_1{
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explore_info_right_2 {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #e6e6ea;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .overlay-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.overlay-info div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.loading {
  text-align: center;
  font-size: 16px;
  color: #666;
  padding: 10px;
}
</style>

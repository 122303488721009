// src/stores/messageStore.js
import {defineStore, mapActions} from 'pinia';
import {getPostDetailRequest} from "@/api/api";
import {StatusStore} from "@/stores/StatusStore";

export const PostDetailStore = defineStore('PostDetailStore', {
    state: () => ({
        post_info: null,//详情页的帖子信息
        post_id:0,//详情页的帖子id
    }),
    actions: {
        ...mapActions(StatusStore, ['setNeedGetDetail']),
        getDetailInfo(){
            getPostDetailRequest({id: this.post_id}).then(res => {
                this.postDetail = res.data;
                this.setPostInfo(res.data);
                this.setNeedGetDetail(false);
            });
        },
        setPostInfo(value){
            this.post_info=value;
        },
        clearPostInfo(){
            this.post_info=null;
        },
        getPostInfo(){
            return this.post_info;
        },
        setPostId(value){
            this.post_id=value;
        },


    },
});

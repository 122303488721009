<template>
  <el-card class="password-change-card theme_bg">
    <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input
            v-model="form.oldPassword"
            type="password"
            placeholder="请输入旧密码"
        ></el-input>
      </el-form-item>

      <el-form-item label="新密码" prop="newPassword">
        <el-input
            v-model="form.newPassword"
            type="password"
            placeholder="请输入新密码"
        ></el-input>
      </el-form-item>

      <el-form-item label="确认新密码" prop="confirmPassword">
        <el-input
            v-model="form.confirmPassword"
            type="password"
            placeholder="请确认新密码"
        ></el-input>
      </el-form-item>

      <el-button class="pass_reset" size="large" type="primary" @click="changePassword">确认修改</el-button>
    </el-form>
  </el-card>
</template>

<script>
import {ref} from 'vue';
import {ElMessage} from 'element-plus';
import {changePwdRequest} from "@/api/api";
import router from "@/router";

export default {
  name: 'PasswordReset',
  setup() {
    const form = ref({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });

    const formRef = ref(null);

    // 自定义验证器：验证新密码的格式
    const validateNewPassword = (rule, value, callback) => {
      const pattern = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      if (!value) {
        callback(new Error('请输入新密码'));
      } else if (!pattern.test(value)) {
        callback(new Error('密码必须包含字母、数字,且6位以上'));
      } else {
        callback();
      }
    };

    // 自定义验证器：验证确认密码是否一致
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== form.value.newPassword) {
        callback(new Error('两次密码输入不一致'));
      } else {
        callback();
      }
    };

    // 表单验证规则
    const rules = {
      oldPassword: [
        {required: true, message: '请输入旧密码', trigger: 'blur'},
      ],
      newPassword: [
        {required: true, validator: validateNewPassword}, // 移除 trigger 属性
      ],
      confirmPassword: [
        {required: true, message: '请确认新密码'}, // 移除 trigger 属性
        {validator: validateConfirmPassword},
      ],
    };

    // 确认修改密码
    const changePassword = async () => {
      if (!formRef.value) return;
      try {
        await formRef.value.validate();
        // 执行修改密码的请求
        changePwdRequest({
          oldPassword: form.value.oldPassword,
          newPassword: form.value.newPassword,
        }).then(res => {
          if (res.code === 200) {
            ElMessage.success('密码修改成功,请重新登录！');
            router.replace({name: 'LoginPage'});
          } else {
            ElMessage.error(res.message);
          }
        });
      } catch (error) {
        // 处理验证错误或请求错误
        ElMessage.error(error.message);
      }
    };

    return {
      form,
      formRef,
      rules,
      changePassword,
    };
  },
};
</script>
œœœœ
<style>
.password-change-card .el-form-item__error {
  top: 86%;
}

.password-change-card .el-input__wrapper {
  margin-bottom: 25px;
  background-color: var(--background-color) !important;
  height: 53px !important;
}

.pass_reset {

  border: none !important;
  float: right;
  margin-top: 30px;
}

.password-change-card {
  max-width: 750px;
  margin: 45px auto 0;
  padding: 20px;
  border: none !important;
}

.password-change-card .el-form-item__label {
  font-size: 17px;
  color: var(--text-color);
  width: 118px !important;
}
.el-card.is-always-shadow.password-change-card.theme_bg {
  box-shadow: none;
}
</style>

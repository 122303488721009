<template>
  <div class="profile-edit-container">
    <div class="zy">主页设置</div>
    <!-- 用户信息 -->
    <div class="profile-header">
      <div class="avatar_part">
        <AvatarUploader ref="avatarUploader"  :user="user_info"/>
      </div>

      <el-button type="primary" size="large" class="change-avatar-btn" @click="openAvatarUploader">
        更改头像
      </el-button>
      <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
    </div>

    <!-- 表单 -->
    <el-form :model="form" label-width="100px" class="profile-form">
      <el-form-item label="昵称">
        <el-input
            v-model="form.nickname"
            type="text"
            :maxlength="20"
            show-word-limit
            placeholder="昵称"
        ></el-input>
      </el-form-item>
      <el-form-item label="个性签名">
        <el-input
            v-model="form.bio"
            type="textarea"
            :maxlength="150"
            show-word-limit
            placeholder="个性签名"
        ></el-input>
      </el-form-item>

      <el-form-item label="性别">
        <el-select v-model="form.gender" placeholder="请选择性别" >
          <el-option label="男" key="1" :value="1"></el-option>
          <el-option label="女" key="2" :value="2"></el-option>
          <el-option label="不想说" key="0" :value="0"></el-option>
        </el-select>
        <p class="input-hint">这不会显示在你的公开资料中。</p>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="large" class="submit-btn btn_sub" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {updateProfileequest} from "@/api/api";
import AvatarUploader from "@/components/AvatarUploader.vue";

export default {
  name: 'EditProfile',
  components: {AvatarUploader},
  data() {
    return {
      user_info:JSON.parse(localStorage.getItem('user_info')),
      form: {
        avatarUrl: require('@/assets/hwk_1.png'), // 初始头像
        username: '', // 示例用户名
        nickname: '', // 示例昵称
        bio: '',
        gender: 0, // 初始性别
      },
    };
  },
  mounted() {
    this.form.bio = this.user_info.desc;
    this.form.gender = this.user_info.sex;
    this.form.nickname = this.user_info.name;
    this.form.avatarUrl = this.user_info.avatar;

  },
  methods: {
    // 点击按钮时，调用 AvatarUploader 组件的 openModal 方法
    openAvatarUploader() {
      this.$refs.avatarUploader.openModal();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.form.avatarUrl = reader.result; // 更新头像URL

        };
        reader.readAsDataURL(file);
      }
    },
    submitForm() {
      updateProfileequest(this.form).then(response => {
        if (response.code === 200) {
          //缓存最新用户数据
          localStorage.setItem('user_info', JSON.stringify(response.data));
          this.$message.success('更新成功');
        }
      }).catch(error => {
        console.error('更新失败:', error);
      });
    },
  },
};
</script>

<style scoped>
.avatar_part{
  width: 77px;
  height: 77px;
  border-radius: 50%;
  overflow: hidden;
}
.el-textarea .el-input__count{
  background: none !important;
}
.zy{
  font-size: 19px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 40px;
}
.btn_sub{

  border: none !important;
}
.profile-form .el-form-item__label{
  justify-content: flex-start !important;
}
.username {
  font-size: 18px;
  color: var(--text-color);
}
.profile-form .el-select__wrapper{
  background-color: var(--background-color) !important;
  height: 53px !important;
}
.el-select-dropdown__item.is-hovering {
  background-color: rgb(60,60,60) !important;
}
.el-select-dropdown__item.is-hovering span {
  color: #fff !important;
}
.el-popper__arrow {
  display: none !important;
}
.el-select__placeholder{
  color: var(--text-color) !important;
}
.el-select__placeholder span {
  color: var(--text-color) !important;
}
.profile-edit-container {
  max-width: 750px;
  margin: 0px auto;
  padding: 0px;
  background-color: var(--background-color);
}
@media (max-width: 768px){
  .profile-edit-container{
    max-width: 750px;
    margin: 0px auto;
    padding: 0px;
    background-color: var(--background-color);
  }
  .profile-header {
    padding: 0px 10px !important;
  }

}

.profile-header {
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0px 20px;
  background-color: var(--set-header-bg);
  border-radius: 8px;
  position: relative;
  margin-bottom: 30px;
}

.avatar {
  margin-right: 15px;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.username {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: var(--text-color);
}

.sub-username {
  color: #aaa;
  margin-top: 2px;
}

.change-avatar-btn {
  position: absolute;
  right: 20px;

}



.profile-form {
  width: 100%;
}

.el-form-item {
  flex-direction: column !important;
  margin-bottom: 18px;
}

.input-hint {
  font-size: 0.85em;
  color: #888;
  margin-top: 4px;
}

.submit-btn {

}

.submit-btn:hover {

}
</style>

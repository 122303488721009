<template>
  <div class="signup-container theme_bg">
    <div class="signup-box">
      <div class="logo">
        <svg width="130" class="ml_10" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 1499.81 1499.81">
          <defs></defs>
          <path class="cls-1" d="M350.96,532.43h134.98v59.99h-75.74v150.73h-59.24v-210.72Z"/>
          <path class="cls-1"
                d="M1163.27,773.15h75.74c7.5,7.75,23.17,23.96,23.17,23.96,0,0-33.67,33.78-50.17,50.28h24c5.75,6,11.5,12,17.25,18v15.75h-75.74c-7.75-8.25-15.5-16.5-23.25-24.75,16.5-16.25,50.27-49.52,50.27-49.52,0,0-17.78.03-26.27.03-5-5.25-10-10.5-15-15.75v-18Z"/>
          <path class="cls-1"
                d="M1145.11,908.14h-33.75v-51.74c-12.25-12.25-24.5-24.5-36.75-36.75l24-24c9.75,9.5,19.5,19,29.25,28.5,9.75-9.5,19.5-19,29.25-28.5,8,7.75,16,15.5,24,23.25l-36,36v53.24Z"/>
          <path class="cls-1"
                d="M980.13,796.4c8,7.75,16,15.5,24,23.25-7.75,8-15.5,16-23.25,24-8-7.75-16-15.5-24-23.25,7.75-8,15.5-16,23.25-24Z"/>
          <path class="cls-1"
                d="M501.69,532.43h133.48v59.99h-74.24v15h74.24v59.99h-74.24v15.75h74.24v59.99h-133.48v-210.72Z"/>
          <path class="cls-1"
                d="M785.9,532.43v210.72h-59.24c-.25-19.75-.5-39.5-.75-59.24-9,21.75-18,43.5-27,65.24-18.25-7.5-36.5-15-54.74-22.5,27.49-64.74,55-129.49,82.49-194.23h59.24Z"/>
          <path class="cls-1" d="M801.65,532.43h59.24v210.72h-59.24v-210.72Z"/>
          <path class="cls-1" d="M876.64,683.16h133.48v59.99h-133.48v-59.99Z"/>
          <path class="cls-1" d="M277.47,757.41h133.48v59.99h-133.48v-59.99Z"/>
          <path class="cls-1" d="M425.95,757.41h59.24v59.99h-59.24v-59.99Z"/>
          <path class="cls-1"
                d="M800.9,757.41h134.98v210.72h-134.98v-59.99h75.74v-14.25h-75.74v-59.99h75.74v-16.5h-75.74v-59.99Z"/>
          <path class="cls-1"
                d="M575.18,758.16h210.72v210.72h-59.24v-151.48h-15.75v151.48h-59.24v-151.48h-17.25v151.48h-59.24v-210.72Z"/>
          <path class="cls-1"
                d="M1062.38,820.05c10,9.75,31.74,30.34,31.74,30.34l-23.58,24.27s-20.67-21.03-30.42-31.02c-10.25,10-20.5,20-30.75,30-7.75-7.75-15.5-15.5-23.25-23.25,10-10,20-20,30-30-9.75-9.75-19.5-19.5-29.25-29.25v-2.25c7.5-7.5,15-15,22.5-22.5,10,10,20,20,30,30,10-10,20-20,30-30,8,7.75,16,15.5,24,23.25l-30.99,30.4Z"/>
          <path class="cls-1" d="M500.94,833.9h59.99v133.48h-59.99v-133.48Z"/>
          <path class="cls-1" d="M350.21,834.65h59.99v133.48h-59.99v-133.48Z"/>
        </svg>
      </div>
      <p class="subtitle">注册 RealTime.Art，分享创意</p>
      <el-form :model="form" :rules="rules" ref="signupForm" @submit.prevent="onSubmit" class="signup-form">
        <el-form-item prop="username">
          <el-input v-model="form.username" placeholder="账号" @focus="handleFocus('username')" @blur="handleBlur('username')" :class="{ active: activeInput === 'username' }" />
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="form.email" placeholder="邮箱" @focus="handleFocus('email')" @blur="handleBlur('email')" :class="{ active: activeInput === 'email' }" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" placeholder="密码" type="password" @focus="handleFocus('password')" @blur="handleBlur('password')" :class="{ active: activeInput === 'password' }" />
        </el-form-item>
        <el-form-item prop="config_password">
          <el-input v-model="form.config_password" placeholder="确认密码" type="password" @focus="handleFocus('config_password')" @blur="handleBlur('config_password')" :class="{ active: activeInput === 'config_password' }" />
        </el-form-item>
        <el-form-item prop="fullName">
          <el-input v-model="form.fullName" placeholder="昵称" @focus="handleFocus('fullName')" @blur="handleBlur('fullName')" :class="{ active: activeInput === 'fullName' }" />
        </el-form-item>
        <el-form-item prop="invite_code">
          <el-input v-model="form.invite_code" placeholder="邀请码" @focus="handleFocus('invite_code')" @blur="handleBlur('invite_code')" :class="{ active: activeInput === 'invite_code' }" />
        </el-form-item>
        <el-button type="primary" size="large" class="signup-button" @click="onSubmit">注册</el-button>
      </el-form>
      <div class="login-link" @click="goToLoginPage">有账号了？<div style="color: #3897f0">请登录</div></div>
    </div>
    <div class="download-app">
      <ResponsiveFooter/>
    </div>
  </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus';
import ResponsiveFooter from "@/components/ResponsiveFooter.vue";
import {postRequest} from "@/api/api";
export default {
  name: 'InstagramSignupClone',
  components: {
    ResponsiveFooter,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        config_password: '',
        fullName: '',
        username: '',
        invite_code: '',
      },
      activeInput: null,
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // const phoneRegex = /^[0-9]{7,}$/;
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (value.includes('@')) {
                if (!emailRegex.test(value)) {
                  callback(new Error('请输入正确的邮箱地址'));
                } else {
                  callback();
                }
              } else {
                callback(new Error('请输入正确的邮箱地址'));
                // if (!phoneRegex.test(value)) {
                //   callback(new Error('你的电话号码似乎不正确，请输入包括国家/地区代码的完整号码。'));
                // } else {
                //   callback();
                // }
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不少于 6 个字符', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
            message: '密码必须包含字母、数字,且6位以上',
            trigger: 'blur',
          },
        ],
        config_password: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { min: 6, message: '密码长度不少于 6 个字符', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
            message: '密码必须包含字母、数字,且6位以上',
            trigger: 'blur',
          },
        ],
        fullName: [
          { required: true, message: '请输入昵称昵称', trigger: 'blur' },
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 4, message: '账号长度不能少于 4 个字符', trigger: 'blur' },
        ],
        invite_code: [
          { required: true, message: '请输入邀请码', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    goToLoginPage() {
      this.$router.push({ name: 'LoginPage' });
    },
    handleFocus(input) {
      this.activeInput = input;
    },
    handleBlur(input) {
      if (this.activeInput === input) {
        this.activeInput = null;
      }
    },
    onSubmit() {
      this.$refs.signupForm.validate((valid) => {
        if (valid) {
          postRequest("/auth/register", this.form)
              .then(result=>{
                if(result.code===200){
                  this.$router.push({ name: 'LoginPage' });
                  ElMessage({
                    message: '注册成功',
                    type: 'success',
                  });
                }else{
                  ElMessage({
                    message: result.message,
                    type: 'error',
                  });
                }
          }).catch(error=>{
            console.error(error);
            ElMessage({
              message: '注册失败',
              type: 'error',
            });
          });

        } else {
          ElMessage({
            message: '请完成所有必填项并确保输入正确',
            type: 'error',
          });
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.download-app {
  margin-top: 20px;
  text-align: center;
  position: fixed;
  bottom: 50px;
}
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.signup-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: var(--background-color);
  text-align: center;
}

.logo {
  font-family: 'Lobster', cursive;
  font-size: 36px;
  margin-bottom: 10px;
}
.logo svg{
  fill: var(--text-color) !important;
}
.subtitle {
  font-size: 16px;
  margin-bottom: 20px;
}

.signup-form {
  width: 100%;
}

.signup-button {
  width: 100%;
  background-color: #3897f0;
  color: #fff;
  margin-top: 10px;
}

.login-link {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.app-links img {
  width: 120px;
  margin: 10px;
}

.el-input.active::placeholder {
  transform: translateY(-20px);
  font-size: 12px;
  color: #bbb;
}
</style>

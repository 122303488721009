<template>
  <el-drawer
      title="通知"
      :model-value="visible"
      custom-class="recommend-drawer"
      direction="ltr"
      placement="right"
      :show-close="false"
      size="400px"
      @close="handleClose"
      @open="all_read"
      :append-to-body="false"
      :modal="true"
      :modal-append-to-body="true"
      :modal-z-index="100"
      :mask="false"
  >
    <div class="drawer-content " @click.stop>

      <div class="recommendations" v-if="notifications.length > 0">
        <div class="notice_title">
          <h3 style="padding-left: 10px;">你的通知</h3>
          <!--          <div class="notice_readed" @click="all_read">-->
          <!--            全部已读-->
          <!--          </div>-->
        </div>
        <div class="notification-container">
          <div v-for="(notification, index) in notifications" :key="index" class="notification-item"
               @click="navigateTo(notification)">
            <div class="notification-header">
              <div class="nnn-left">
                <el-avatar :src="notification.sender.avatar" size="small"></el-avatar>
                <div class="notification-content">
                  <div class="notification_user_info">
                    <span class="notification-username">{{ notification.sender.name }}</span>
                    <span class="notification-time">{{ notification.created_time }}</span>
                  </div>
                  <p v-if="notification.type === 'follow'">{{ notification.sender.name }} 关注了你</p>
                  <p v-if="notification.type === 'like_post'">{{ notification.sender.name }} 点赞了你的作品</p>
                  <p v-if="notification.type === 'like_comment'">{{ notification.sender.name }} 点赞了你的评论</p>
                  <p v-if="notification.type === 'reply_comment'">{{ notification.sender.name }} 回复了你的评论</p>
                  <p v-if="notification.type === 'reply_post'">{{ notification.sender.name }} 回复了你的帖子</p>
                  <p v-if="notification.type === 'message'">{{ notification.sender.name }} 给你发送了消息</p>
                </div>
              </div>


              <el-button v-if="notification.type === 'follow'" type="primary" size="small">关注</el-button>
              <div v-else-if="notification.type === 'message'" class="notification_image">
              </div>
              <div v-else class="notification_image">
                <img v-if="notification.post" :src="'https://bbs-oss.realtime.xyz/'+notification.post.plan.cover">
              </div>
            </div>
          </div>
          <p v-if="noMore">没有更多数据了</p>
          <p v-else class="display_center" style="text-align: center; cursor: pointer; position: relative;" @click="load_more">
            查看更多数据
            <span v-if="loading" class="loading-spinner"></span>
          </p>

        </div>
      </div>

      <div class="notification" v-else>
        <svg
            class="icon-heart"
            fill="currentColor"
            height="50"
            role="img"
            viewBox="0 0 48 48"
            width="50"
        >
          <title>帖子动态</title>
          <path
              d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"
          ></path>
        </svg>
        <p>帖子动态</p>
        <p>获得点赞或评论的帖子会显示在这里。</p>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {allReadRequest, noticeRequest} from "@/api/api";
import {mapActions} from "pinia";
import {UserStore} from "@/stores/UserStore";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)
dayjs.extend(timezone)
export default {
  name: "NoticeDrawer",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:visible"],
  data() {
    return {
      notifications: [],
      timezone: "utc",
      page:1,
      loading: true, // 加载状态
      noMore: false, // 是否还有更多数据
    };
  },
  mounted() {
    this.timezone = dayjs.tz.guess();
    this.page=1;
    this.notifications=[];
    this.getData()
  },
  methods: {
    ...mapActions(UserStore, ["loadUserData"]),
    // 加载更多数据
    load_more() {
      if (!this.loading && !this.noMore) {
        this.loading = true;
        this.page++;
        this.getData(false);
      }
    },
    getData() {
      noticeRequest({page:this.page}).then(res => {
        if (res && res.code === 200) {
          this.loading = false;
          this.noMore = res.data.length === 0; // 判断是否还有数据
          this.notifications.push(...res.data);
          this.notifications.forEach(item => {
            item.created_time = dayjs.utc(item.created_at).tz(this.timezone).format('MM-DD HH:mm');
          })
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    all_read() {
      allReadRequest({}).then(res => {
        if (res && res.code === 200) {
          // ElMessage.success('已全部标记为已读！');
          //重新加载当前路由
          this.loadUserData()
        }
      })
    },
    // 点击通知跳转
    navigateTo(notification) {
      if (notification.type === 'follow') {
        //关注信息跳转到用户主页
        this.$router.push(`/user/${notification.sender.user_name}`);
      } else if (notification.type === 'like_post') {
        //点赞帖子，跳转到作品详情
        this.$router.push(`/post/${notification.reference_id}`);
      } else if (notification.type === 'like_comment') {
        //点赞评论，跳转到作品详情
        this.$router.push(`/post/${notification.post.id}`);
      } else if (notification.type === 'reply_comment') {
        //回复评论，跳转到作品详情
        this.$router.push(`/post/${notification.post.id}`);
      }else if (notification.type === 'reply_post') {
        //回复评论，跳转到作品详情
        this.$router.push(`/post/${notification.post.id}`);
      } else if (notification.type === 'message') {
        //收到消息，跳转到消息页面。并激活该用户的聊天
        this.$router.push(`/Messagepage`);
      }
    },
    handleClose() {
      this.$emit("update:visible", false); // 通知父组件更新 `visible` 状态
    },
  },
};
</script>

<style>

.drawer-content {
  z-index: 900 !important; /* 确保低于菜单 */
}

.nnn-left {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.notification_image {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 6px;
}

.notification_image img {
  object-fit: cover;
  width: 44px;
  height: 44px;
}

.notice_title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;
}

.notice_readed {
  cursor: pointer;
}


.recommend-drawer {
  position: absolute !important;
  padding: 0;
  border-left: var(--left-sidebar-border-right);
  left: 70px !important;
  box-shadow: var(--el-drawer-box-shadow) !important;

}

/* 覆盖 el-drawer 的内部样式 */
.recommend-drawer ::v-deep .el-drawer__wrapper {
  position: absolute !important;
  bottom: 0;
  left: 0;
}

.el-overlay {
  background-color: transparent !important;
}


.notification {
  text-align: center;
  margin-bottom: 20px;
}

.icon-heart {
  color: #888;
}

.notification_user_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recommendations {
  padding-top: 10px;
}

.notification-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.notification-item {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.notification-item:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.notification-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.notification-username {
  font-weight: bold;
}

.notification-time {
  margin-left: 10px;
  font-size: 12px;
  color: #888;
}

.notification-content p {
  margin: 0;
  font-size: 14px;
}

.el-avatar {
  border-radius: 50%;
}
</style>

<template>
  <div v-loading="userloading" class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main>
        <div class="profile-card">
          <div class="ccc_w">
            <div class="ccc">
              <div class="avatar-section">
                <el-image class="avatar-container" :src="user_info.avatar?user_info.avatar:ava_bg">
                </el-image>
              </div>
              <div class="ccc_inner">
                <div class="info-section">
                  <div class="info_header">
                    <div class="username">
                      <span>{{ user_info.name }}</span>


                      <el-button style="margin-left: 10px;"
                          :class="user_info.is_followed_by_me ? 'followed_style' : 'unfollowed_style'"
                          :type="user_info.is_followed_by_me ? 'info' : 'primary'"
                          size="default"
                          class="follow-button"
                          @click="toggleFollow">
                        {{ user_info.is_followed_by_me ? '已关注' : '关注' }}
                      </el-button>
                    </div>
                    <div class="actions">

                    </div>
                  </div>
                  <div class="stats">
                    <div class="stat-item">
                      <span class="stat-number">{{ user_info.plans?user_info.plans.length:0 }}</span>
                      <span class="stat-label">作品</span>
                    </div>
                    <div class="stat-item"  style="cursor: pointer" @click="$refs.fansModal.visible = true">
                      <span class="stat-number">{{user_info.followers_count}}</span>
                      <span class="stat-label">粉丝</span>
                    </div>
                    <UserFansModal ref="fansModal"  :user_name="user_name"/>
                    <div class="stat-item" style="cursor: pointer" @click="$refs.followModal.visible = true">
                      <span class="stat-number">{{user_info.following_count}}</span>
                      <span class="stat-label">关注</span>
                    </div>
                    <UserFollowUserModal ref="followModal"  :user_name="user_name"/>
                  </div>
                </div>
              </div>

            </div>
            <div class="jj_text_pc">
              {{user_info.desc?user_info.desc:'这个人很懒，什么都没留下'}}
            </div>

            <div class="jj_text_mobile">
              {{user_info.desc?user_info.desc:'这个人很懒，什么都没留下'}}
            </div>

          </div>
        </div>

        <UserGalleryContent :user_name="user_name" @dataLoadeduser="onDataLoadeduser"/>

      </el-main>
    </el-container>

    <!--    底部菜单组件-->
    <BottomMenu/>
  </div>
</template>

<script>
import UserGalleryContent from "@/components/UserGalleryContent.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import {userFollowRequest, userInfoRequest} from "@/api/api";
import LeftSidebar from "@/components/LeftSidebar.vue";
import ava_bg from '@/assets/ava_bg.png';
import {ElMessage} from "element-plus";
import UserFansModal from "@/components/UserFansModal.vue";
import UserFollowUserModal from "@/components/UserFollowUserModal.vue";
export default {
  name: 'UserProfile',
  components: {
    UserFollowUserModal,
    UserFansModal,
    LeftSidebar,
    BottomMenu,
    UserGalleryContent,
  },
  data() {
    return {
      userloading: false, // 控制页面的加载动画
      //从路由参数中获取文章id
      user_name: this.$route.params.username,
      user_info: {},
      avatarUrl: '', // 替换为实际头像 URL
      ava_bg:ava_bg,
    };
  },
  watch: {
    '$route.params.username': {
      handler(newUsername) {
        this.user_name = newUsername;
        this.getData();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 子组件通知父组件数据加载完成
    onDataLoadeduser() {
      this.userloading = false; // 隐藏加载动画
    },

    toggleFollow() {
      let type = 1;
      if (this.user_info.is_followed_by_me) {
        //已经关注提交取消
        type = 0;
      } else {
        type = 1;
      }
      userFollowRequest(this.user_info.id, {type: type}).then(response => {
        if (response.code === 200) {
          this.user_info.is_followed_by_me = !this.user_info.is_followed_by_me;
          ElMessage({
            message: type === 1 ? '已关注' : '已取消关注',
            type: type === 1 ? 'success' : 'info',
          });
        } else {
          ElMessage({
            message: response.message,
            type: 'error',
          });
        }
      });
    },

    getData() {
      this.userloading = true;
      userInfoRequest({user_name:this.user_name}).then(response => {
        if (response.code === 200) {
          this.user_info=response.data;
          this.userloading = false;
          // this.username = response.data.username;
          // this.avatarUrl = response.data.avatarUrl;
        }

      });
    },
    goToUserProfileSetUp() {
      this.$router.push({name: 'UserProfileSetUp'});
    },
    goToPhoneEditProfile() {
      this.$router.push({name: 'PhoneEditProfile'});
    },
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .avatar-container{
    width: 77px !important;
    height: 77px !important;
  }
}
.avatar-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.avatar-section {
  width: 150px;
  height: 150px;
}

.ccc {
  display: flex;
  flex-direction: row;
}

.ccc_w {
  display: flex;
  flex-direction: column;
}

.avatar_img {
  width: 150px;
  height: 150px;
}

.info_header {
  display: flex;
  flex-direction: row;
}

.actions {
  margin-left: 30px;
}

.jj_text_pc {
  width: 312px;
  font-size: 13px;
  margin-top: -50px;
  margin-left: 205px;
}

.jj_text_mobile {
  display: none;
}

@media (max-width: 1200px) {
  .avatar-section {
    margin-right: 30px !important;
  }

  .actions {
    margin-left: 20px !important;
  }
}

@media (max-width: 992px) {
  .jj_text_pc {
    margin-left: 173px !important;
  }

  .avatar-section {
    margin-right: 20px !important;
  }

  .actions {
    margin-left: 10px !important;
  }
}

@media (max-width: 768px) {
  .el-main {
    padding: 20px 0px !important;
  }

  .actions {
    gap: 3px !important;
  }

  .stat-item {
    flex-direction: column !important;
  }

  .avatar-section {
    width: 77px;
    height: 77px;
  }

  .stats {
    margin-top: 0px !important;
  }

  .jj_text_mobile {
    display: block;
    text-align: left;
    width: 70%;
    margin-top: 20px;
    font-size: 13px;
  }

  .bt_left {
    margin-left: 0px !important;
  }

  .actions {
    margin-left: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    margin: 10px 0px;
  }

  .ccc {
    display: flex;
    flex-direction: row;
  }

  .avatar_img {
    width: 77px;
    height: 77px;
  }


  .avatar-section {
    margin-right: 22px !important;
  }

  .info_header {
    display: flex;
    flex-direction: column;
  }

  .jj_text_pc {
    display: none;
  }

  .jj_text_mobile {
    display: block;
  }
}

@media (max-width: 600px) {
  .jj_text_mobile {
    display: block;
    text-align: left;
    width: 100% !important;
  }
}



.profile-card {
  display: flex;
  flex-direction: row;
  padding: 20px;
  max-width: 935px;
  margin: auto;
  background-color: var(--background-color);
  border-radius: 12px;
  gap: 20px;
}

.avatar-section {
  flex: 0 0 auto;
  margin-right: 55px;
}


.info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.username {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.stats {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.stat-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.stat-number {
  font-weight: bold;
  font-size: 1rem;
}

.stat-label {
  font-weight: 500;
  font-size: 1.0rem;
  margin-left: 4px;
}

.actions {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .profile-card {
    flex-direction: column;
    text-align: center;
  }

  .stats {
    justify-content: space-around;
    width: 100%;
  }
}
</style>
<template>
  <div class="post_flow post_edit">
    <el-dialog
        v-model="isVisible"
        :before-close="handleClose"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        @open="doOpen"
        @close="closeModal"
        :modal="true"
        :width="dialogWidth"
        :style="{ height: dialogHeight}"
    >
      <template #header>编辑作品{{ plan_info ? "-" + plan_info.title : "" }}</template>

      <!-- 文件输入元素，始终存在于 DOM 中 -->
      <input
          ref="fileInput"
          type="file"
          accept="image/*,video/mp4"
          multiple
          @change="handleFileChange"
          style="display: none;"
      />
      <!-- 封面图片输入 -->
      <input
          ref="coverInput"
          type="file"
          accept="image/*"
          @change="handleCoverChange"
          style="display: none;"
      />

      <!-- 整个对话框的内容需要有一个根元素 -->
      <div>
        <!-- 步骤1：文件上传 -->
        <div v-if="step === 1" class="upload-step">
          <svg aria-label="表示图片或视频等素材的图标" class="mb_f30" fill="currentColor" height="77" role="img"
               viewBox="0 0 97.6 77.3" width="96"><title>表示图片或视频等素材的图标</title>
            <path
                d="M16.3 24h.3c2.8-.2 4.9-2.6 4.8-5.4-.2-2.8-2.6-4.9-5.4-4.8s-4.9 2.6-4.8 5.4c.1 2.7 2.4 4.8 5.1 4.8zm-2.4-7.2c.5-.6 1.3-1 2.1-1h.2c1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1-1.7 0-3.1-1.4-3.1-3.1 0-.8.3-1.5.8-2.1z"
                fill="currentColor"></path>
            <path
                d="M84.7 18.4 58 16.9l-.2-3c-.3-5.7-5.2-10.1-11-9.8L12.9 6c-5.7.3-10.1 5.3-9.8 11L5 51v.8c.7 5.2 5.1 9.1 10.3 9.1h.6l21.7-1.2v.6c-.3 5.7 4 10.7 9.8 11l34 2h.6c5.5 0 10.1-4.3 10.4-9.8l2-34c.4-5.8-4-10.7-9.7-11.1zM7.2 10.8C8.7 9.1 10.8 8.1 13 8l34-1.9c4.6-.3 8.6 3.3 8.9 7.9l.2 2.8-5.3-.3c-5.7-.3-10.7 4-11 9.8l-.6 9.5-9.5 10.7c-.2.3-.6.4-1 .5-.4 0-.7-.1-1-.4l-7.8-7c-1.4-1.3-3.5-1.1-4.8.3L7 49 5.2 17c-.2-2.3.6-4.5 2-6.2zm8.7 48c-4.3.2-8.1-2.8-8.8-7.1l9.4-10.5c.2-.3.6-.4 1-.5.4 0 .7.1 1 .4l7.8 7c.7.6 1.6.9 2.5.9.9 0 1.7-.5 2.3-1.1l7.8-8.8-1.1 18.6-21.9 1.1zm76.5-29.5-2 34c-.3 4.6-4.3 8.2-8.9 7.9l-34-2c-4.6-.3-8.2-4.3-7.9-8.9l2-34c.3-4.4 3.9-7.9 8.4-7.9h.5l34 2c4.7.3 8.2 4.3 7.9 8.9z"
                fill="currentColor"></path>
            <path
                d="M78.2 41.6 61.3 30.5c-2.1-1.4-4.9-.8-6.2 1.3-.4.7-.7 1.4-.7 2.2l-1.2 20.1c-.1 2.5 1.7 4.6 4.2 4.8h.3c.7 0 1.4-.2 2-.5l18-9c2.2-1.1 3.1-3.8 2-6-.4-.7-.9-1.3-1.5-1.8zm-1.4 6-18 9c-.4.2-.8.3-1.3.3-.4 0-.9-.2-1.2-.4-.7-.5-1.2-1.3-1.1-2.2l1.2-20.1c.1-.9.6-1.7 1.4-2.1.8-.4 1.7-.3 2.5.1L77 43.3c1.2.8 1.5 2.3.7 3.4-.2.4-.5.7-.9.9z"
                fill="currentColor"></path>
          </svg>

          <div class="upload-area" @drop.prevent="handleDrop" @dragover.prevent>
            <p>或将照片和视频拖到这里</p>
            <!--            <el-button class="select_img_btn" @click="selectFiles">从电脑中选择</el-button>-->
            <el-button type="primary" size="large" @click="selectFiles">从电脑中选择</el-button>

          </div>
        </div>
        <!-- 步骤2：图片预览和裁剪 -->
        <div v-else class="edit-step">
          <div class="step3-container">
            <div class="step3_all">
              <div class="step3_all_left">
                <div class="time_line_part">
                  <!--                  <PlanTimeline v-if="plan_info&&plan_info.is_independent===0"  :post_id="postDetail.id" :plan_info="postDetail.plan"/>-->
                </div>

                <div class="img_cut_part">
                  <!-- 图片预览和裁剪 -->
                  <div class="image-editor">
                    <img
                        v-if="currentFileType === 'image'"
                        :src="files[activeIndex]?.croppedDataUrl || files[activeIndex]?.preview"
                        alt="preview"
                        ref="cropImage"
                    />
                    <video
                        v-else
                        ref="videoPreview"
                        :src="files[activeIndex]?.preview"
                        loop
                        autoplay
                        style="max-width: 100%; max-height: 100%;"
                    ></video>
                  </div>

                  <div class="post_flow_bottom">
                    <!-- 裁剪比例选择（仅在当前文件为图片时显示） -->
                    <div class="cj_tool_grop">
                      <div
                          class="toolbar cj_cut_icon_toolbar"
                          v-if="isToolbarVisible && currentFileType === 'image'"
                          ref="toolbar"
                      >
                        <el-radio-group
                            class="cj_bl_item_group"
                            v-model="files[activeIndex].cropRatio"
                            @change="applyCrop"
                        >
                          <el-radio-button class="cj_bl_item" value="original">
                            原版
                            <svg aria-label="“照片轮廓”图标" class="x1lliihq x1n2onr6 x1roi4f4" fill="currentColor"
                                 height="24"
                                 role="img" viewBox="0 0 24 24" width="24"><title>“照片轮廓”图标</title>
                              <path d="M6.549 5.013A1.557 1.557 0 1 0 8.106 6.57a1.557 1.557 0 0 0-1.557-1.557Z"
                                    fill-rule="evenodd"></path>
                              <path
                                  d="m2 18.605 3.901-3.9a.908.908 0 0 1 1.284 0l2.807 2.806a.908.908 0 0 0 1.283 0l5.534-5.534a.908.908 0 0 1 1.283 0l3.905 3.905"
                                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path>
                              <path
                                  d="M18.44 2.004A3.56 3.56 0 0 1 22 5.564h0v12.873a3.56 3.56 0 0 1-3.56 3.56H5.568a3.56 3.56 0 0 1-3.56-3.56V5.563a3.56 3.56 0 0 1 3.56-3.56Z"
                                  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2"></path>
                            </svg>
                          </el-radio-button>
                          <el-radio-button class="cj_bl_item" value="1:1">
                            1:1
                            <svg aria-label="“裁剪成正方形”图标" class="x1lliihq x1n2onr6 x9bdzbf" fill="currentColor"
                                 height="24" role="img" viewBox="0 0 24 24" width="24"><title>“裁剪成正方形”图标</title>
                              <path
                                  d="M19 23H5a4.004 4.004 0 0 1-4-4V5a4.004 4.004 0 0 1 4-4h14a4.004 4.004 0 0 1 4 4v14a4.004 4.004 0 0 1-4 4ZM5 3a2.002 2.002 0 0 0-2 2v14a2.002 2.002 0 0 0 2 2h14a2.002 2.002 0 0 0 2-2V5a2.002 2.002 0 0 0-2-2Z"></path>
                            </svg>
                          </el-radio-button>
                          <el-radio-button class="cj_bl_item" value="4:5">
                            4:5
                            <svg aria-label="“裁剪成纵向图形”图标" class="x1lliihq x1n2onr6 x1roi4f4"
                                 fill="currentColor"
                                 height="24" role="img" viewBox="0 0 24 24" width="24"><title>
                              “裁剪成纵向图形”图标</title>
                              <path
                                  d="M16 23H8a4.004 4.004 0 0 1-4-4V5a4.004 4.004 0 0 1 4-4h8a4.004 4.004 0 0 1 4 4v14a4.004 4.004 0 0 1-4 4ZM8 3a2.002 2.002 0 0 0-2 2v14a2.002 2.002 0 0 0 2 2h8a2.002 2.002 0 0 0 2-2V5a2.002 2.002 0 0 0-2-2Z"></path>
                            </svg>
                          </el-radio-button>
                          <el-radio-button class="cj_bl_item" value="16:9">
                            16:9
                            <svg aria-label="“裁剪成横向图形”图标" class="x1lliihq x1n2onr6 x1roi4f4"
                                 fill="currentColor"
                                 height="24" role="img" viewBox="0 0 24 24" width="24"><title>
                              “裁剪成横向图形”图标</title>
                              <path
                                  d="M19 20H5a4.004 4.004 0 0 1-4-4V8a4.004 4.004 0 0 1 4-4h14a4.004 4.004 0 0 1 4 4v8a4.004 4.004 0 0 1-4 4ZM5 6a2.002 2.002 0 0 0-2 2v8a2.002 2.002 0 0 0 2 2h14a2.002 2.002 0 0 0 2-2V8a2.002 2.002 0 0 0-2-2Z"></path>
                            </svg>
                          </el-radio-button>
                        </el-radio-group>
                      </div>
                      <div class="cj_cut_icon" @click="toggleToolbar" ref="toggleButton">
                        <!-- 裁剪按钮的SVG图标保持不变 -->
                        <svg aria-label="选择“裁剪”" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="16"
                             role="img" viewBox="0 0 24 24" width="16"><title>选择“裁剪”</title>
                          <path
                              d="M10 20H4v-6a1 1 0 0 0-2 0v7a1 1 0 0 0 1 1h7a1 1 0 0 0 0-2ZM20.999 2H14a1 1 0 0 0 0 2h5.999v6a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1Z"></path>
                        </svg>
                      </div>
                    </div>

                    <!-- 底部导航栏 -->
                    <div class="bottom-bar">
                      <!-- 图片缩略图 -->
                      <!-- 图片缩略图 -->
                      <div class="thumbnail-scroll-container" v-if="isAddimgVisible">

                        <!-- 左侧滚动按钮 -->
                        <button
                            v-if="showLeftScroll"
                            class="scroll-button left"
                            @click="scrollLeft"
                            aria-label="向左滚动"
                        >
                          <!-- 左箭头图标 -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                          </svg>
                        </button>

                        <!-- 滚动区域 -->
                        <div class="thumbnail-container" ref="thumbnailContainer">
                          <Container @drop="onDrop" class="thumbnail-list" orientation="horizontal">
                            <Draggable v-for="(file, index) in files" :key="file.uniqueId" v-memo="file.uniqueId">
                              <div class="thumbnail-wrapper">
                                <img
                                    v-if="file.type === 'image'"
                                    :src="file.croppedDataUrl || file.originalDataUrl"
                                    alt="preview thumbnail"
                                    class="thumbnail"
                                    :class="{ active: index === activeIndex }"
                                    @click="setActiveImage(index)"
                                    @load="updateScrollButtons"
                                />
                                <img
                                    v-else
                                    :src="file.coverImage"
                                    class="thumbnail"
                                    :class="{ active: index === activeIndex }"
                                    @click="setActiveImage(index)"
                                    @loadedmetadata="updateScrollButtons"
                                    :controls="false"
                                >
                                <span class="close-btn" @click.stop="removeImage(index)">×</span>
                              </div>
                            </Draggable>
                          </Container>

                        </div>
                        <!-- 添加更多文件按钮 -->
                        <div class="add_more_kk">
                          <div class="add-more" @click="selectFiles">
                            <span class="add-icon">+</span>
                          </div>
                        </div>
                        <!-- 右侧滚动按钮 -->
                        <button
                            v-if="showRightScroll"
                            class="scroll-button right"
                            @click="scrollRight"
                            aria-label="向右滚动"
                        >
                          <!-- 右箭头图标 -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 1 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                          </svg>
                        </button>
                      </div>

                      <div class="add_img_icon" @click="toggleAddToolbar">
                        <!-- 打开媒体库的SVG图标保持不变 -->
                        <svg aria-label="打开媒体库" class="x1lliihq x1n2onr6 x9bdzbf" fill="currentColor" height="16"
                             role="img" viewBox="0 0 24 24" width="16"><title>打开媒体库</title>
                          <path
                              d="M19 15V5a4.004 4.004 0 0 0-4-4H5a4.004 4.004 0 0 0-4 4v10a4.004 4.004 0 0 0 4 4h10a4.004 4.004 0 0 0 4-4ZM3 15V5a2.002 2.002 0 0 1 2-2h10a2.002 2.002 0 0 1 2 2v10a2.002 2.002 0 0 1-2 2H5a2.002 2.002 0 0 1-2-2Zm18.862-8.773A.501.501 0 0 0 21 6.57v8.431a6 6 0 0 1-6 6H6.58a.504.504 0 0 0-.35.863A3.944 3.944 0 0 0 9 23h6a8 8 0 0 0 8-8V9a3.95 3.95 0 0 0-1.138-2.773Z"
                              fill-rule="evenodd"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="step3_all_right">
                <!-- 右侧：帖子内容输入和封面选择 -->
                <div class="content-input">

                  <!-- 帖子内容输入 -->
                  <el-input
                      class="text_area_style"
                      type="textarea"
                      v-model="postContent"
                      placeholder="添加内容"
                      :rows="6"
                      :maxlength="500" show-word-limit
                  ></el-input>

                  <!-- 表情选择器 -->
                  <div class="emoji-picker-container">
                    <el-button class="bq_icon_btn" @click="toggleEmojiPicker">
                      <svg data-v-4d9c2806="" t="1729750205868" class="icon icon_bq" viewBox="0 0 1040 1024"
                           version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="20459" width="24" height="24">
                        <path data-v-4d9c2806="" d="M512.075261
                 1024A511.774217 511.774217 0 1 1 730.482434
                 48.769072a37.630457 37.630457 0 1 1-32.061149
                 68.035867 436.513303 436.513303 0 1 0
                 250.468323 395.270322 37.630457 37.630457 0 0 1
                 75.260914 0 512.526826 512.526826 0 0
                 1-512.075261 511.924739z" fill="" p-id="20460"></path>
                        <path data-v-4d9c2806="" d="M333.857416
                 344.0929a57.348817 57.348817 0 1 0 57.348817
                 57.348817 57.499339 57.499339 0 0
                 0-57.348817-57.348817zM686.53006
                 344.0929a57.348817 57.348817 0 1 0 57.348817
                 57.348817 57.348817 57.348817 0 0
                 0-57.348817-57.348817zM515.236219
                 783.165074c-162.864619
                 0-262.359547-141.942084-262.359547-219.159782a30.104366
                 30.104366 0 0 1 60.208731 0c0
                 48.618551 76.314567 158.951051
                 202.150816 158.951051s193.571072-134.114949
                 193.571072-158.951051a30.104366 30.104366 0 0
                 1 60.208731 0c0 54.488902-90.012054
                 219.159782-253.779803
                 219.159782zM1009.549904
                 207.720123h-67.132735V139.985301a30.104366
                 30.104366 0 1 0-60.208732
                 0v67.734822h-67.734822a30.104366 30.104366
                 0 0 0-30.104366 30.104366 30.104366
                 30.104366 0 0 0 30.104366
                 30.104366h67.734822v67.734823a30.104366
                 30.104366 0 0 0 60.208732
                 0v-67.734823h67.734823a30.104366 30.104366
                 0 0 0 30.104365-30.104366 30.104366
                 30.104366 0 0 0-30.706453-30.104366z" fill="" p-id="20461"></path>
                      </svg>
                    </el-button>
                    <!-- 使用自定义元素 emoji-picker -->
                    <emoji-picker v-if="showEmojiPicker" @emoji-click="addEmoji"></emoji-picker>
                  </div>

                  <!-- 封面图片上传 -->
                  <div class="cover-upload">
                    <p>选择帖子封面（可选）:</p>
                    <div class="cover-preview" @click="selectCover">
                      <img :src="coverImage || defaultCoverImage" alt="封面预览"/>
                    </div>
                  </div>

                  <!-- 操作按钮 -->
                  <div class="action-buttons">
                    <el-button type="primary" size="large" @click="editPlan">修改作品详情/计划</el-button>
                    <el-button type="primary" size="large" @click="publishPost">保存</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end of root div -->
    </el-dialog>
  </div>
</template>


<script>
import {nextTick} from 'vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import {Container, Draggable} from 'vue3-smooth-dnd';
// 引入 emoji-picker-element
import 'emoji-picker-element';
// import PlanTimeline from "@/components/PlanTimeline.vue";
import {editPlanNodeRequest} from "@/api/api";
import {ElLoading, ElMessage} from "element-plus";
import {modalStore} from "@/stores/modalStore";
import {mapActions, mapState} from 'pinia';
import {StatusStore} from "@/stores/StatusStore";
import default_cover from '@/assets/default-cover.png';

export default {
  name: 'CreatePostFlow',
  components: {
    // PlanTimeline,
    Container,
    Draggable
  },
  props: {},
  data() {
    return {
      isSpecialEntry: false, // 用来标识是否是从主组件进入模态框的标识
      planModalVisible: false,
      // 用于控制工具栏显示与隐藏的变量
      isToolbarVisible: false,
      isAddimgVisible: false,
      dialogWidth: '90%', // 初始宽度
      dialogHeight: '700px', // 初始高度
      step: 2, // 当前步骤
      files: [], // 已上传的文件列表
      activeIndex: 0, // 当前裁剪的文件索引
      postTitle: '', // 帖子标题（新增部分）
      postContent: '', // 帖子内容
      cropper: null, // Cropper实例
      croppedImages: [], // 裁剪后的图像列表
      coverImage: null, // 封面图片
      coverImageFile: null,
      defaultCoverImage: null, // 默认封面图片（第一张裁剪后的图片）
      videoCoverImage: null, // 从视频中提取的封面图片
      showEmojiPicker: false, // 控制表情选择器的显示
      currentFileType: 'image', // 当前文件类型
      // 新增：控制滚动按钮的显示
      showLeftScroll: true,
      showRightScroll: true,
      del_medias_id: [],
      default_cover: default_cover,
    };
  },
  computed: {
    ...mapState(modalStore, ['isShowEditNode', 'edit_node_info']),
    isVisible() {
      return this.isShowEditNode;
    },
    postDetail() {
      return this.edit_node_info;
    },
    plan_info() {
      return this.edit_node_info.plan;
    },
  },
  mounted() {
    // 新增：监听滚动事件
    this.$nextTick(() => {
      this.updateScrollButtons(); // 初始化滚动按钮状态
    });
    window.addEventListener('resize', this.updateScrollButtons); // 监听窗口大小变化

    // 新增：监听滚动事件
    const container = this.$refs.thumbnailContainer;
    if (container) {
      container.addEventListener('scroll', this.updateScrollButtons);
    }

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScrollButtons); // 移除窗口大小变化监听器

    // 新增：移除滚动事件监听器
    const container = this.$refs.thumbnailContainer;
    if (container) {
      container.removeEventListener('scroll', this.updateScrollButtons);
    }
  },
  watch: {
    activeIndex() {
      // 当 activeIndex 改变时，更新 currentFileType，并根据是否裁剪初始化裁剪器
      nextTick(() => {
        if (this.files[this.activeIndex]) {
          this.currentFileType = this.files[this.activeIndex].type;
          if (this.currentFileType === 'image') {
            const currentFile = this.files[this.activeIndex];
            if (currentFile.isCropped) {
              this.initializeCropper();
            } else {
              if (this.cropper) {
                this.cropper.destroy();
                this.cropper = null;
              }
            }
          } else {
            if (this.cropper) {
              this.cropper.destroy();
              this.cropper = null;
            }
          }
        } else {
          this.currentFileType = null;
        }
        this.updateScrollButtons(); // 更新滚动按钮状态
      });
    },
    files() {
      this.$nextTick(() => {
        this.updateScrollButtons(); // 当文件列表变化时，更新滚动按钮状态
      });
    },
  },
  methods: {
    ...mapActions(modalStore, ['setSelectPlan', 'hideEditNodeModal', 'getEditNodeInfo', 'showEditPlanModal']),
    ...mapActions(StatusStore, ['setNeedGetDetail']),
    doOpen() {
      this.resetComponent();
      this.plan_info = this.postDetail.plan;
      //遍历postDetail的medias文件放到files中
      if (this.postDetail.cover_image) {
        this.coverImage = "https://bbs-oss.realtime.xyz/" + this.postDetail.cover_image; // 设置封面图片
      } else {
        if (this.postDetail.medias && this.postDetail.medias.length > 0) {
          this.coverImage = "https://bbs-oss.realtime.xyz/" + this.postDetail.medias[0].media_url;
        }
      }
      this.postDetail.medias.forEach((media) => {
        const url = "https://bbs-oss.realtime.xyz/" + media.media_url;
        let cover_url;
        //根据media_url后缀判断是图片还是视频
        let type = 'image';
        if (media.media_url.endsWith(".mp4") || media.media_url.endsWith(".mov") || media.media_url.endsWith(".avi") || media.media_url.endsWith(".flv") || media.media_url.endsWith(".wmv") || media.media_url.endsWith(".rmvb") || media.media_url.endsWith(".3gp") || media.media_url.endsWith(".mkv")) {
          type = "video";
          if (media.cover_url) {
            cover_url = "https://bbs-oss.realtime.xyz/" + media.cover_url;
          } else {
            cover_url = this.default_cover;
          }


        } else {
          type = "image";
        }
        let file_item = {
          media_id: media.id,
          preview: url,
          originalDataUrl: url, // 保存原始图像的URL
          uniqueId: Date.now() + Math.random(),
          type,
          cropRatio: 'original', // 每个文件的裁剪比例
          isCropped: false,      // 每个文件的裁剪状态
          croppedDataUrl: null,  // 裁剪后的数据
          coverImage: cover_url,
        };
        this.files.push(file_item);
        // if(type === 'video'){
        //   this.getUrlVideoCover(url,file_item);
        // }

        // 如果是视频并且只有一个文件，尝试提取封面
        if (type === 'video' && this.files.length === 1) {
          // this.extractVideoCover(url);
          this.currentFileType = "video";
        }
        this.videoCoverImage = this.postDetail.plan.cover;
      });

      this.postContent = this.postDetail.description;
      if (!this.plan_info || !this.plan_info.id) {
        ElMessage.error('无法获取计划信息，请重新选择计划。');
        this.hideEditNodeModal(); // 关闭对话框
      }
    },

    editPlan() {
      this.setSelectPlan(this.plan_info);
      this.showEditPlanModal(this.plan_info.is_independent === 1);
    },
    closeModal() {
      this.hideEditNodeModal();
    },
    goUpload(isSpecialEntry) {
      this.isSpecialEntry = isSpecialEntry; // 设置标识
      this.planModalVisible = true;
    },
    // 切换工具栏显示状态
    toggleToolbar() {
      this.isToolbarVisible = !this.isToolbarVisible;
      if (this.isToolbarVisible) {
        // 添加全局点击事件监听器
        document.addEventListener('click', this.handleClickOutside);
      } else {
        // 移除全局点击事件监听器
        this.removeClickListener();
      }
    },
    toggleAddToolbar() {
      this.isAddimgVisible = !this.isAddimgVisible;
      this.$nextTick(() => {
        this.updateScrollButtons(); // 更新滚动按钮状态
      });
    },

    // 新增：处理点击外部关闭工具栏
    handleClickOutside(event) {
      const toolbar = this.$refs.toolbar;
      const toggleButton = this.$refs.toggleButton;
      if (
          toolbar &&
          !toolbar.contains(event.target) &&
          toggleButton &&
          !toggleButton.contains(event.target)
      ) {
        this.isToolbarVisible = false;
        this.removeClickListener();
      }
    },

    // 新增：移除点击事件监听器
    removeClickListener() {
      document.removeEventListener('click', this.handleClickOutside);
    },

    // 选择文件
    selectFiles() {
      this.$nextTick(() => {
        if (this.$refs.fileInput) {
          this.$refs.fileInput.click();
        } else {
          console.error('fileInput ref 未找到，无法打开文件选择器');
        }
        this.updateScrollButtons(); // 更新滚动按钮状态
      });
    },
    /**
     * 验证图片的宽度和宽高比
     * @param {File} file - 图片文件
     * @returns {Promise} - 验证通过时 resolve，验证失败时 reject
     */
    validateImageDimensions(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;
            const aspectRatio2 = height / width;


            if (width < 1024) {
              reject(`图片 "${file.name}" 的宽度小于 1024 像素。`);
              return;
            }
            if (height < 1024) {
              reject(`图片 "${file.name}" 的高度小于 1024 像素。`);
              return;
            }

            if (aspectRatio > 2.39 || aspectRatio2 > 2.39) {
              reject(`图片 "${file.name}" 的宽高比超过 2.39:1。`);
              return;
            }

            resolve();
          };
          img.onerror = () => {
            reject(`无法加载图片 "${file.name}"，请选择有效的图片文件。`);
          };
          img.src = e.target.result;
        };
        reader.onerror = () => {
          reject(`无法读取文件 "${file.name}"。`);
        };
        reader.readAsDataURL(file);
      });
    },
    // 文件选择事件处理（多文件支持）
    handleFileChange(event) {
      const files = event.target.files; // 获取选择的文件列表
      const selectedFiles = Array.from(files);
      const promises = selectedFiles.map((file) => this.validateFile(file));
      if(this.files.length+selectedFiles.length>9){
        ElMessage.error(`最多只能上传9个文件。`);
        return;
      }
      Promise.allSettled(promises)
          .then((results) => {
            results.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                const file = selectedFiles[index];
                const url = URL.createObjectURL(file);
                const type = file.type.startsWith('image') ? 'image' : 'video';
                let file_info = {
                  file: file,
                  preview: url,
                  originalDataUrl: url,
                  uniqueId: Date.now() + Math.random(),
                  type: type,
                  cropRatio: 'original',
                  isCropped: false,
                  croppedDataUrl: null,
                  coverImage: null, // 新增：封面图片
                };
                this.files.push(file_info);

                // 如果是视频并且只有一个文件，尝试提取封面
                if (type === 'video') {
                  this.extractVideoCover(url, file_info)
                      .then(() => {
                        console.log(`封面提取成功 for ${file.name}`);
                      })
                      .catch((error) => {
                        if(file){
                          console.error(`封面提取失败 for ${file.name}:`, error);
                          ElMessage.error(`无法提取视频 "${file.name}" 的封面。`);
                        }
                      });
                }

              } else if (result.status === 'rejected') {
                ElMessage.error(result.reason);
              }
            });


            if (this.files.length > 0) {
              this.step = 2; // 切换到裁剪步骤
              this.dialogWidth = '90%';
              this.dialogHeight = 'auto';

              this.croppedImages = this.files.map((file) => {
                if (file.type === 'image') {
                  return file.croppedDataUrl || file.originalDataUrl;
                }
              });

              // 设置默认封面图片为第一张裁剪后的图片
              const firstImage = this.files.find((file) => file.type === 'image');
              if (firstImage) {
                this.defaultCoverImage = firstImage.croppedDataUrl || firstImage.originalDataUrl;
              } else if (this.videoCoverImage) {
                // 如果没有图片，但有视频封面，使用视频封面
                this.defaultCoverImage = this.videoCoverImage;
              }

              // 如果用户未选择封面，使用默认封面
              if (!this.coverImage) {
                this.coverImage = this.defaultCoverImage;
              }

              this.activeIndex = 0;
              this.currentFileType = this.files[this.activeIndex]?.type;
              //遍历files，如果是视频。则读取一次视频的第一帧作为视频文件自己的coverImage
              this.files.forEach((file) => {
                if (file.type === 'video') {
                  this.getVideoCover(file.originalDataUrl, file)
                      .then(() => {
                        console.log(`封面提取成功 for ${file.file.name}`);
                      })
                      .catch((error) => {
                        if(file){
                          console.error(`封面提取失败 for ${file.name}:`, error);
                          // ElMessage.error(`无法提取视频 "${file.name}" 的封面。`);
                        }
                      });
                }
              });
              nextTick(() => {
                // 不自动初始化裁剪器
              });


            }
          })
          .catch((error) => {
            console.error('文件验证过程中出错:', error);
            ElMessage.error('文件验证过程中出错，请稍后重试。');
          })
          .finally(() => {
            event.target.value = null; // 清空文件选择器
          });
    },
    // 文件选择事件处理（多文件支持）
    // handleFileChange(event) {
    //   const selectedFiles = Array.from(event.target.files);
    //   const promises = selectedFiles.map((file) => this.validateFile(file));
    //   if (this.files.length + selectedFiles.length > 9) {
    //     ElMessage.error(`最多只能上传9个文件。`);
    //     return;
    //   }
    //   Promise.allSettled(promises)
    //       .then((results) => {
    //
    //         results.forEach((file) => {
    //           console.log(file)
    //           const url = URL.createObjectURL(file);
    //           const type = file.type.startsWith('image') ? 'image' : 'video';
    //           this.files.push({
    //             file,
    //             preview: url,
    //             originalDataUrl: url, // 保存原始图像的URL
    //             uniqueId: Date.now() + Math.random(),
    //             type,
    //             cropRatio: 'original', // 每个文件的裁剪比例
    //             isCropped: false,      // 每个文件的裁剪状态
    //             croppedDataUrl: null,  // 裁剪后的数据
    //           });
    //           // 如果是视频并且只有一个文件，尝试提取封面
    //           if (type === 'video' && selectedFiles.length === 1 && this.files.length === 1) {
    //             this.extractVideoCover(url);
    //           }
    //         });
    //         if (this.files.length > 0) {
    //           this.step = 2; // 切换到裁剪步骤
    //           this.dialogWidth = '90%';
    //           this.dialogHeight = 'auto';
    //
    //           this.croppedImages = this.files.map((file) => {
    //             if (file.type === 'image') {
    //               return file.croppedDataUrl || file.preview;
    //             } else {
    //               return file.preview;
    //             }
    //           });
    //
    //           // 设置默认封面图片为第一张裁剪后的图片
    //           const firstImage = this.files.find((file) => file.type === 'image');
    //           if (firstImage) {
    //             this.defaultCoverImage = firstImage.croppedDataUrl || firstImage.originalDataUrl;
    //           } else if (this.videoCoverImage) {
    //             // 如果没有图片，但有视频封面，使用视频封面
    //             this.defaultCoverImage = this.videoCoverImage;
    //           }
    //           // 如果用户未选择封面，使用默认封面
    //           if (!this.coverImage) {
    //             this.coverImage = this.defaultCoverImage;
    //           }
    //
    //           this.activeIndex = 0;
    //           this.currentFileType = this.files[this.activeIndex]?.type;
    //           //遍历files，如果是视频。则读取一次视频的第一帧作为视频文件自己的coverImage
    //           this.files.forEach((file) => {
    //             if (file.type === 'video' && !file.media_id) {
    //               console.log(file)
    //               this.getVideoCover(file.originalDataUrl, file)
    //                   .then(() => {
    //                     console.log(`封面提取成功 for ${file.file.name}`);
    //                   })
    //                   .catch((error) => {
    //                     console.error(`封面提取失败 for ${file.file.name}:`, error);
    //                     ElMessage.error(`无法提取视频 "${file.file.name}" 的封面。`);
    //                   });
    //             }
    //           });
    //           nextTick(() => {
    //             // 不自动初始化裁剪器
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         console.error('文件验证过程中出错:', error);
    //         ElMessage.error('文件验证过程中出错，请稍后重试。');
    //       })
    //       .finally(() => {
    //         event.target.value = null; // 清空文件选择器
    //       });
    //
    // },
    /**
     * 验证文件类型和大小，并对图片进行额外的尺寸和宽高比验证
     * @param {File} file - 上传的文件
     * @returns {Promise} - 返回一个 Promise，验证通过时 resolve，验证失败时 reject
     */
    validateFile(file) {
      return new Promise((resolve, reject) => {
        const isImage = file.type.startsWith('image/');
        const isVideo = file.type.startsWith('video/');
        const maxImageSize = 10 * 1024 * 1024; // 10MB
        const maxVideoSize = 50 * 1024 * 1024; // 50MB

        // 类型验证
        if (!isImage && !isVideo) {
          reject(`文件 "${file.name}" 类型不符合要求。`);
          return;
        }

        // 大小验证
        if (isImage && file.size > maxImageSize) {
          reject(`图片 "${file.name}" 的大小超过 10MB，请选择较小的图片。`);
          return;
        }

        if (isVideo && file.size > maxVideoSize) {
          reject(`视频 "${file.name}" 的大小超过 50MB，请选择较小的视频。`);
          return;
        }

        // 如果是图片，进行尺寸和宽高比验证
        if (isImage) {
          this.validateImageDimensions(file)
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
        } else {
          // 如果不是图片，直接通过验证
          resolve();
        }
      });
    },
    // 拖拽文件处理
    handleDrop(event) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      if (this.files.length + droppedFiles.length > 9) {
        ElMessage.error(`最多只能上传9个文件。`);
        return;
      }
      droppedFiles.forEach((file) => {
        const url = URL.createObjectURL(file);
        const type = file.type.startsWith('image') ? 'image' : 'video';
        this.files.push({
          file,
          preview: url,
          originalDataUrl: url, // 保存原始图像的URL
          uniqueId: Date.now() + Math.random(),
          type,
          cropRatio: 'original', // 每个文件的裁剪比例
          isCropped: false,      // 每个文件的裁剪状态
          croppedDataUrl: null,  // 裁剪后的数据
        });
        // 如果是视频并且只有一个文件，尝试提取封面
        if (type === 'video' && droppedFiles.length === 1 && this.files.length === 1) {
          this.extractVideoCover(url);
        }
      });
      if (this.files.length > 0) {
        this.step = 2;
        this.activeIndex = 0;
        this.currentFileType = this.files[this.activeIndex]?.type;
        //遍历files，如果是视频。则读取一次视频的第一帧作为视频文件自己的coverImage
        this.files.forEach((file) => {
          if (file.type === 'video') {
            this.getVideoCover(file.originalDataUrl, file)
                .then(() => {
                  console.log(`封面提取成功 for ${file.file.name}`);
                })
                .catch((error) => {
                  if(file){
                    console.error(`封面提取失败 for ${file.file.name}:`, error);
                    // ElMessage.error(`无法提取视频 "${file.file.name}" 的封面。`);
                  }
                });
          }
        });
        nextTick(() => {
          // 不自动初始化裁剪器
        });
      }
    },
    // 从视频中提取第一帧作为封面
    getVideoCover(videoUrl, file_info) {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = videoUrl;
        video.crossOrigin = 'Anonymous'; // 处理跨域问题
        video.load();

        video.addEventListener('loadeddata', () => {
          video.currentTime = 0;
        });

        video.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageUrl = canvas.toDataURL('image/png');
          file_info.coverImage = imageUrl;
          const index = this.files.findIndex(f => f.uniqueId === file_info.uniqueId);
          if (index !== -1) {
            // 使用 splice 替换整个对象，触发响应式更新
            this.files.splice(index, 1, {...file_info});
          }
          resolve(imageUrl);

        });

        video.addEventListener('error', (e) => {
          console.error('无法加载视频，提取封面失败', e);
          reject(e);
        });
      });
    },
    //获取在线视频类型的封面
    getUrlVideoCover(url, item) {
      // 获取新的视频
      let new_video = document.createElement('video')
      // 获取老的视频链接地址
      new_video.crossOrigin = 'anonymous';
      // 重点 为新video的src赋值 这里给URL增加后缀是防止浏览器对视频进行缓存，否则截图无法成功
      new_video.src = url + "?t=" + new Date();
      new_video.load();
      console.log(url)
      // 重点 此处新video的视频进度如果是0则无法截取图片，随意这是一个比0大的数字即可
      new_video.currentTime = 0.000001
      // 重点 开启跨域支持

      new_video.addEventListener('loadeddata', () => {
        new_video.currentTime = 0;
      });
      new_video.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');

        canvas.width = new_video.videoWidth;
        canvas.height = new_video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(new_video, 0, 0, canvas.width, canvas.height);
        const imageUrl = canvas.toDataURL('image/png');
        this.videoCoverImage = imageUrl;
        // 如果用户未选择封面，使用提取的封面
        if (!this.coverImage) {
          item.coverImage = this.videoCoverImage;
        }
      });

    },
    // 从视频中提取第一帧作为封面
    extractVideoCover(videoUrl) {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = videoUrl;
        video.crossOrigin = 'Anonymous'; // 处理跨域问题
        video.load();
        video.addEventListener('loadeddata', () => {
          video.currentTime = 0;
        });
        video.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageUrl = canvas.toDataURL('image/png');
          this.videoCoverImage = imageUrl;
          // 如果用户未选择封面，使用提取的封面
          if (!this.coverImage) {
            this.coverImage = this.videoCoverImage;
          }
          resolve(imageUrl);
        });
        video.addEventListener('error', (e) => {
          console.error('无法加载视频，提取封面失败', e);
          reject(e);
        });
      });
    },
    // 初始化Cropper实例
    initializeCropper() {
      if (this.cropper) this.cropper.destroy(); // 销毁之前的实例
      const image = this.$refs.cropImage;
      if (image) {
        const currentFile = this.files[this.activeIndex];
        const aspectRatio = this.cropRatioToAspect(currentFile.cropRatio);
        if (isNaN(aspectRatio)) {
          // 原版模式，不裁剪
          return;
        }
        this.cropper = new Cropper(image, {
          aspectRatio: aspectRatio,
          viewMode: 1,
          autoCropArea: 1, // 自动裁剪区域
          movable: true,
          zoomable: true,
          scalable: true,
          rotatable: true,
          background: false,
          responsive: true,
          ready: () => {
            // 裁剪器准备好
            this.saveCroppedImage();
          },
          cropend: () => {
            // 当裁剪结束时，保存裁剪后的图像
            this.saveCroppedImage();
            // 将裁剪后的图片实时更新为封面
            this.updateCoverImage();
          },
          error: (e) => {
            console.error('Cropper error', e);
          },
        });
      } else {
        console.error('裁剪图像未加载完成');
      }
    },
    // 将选择的裁剪比例转化为数字
    cropRatioToAspect(cropRatio) {
      switch (cropRatio) {
        case '1:1':
          return 1;
        case '4:5':
          return 4 / 5;
        case '16:9':
          return 16 / 9;
        case 'original':
          return NaN; // 原版
        default:
          return 1;
      }
    },
    // 应用裁剪比例
    applyCrop() {
      const currentFile = this.files[this.activeIndex];
      const aspectRatio = this.cropRatioToAspect(currentFile.cropRatio);
      if (isNaN(aspectRatio)) {
        // 原版模式
        currentFile.isCropped = false;
        currentFile.croppedDataUrl = currentFile.originalDataUrl;
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = null;
        }
      } else {
        // 设置新的裁剪比例
        currentFile.isCropped = true;
        // 重置为原图
        currentFile.croppedDataUrl = null;
        this.initializeCropper();
      }
      // 保存裁剪的图像由裁剪器的 cropend 事件处理
    },

    updateCoverImage() {
      // 获取裁剪后的第一张图片并作为封面
      const firstImage = this.files.find((file) => file.type === 'image' && file.croppedDataUrl);
      if (firstImage) {
        this.coverImage = firstImage.croppedDataUrl;
      } else if (this.videoCoverImage) {
        // 如果没有图片，使用视频封面
        this.coverImage = this.videoCoverImage;
      }
    },

    // 保存当前裁剪后的图像
    saveCroppedImage() {
      if (this.cropper) {
        const canvas = this.cropper.getCroppedCanvas();
        if (canvas) {
          const croppedDataUrl = canvas.toDataURL('image/png');
          const currentFile = this.files[this.activeIndex];
          canvas.toBlob((blob) => {
            const currentFile = this.files[this.activeIndex];
            const file = new File([blob], currentFile.file.name, {type: 'image/png'});
            currentFile.croppedFile = file;
          }, 'image/png');
          currentFile.croppedDataUrl = croppedDataUrl;
        } else {
          console.error('无法获取裁剪后的画布');
        }
      } else {
        console.error('裁剪器未初始化');
      }
    },
    // 选择封面图片
    selectCover() {
      if (this.$refs.coverInput) {
        this.$refs.coverInput.click();
      } else {
        console.error('coverInput ref 未找到，无法打开文件选择器');
      }
    },
    // 处理封面图片更改
    handleCoverChange(event) {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        this.coverImage = url;
        this.coverImageFile = file; // 将封面图片存储为 File 对象
      }
    },
    // vue3-smooth-dnd 的 onDrop 事件，用于更新文件顺序
    onDrop(dropResult) {
      const {removedIndex, addedIndex} = dropResult;
      if (removedIndex !== null && addedIndex !== null) {
        this.setActiveImage(addedIndex);
        const movedItem = this.files.splice(removedIndex, 1)[0];
        this.files.splice(addedIndex, 0, movedItem);
      }
      this.$nextTick(() => {
        this.updateScrollButtons(); // 更新滚动按钮状态
      });
    },
    // 设置当前活跃的图片，用于点击缩略图切换主图
    setActiveImage(index) {
      this.activeIndex = index;
      this.currentFileType = this.files[this.activeIndex]?.type;
      nextTick(() => {
        if (this.currentFileType === 'image') {
          const currentFile = this.files[this.activeIndex];
          if (currentFile.isCropped) {
            this.initializeCropper();
          } else {
            if (this.cropper) {
              this.cropper.destroy();
              this.cropper = null;
            }
          }
        } else {
          if (this.cropper) {
            this.cropper.destroy();
            this.cropper = null;
          }
        }
        this.updateScrollButtons(); // 更新滚动按钮状态
      });
    },
    // 删除图片
    removeImage(index) {
      console.log(this.files[index])
      if (this.files[index].media_id) {
        this.del_medias_id.push(this.files[index].media_id);
      }
      this.files.splice(index, 1);
      if (this.files.length === 0) {
        this.step = 1; // 如果没有文件了，返回到步骤1
        return;
      }
      if (index === this.activeIndex) {
        // 确保 activeIndex 不超过 files 的长度
        this.activeIndex = Math.min(this.activeIndex, this.files.length - 1);
        this.currentFileType = this.files[this.activeIndex]?.type;
        nextTick(() => {
          const currentFile = this.files[this.activeIndex];
          if (currentFile.type === 'image') {
            if (currentFile.isCropped) {
              this.initializeCropper();
            } else {
              if (this.cropper) {
                this.cropper.destroy();
                this.cropper = null;
              }
            }
          } else {
            if (this.cropper) {
              this.cropper.destroy();
              this.cropper = null;
            }
          }
          this.updateScrollButtons(); // 更新滚动按钮状态
        });
      } else if (index < this.activeIndex) {
        this.activeIndex -= 1; // 调整 activeIndex
        this.updateScrollButtons(); // 更新滚动按钮状态
      }
    },
    // 发布帖子
    publishPost() {
      // 检查 plan_info 是否存在
      if (!this.plan_info || !this.plan_info.id) {
        ElMessage.error('无法获取计划信息，请重新选择或刷新页面后重试。');
        return;
      }


      // 检查是否只有一个视频且未上传封面
      const videoFiles = this.files.filter((file) => file.type === 'video');
      if (videoFiles.length === 1 && this.files.length === 1 && !this.coverImage) {
        if (this.videoCoverImage) {
          this.coverImage = this.videoCoverImage;
        } else {
          ElMessage.error('请为您的视频上传一个封面图片');
          return;
        }
      }

      //必须有内容
      if (!this.postContent) {
        ElMessage.error('请填写内容');
        return;
      }


      const formData = new FormData();
      formData.append('plan_id', this.plan_info.id);
      formData.append('post_id', this.postDetail.id);
      formData.append('title', this.postTitle);
      formData.append('content', this.postContent);
      formData.append('del_media_ids', this.del_medias_id);

      this.files.forEach((file, index) => {
        if (file.croppedFile) {
          formData.append(`files[${index}]`, file.croppedFile);
        } else {
          formData.append(`files[${index}]`, file.file);
        }
        if (file.type === 'video') {
          formData.append(`file_covers[${index}]`, this.dataURLtoBlob(file.coverImage));
        } else {
          formData.append(`file_covers[${index}]`, "");
        }
      });
      if (this.coverImageFile) {
        formData.append('coverImage', this.coverImageFile);
      } else if (this.coverImage) {
        const coverBlob = this.dataURLtoBlob(this.coverImage);
        if (coverBlob) {
          const coverFile = new File([coverBlob], 'cover.png', {type: 'image/png'});
          formData.append('coverImage', coverFile);
        }

      }
      const loading = ElLoading.service({
        lock: true,
        text: '提交中',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      editPlanNodeRequest(formData)
          .then(res => {
            if (res.code === 200) {
              ElMessage.success('作品保存成功');
              this.resetComponent();
              this.hideEditNodeModal(); // 关闭对话框
              this.createDialogVisible = false;
            }else{
              ElMessage.error(res.message);
            }


          })
          .catch(err => {
            console.error(err);
            ElMessage.error('作品保存失败');
          })
          .finally(() => {
            this.$nextTick(() => {
              this.updateScrollButtons(); // 发布后更新滚动按钮状态
              loading.close()
              this.setNeedGetDetail(true);
            });
          });
    },
    // 将 dataURL 转换为 Blob
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      if (arr[0].match(/:(.*?);/)) {
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type: mime});
      }


    },
    // 组件重置
    resetComponent() {
      this.dialogWidth = '700px';
      this.dialogHeight = '700px';
      this.step = 2;
      this.files = [];
      this.del_medias_id = [];
      this.activeIndex = 0;
      this.postTitle = ''; // 重置标题
      this.postContent = '';
      this.cropper = null;
      this.croppedImages = [];
      this.coverImage = null;
      this.defaultCoverImage = null;
      this.videoCoverImage = null;
      this.showEmojiPicker = false;
      this.currentFileType = 'image';
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
      this.updateScrollButtons(); // 重置后更新滚动按钮状态
    },
    // 关闭模态框处理
    handleClose(done) {
      let message = '确定关闭吗？';
      if (this.step > 1) {
        message = '您正在编辑当前节点，确定要放弃吗？';

        this.$confirm(message, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
          confirmButtonType: 'primary',  // 设置确认按钮的类型为危险
          cancelButtonType: 'primary', // 设置取消按钮的类型为默认
        })
            .then(() => {
              // 用户确认后，调用 done() 关闭对话框
              this.resetComponent();
              done();
            })
            .catch(() => {
              // 用户取消关闭，对话框保持打开
              // 不调用 done()
            });
      } else {
        done(); // 在 step === 1 时，直接调用 done() 关闭对话框
      }
    },

    // 切换表情选择器的显示状态
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    // 添加表情到帖子内容
    addEmoji(event) {
      this.postContent += event.detail.unicode;
      this.showEmojiPicker = false; // 选中表情后，收起表情选择器
    },

    // 更新滚动按钮的显示状态
    updateScrollButtons() {
      const container = this.$refs.thumbnailContainer;
      if (container) {
        this.showLeftScroll = container.scrollLeft > 0;
        this.showRightScroll = container.scrollWidth > container.clientWidth + container.scrollLeft + 1; // +1 避免浮点数误差
      }
    },

    // 向左滚动
    scrollLeft() {
      const container = this.$refs.thumbnailContainer;
      if (container) {
        container.scrollBy({left: -200, behavior: 'smooth'}); // 根据需要调整滚动距离
        // 在滚动后更新滚动按钮状态
        setTimeout(() => {
          this.updateScrollButtons();
        }, 300); // 等待滚动完成
      }
    },

    // 向右滚动
    scrollRight() {
      const container = this.$refs.thumbnailContainer;
      if (container) {
        container.scrollBy({left: 200, behavior: 'smooth'}); // 根据需要调整滚动距离
        // 在滚动后更新滚动按钮状态
        setTimeout(() => {
          this.updateScrollButtons();
        }, 300); // 等待滚动完成
      }
    },
  },
};
</script>

<style scoped>
.thumbnail-scroll-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%; /* 确保容器占满父级宽度 */
}

.thumbnail-container {
  display: flex;
  gap: 5px;
  overflow-x: auto;
  align-items: center;
  justify-content: flex-end;
  scroll-behavior: smooth;
  height: 110px;
  padding: 0 10px;
  max-width: calc(100% - 60px);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px 0px 0px 8px;
}

.add_more_kk {
  width: 78px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 8px 8px 0;

}

.thumbnail-container::-webkit-scrollbar {
  height: 8px; /* 设置滚动条高度 */
}

.thumbnail-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道颜色 */
}

.thumbnail-container::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条滑块颜色 */
  border-radius: 4px; /* 滚动条滑块圆角 */
}

.thumbnail-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滚动条滑块悬停颜色 */
}

.thumbnail-list {
  display: flex;
}

.thumbnail-wrapper {
  position: relative;
  margin-right: 10px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  opacity: 0.7;
}

.thumbnail.active {
  border-color: #409EFF;
  opacity: 1;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}


.add-icon {
  font-size: 24px;
  color: #ccc;
}

.add-more:hover {
  border-color: #409EFF;
}

.scroll-button {
  position: absolute;
  background: rgb(255 255 255 / 100%);
  border: none;
  color: #454545;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 60px;
}

.scroll-button svg {
  width: 16px;
  height: 16px;
}

.scroll-button:focus {
  outline: none;
}
</style>


<style>

.cj_bl_item .el-radio-button__inner {
  width: 100%;
  border-radius: 0px !important;
}

.el-input .el-input__count .el-input__count-inner {
  background: none !important;
}

.cover-preview {
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.cover-preview img {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
}


.el-message-box__message p {
  color: #333;
}

.post_flow .el-dialog__close {
  background: #666666 !important;
  color: #fff !important;
  top: -7px;
  position: absolute;
  right: -13px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.post_flow .el-overlay-dialog {
  background: rgba(0, 0, 0, 0.6);
}

.post_flow .el-dialog {
  max-width: 1000px;
  min-height: 600px;
  z-index: 2024 !important;
  background: var(--background-color) !important;
  min-width: 745px;
  width: 90%;
}

.post_flow .el-dialog {
  background-color: var(--background-color) !important;
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.2) !important;
  color: var(--text-color);
}
</style>

<style>
.smooth-dnd-draggable-wrapper {
  overflow: unset !important;
}

.text_area_style textarea {
  resize: vertical !important;
  max-height: 180px !important;
  min-height: 80px !important;
  color: var(--text-color);
}
</style>


<style scoped>
.time_line_part {
  width: 40px;
  margin-right: 10px;
}

.bq_icon_btn {
  background: var(--background-color);
  border-radius: 50%;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0px !important;
  margin-left: 5px;
  margin-top: 5px;

}

.bq_icon_btn:hover {
  background: var(--background-color);
  border-radius: 50%;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0px !important;
  margin-left: 5px;
  margin-top: 5px;

}

.step3_all {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.step3_all_left {
  width: 78%;
  height: auto;
  overflow-y: scroll;
  position: relative;
  margin-right: 2%;
  display: flex;
  flex-direction: row;
}

.img_cut_part {
  width: calc(100% - 50px);
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
}

.step3_all_right {
  width: calc(40% - 6px);
  position: relative;
  height: 600px;
}

.post_flow_bottom {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 15px;
  padding: 0px 15px;
  z-index: 10;
}

.cj_cut_icon_toolbar {
  margin-bottom: 30px;
}

.cj_bl_item {
  margin-bottom: 0px;
  width: 100px;
}

.cj_bl_item_group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100px;
}

.cj_tool_grop {
  display: none;
  align-items: center;
  text-align: left;
  float: left;
  flex-direction: column;
  position: relative;
}

.bt2_next {
  margin-top: -55px;
  margin-bottom: 12px;
  margin-right: 35px;
}

.cj_cut_icon {
  width: 32px;
  height: 32px;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.cj_cut_icon svg {
  fill: #fff;
}

.add_img_icon svg {
  fill: #fff;
}

.add_img_icon {
  width: 32px;
  height: 32px;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 10px;
}

span.el-radio-button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.smooth-dnd-container {
  position: relative;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: row;

}

.mb_f30 {
  margin-bottom: -30px;
}

.select_img_btn {
  background: rgb(0, 149, 246);
}

.select_img_btn:hover {
  background: rgb(0, 101, 166);
}

/* 确保视频按照原始宽高比显示，不被裁剪 */
.image-editor video,
.preview-item video,
.thumbnail video {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

/* 确保视频按照原始宽高比显示，不被裁剪 */
.image-editor video,
.preview-item video,
.thumbnail video {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

/* 其他样式 */

.upload-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-top: 130px;
}

.upload-area {
  text-align: center;
  border: none;
  padding: 40px;
  border-radius: 6px;
  width: 80%;
}

.upload-area p {
  color: #909399;
  margin-top: 10px;
}

.edit-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-editor img {
  width: auto;
  max-height: 600px;
  display: block;
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

.toolbar {
  margin-top: 10px;
}

.bottom-bar {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
}


.thumbnail-wrapper {
  position: relative;
}

.thumbnail {
  width: 90px;
  height: 90px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
}

.thumbnail.active {
  border-color: #409eff;
}


.step3-container {
  display: flex;
  gap: 20px;
  width: 100%;
}

.preview-container {
  flex: 1;
}

.image-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
  //max-height: 691px;
  overflow-y: auto;
}

.preview-item img,
.preview-item video {
  width: 100%;
  object-fit: contain; /* 确保视频和图片按照原始比例显示 */
}

.content-input {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emoji-picker-container {
  position: relative;
  height: 25px;
  line-height: 25px;
  margin-top: -45px;
  margin-bottom: 0px;
}

emoji-picker {
  position: absolute;
  z-index: 1000;
}

.cover-upload {
  margin-top: 10px;
}

.cover-upload p {
  margin-bottom: 5px;
}


.action-buttons {
  display: flex;
  margin-top: 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  justify-content: space-between;
}

/* 确保视频按照原始宽高比显示，不被裁剪 */
.image-editor video,
.preview-item video,
.thumbnail video {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

/* 其他样式 */

.upload-step {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}


.upload-area p {
  color: var(--text-color);
  margin-top: 10px;
  font-size: 18px;
}

.edit-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-editor {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  height: 600px;
}

.image-editor img {
  width: 100%;
  display: block;
}

.toolbar {
  margin-top: 10px;
}


.thumbnail-wrapper {
  position: relative;
}


.thumbnail.active {
  border-color: #409eff;
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  background: #343434;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.add-more {
  min-width: 50px;
  height: 50px;
  border: 2px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
}

.add-more .add-icon {
  font-size: 24px;
  color: #d9d9d9;
}

.content-step {
  padding: 0px;
  position: relative;
}

.bt3_pre {
  position: absolute;
  right: 35px;
  top: -50px;
}

.step3-container {
  display: flex;
  gap: 20px;
}

.preview-container {
  flex: 1;
}


.preview-item img,
.preview-item video {
  width: 100%;
  object-fit: contain; /* 确保视频和图片按照原始比例显示 */
}

.content-input {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


emoji-picker {
  position: absolute;
  z-index: 1000;
}

.cover-upload {
  margin-top: 10px;
}

.cover-upload p {
  margin-bottom: 5px;
}


.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.bottom-bar::-webkit-scrollbar {
  display: block !important;
}
</style>

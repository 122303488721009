<template>
  <div class="new_plan">
    <el-dialog
        class="theme_bg"
        v-model="isVisible"
        :width="dialogWidth"
        @close="hidePlanMenuModal"
        @open="getUsersPlan"
        title="选择作品发布方式"
        min-width="700px"
    >
      <div class="step_1_content">
        <!-- 计划列表展示 -->
        <div class="new_plan_two" :style="newPlanTwoStyle">
          <div class="newplan_group" :style="newPlanGroupStyle" @click="createPlan(true)">
            <svg class="icon" viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg" width="32" height="32">
              <path
                  d="M469.333334 213.333334 469.333334 469.333334 213.333334 469.333334 213.333334 554.666666 469.333334 554.666666 469.333334 810.666664 554.666666 810.666664 554.666666 554.666666 810.666664 554.666666 810.666664 469.333334 554.666666 469.333334 554.666666 213.333334 469.333334 213.333334Z"
                  fill="#0785f7"></path>
            </svg>
            创建独立作品
          </div>
          <div class="newplan_group" :style="newPlanGroupStyle" @click="createPlan(false)">
            <svg class="icon" viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg" width="32" height="32">
              <path
                  d="M469.333334 213.333334 469.333334 469.333334 213.333334 469.333334 213.333334 554.666666 469.333334 554.666666 469.333334 810.666664 554.666666 810.666664 554.666666 554.666666 810.666664 554.666666 810.666664 469.333334 554.666666 469.333334 554.666666 213.333334 469.333334 213.333334Z"
                  fill="#0785f7"></path>
            </svg>
            创建新计划
          </div>
        </div>

        <div class="plan-list" v-if="paginatedPlans.length > 0">
          <el-carousel class="plan_lb" :interval="0" height="" :arrow="paginatedPlans.length > 1 ? 'always' : 'never'"  :indicator-position="paginatedPlans.length > 1 ? 'bottom' : 'none'">
            <el-carousel-item v-for="(planGroup, index) in paginatedPlans" :key="index">
              <div class="plan-item-group">
                <div v-for="plan in planGroup" :key="plan.id" class="plan-item" @click="addPlanNode(plan)">
                  <div v-if="plan.isModified" class="modified-label">
                    <!-- SVG 图标 -->
                  </div>
                  <img :src="plan.cover?'https://bbs-oss.realtime.xyz/'+ plan.cover: defaultCover" alt="封面" class="plan-cover"/>
                  <div class="plan-info">
                    <div class="pl_name">{{ plan.title }}</div>
                    <div class="pl_intrs" v-if="plan.status === 0">新创建</div>
                    <div class="pl_intrs" v-if="plan.status === 1">更新中</div>
                    <div class="pl_intrs" v-if="plan.status === 2">已完结</div>
                    <div class="pl_intrs" v-if="plan.is_independent===1">独立作品</div>
                    <div class="pl_intrs" v-else>计划作品</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import defaultCover from '@/assets/default-cover.png';
import {getCanUpdatePlanRequest} from "@/api/api";
import { modalStore } from "@/stores/modalStore";
import { mapActions, mapState } from 'pinia';
import {ElMessage} from "element-plus";
import dayjs from "dayjs";

export default {
  name: 'NewPlanMenuModal',
  props: {},
  data() {
    return {
      plans: [],
      defaultCover: defaultCover,
    };
  },
  computed: {
    ...mapState(modalStore, ['isShowPlanMenu','selectPlan']),
    isVisible() {
      return this.isShowPlanMenu;
    },
    paginatedPlans() {
      const groupedPlans = [];
      for (let i = 0; i < this.plans.length; i += 4) {
        groupedPlans.push(this.plans.slice(i, i + 4));
      }
      return groupedPlans;
    },
    newPlanTwoStyle() {
      return this.plans.length > 0 ? {} : { width: '100%' };
    },
    dialogWidth() {
      return this.plans.length > 0 ? '90%' : '400px';
    },
    newPlanGroupStyle() {
      return this.plans.length > 0 ? { } : {width: '180px'};
    }
  },
  methods: {
    ...mapActions(modalStore, ['hidePlanMenuModal','showCreateModal','showPlanModal',
      'setSelectPlan','clearSelectPlan','showEditNodeModal','setEditNodeInfo','setSelectNodeId']),
    getWidthValue() {
      return window.innerWidth < 768 ? '360px' : '400px';
    },
    handleResize() {
      this.widthValue = this.getWidthValue();
    },
    getUsersPlan() {
      getCanUpdatePlanRequest({}).then(res => {
        this.plans = res;
      })
    },
    addPlanNode(planInfo) {
      if(planInfo.is_independent===1&&planInfo.nodes.length>0){
        ElMessage({
          message: '该独立作品已经上传过作品。如需编辑请从详情中点击编辑',
          type: 'warning'
        });
      }else{
        //如果不是独立作品，并且nodes中有今天的节点，则提示已经上传过作品
        if(planInfo.is_independent===0) {
            for (let i = 0; i <  planInfo.nodes.length; i++) {
              let node=planInfo.nodes[i];
              if(dayjs.tz().isSame(dayjs.utc(node.date_scheduled).tz(),'day')){
                const message = `此计划/独立作品今天已经更新了进度，你要修改这个进度吗？（一天不能提交2次以上的进度）`;
                this.$confirm(message, {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'info',
                  confirmButtonType: 'primary',
                  cancelButtonType: 'primary',
                }).then(() => {
                  node.plan=planInfo;
                  this.setEditNodeInfo(node);
                  this.setSelectNodeId(node.id);
                  this.showEditNodeModal();
                  this.hidePlanMenuModal();
                }).catch(() => {
                  console.log('用户取消关闭');
                });
                return;
              }
            }
        }
        this.setSelectPlan(planInfo);
        this.showCreateModal();
        this.hidePlanMenuModal();
      }

    },
    createPlan(isIndependent) {
      this.clearSelectPlan();
      this.showPlanModal(isIndependent);
      this.hidePlanMenuModal();
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>


<style>
.el-carousel__arrow {
  background-color: rgba(0,0,0,0.6) !important;
}
.el-carousel__arrow:hover {
  background-color: rgba(0,0,0,0.7) !important;
}

@media (min-width: 1000px) {
  .step_1_content{
    height: 375px !important;
  }
  .step_1_content .el-carousel__container{
    height: 330px !important;
  }
}


@media (min-width: 769px) and (max-width: 1000px) {
  .step_1_content .el-carousel__container{
    height: 335px !important;
  }
}
.step_1_content{
  height: auto;
  min-height: 375px;
}
.plan-list .el-carousel__indicators--horizontal {
  bottom: 0;
  }
.el-textarea__inner {
  background-color: var(--background-color) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

/* 更改日期选择器面板的背景颜色 */
.el-picker-panel {
  background-color: #2c2c2c; /* 将 #f0f0f0 替换为你想要的背景颜色 */
}

/* 如果你想更改日期选择器中日期的背景颜色 */
.el-date-table td.available {
  background-color: var(--background-color); /* 将 #ffffff 替换为你想要的背景颜色 */
}

.el-date-table td.current {
  background-color: #cce4ff; /* 将 #cce4ff 替换为当前日期的背景颜色 */
}

::v-deep .new_plan .el-picker-panel__body-wrapper {
  background: var(--background-color) !important;
  border: 1px solid #666666;
}

.new_plan_two {
  display: flex;
  flex-direction: row;
  width: 33.33%;
  justify-content: center;
  padding-left: 18px;
  gap: 10px;
}



.step_1_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 50px;
  gap: 10px;
}

@media (max-width: 1000px) {
  .step_1_content{
    flex-direction: column;
    align-items: center;
  }
  .new_plan_two{
    width: 50%;
    padding-left: 0px;
  }
  .plan-item{
    height: auto !important;
    padding-bottom: 10px;
  }
  .plan-list{
    width: 100% !important;
  }
  .newplan_group {
    height: auto !important;
    aspect-ratio: 1 / 1.5 !important;
    max-height: 280px;
    min-height: 220px;
  }
}


.new_plan .el-dialog__close {
  background: #666666 !important;
  color: #fff !important;
  top: -7px;
  position: absolute;
  right: -13px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.new_plan .el-dialog__headerbtn svg {
  fill: var(--text-color) !important;
}

.new_plan .el-picker-panel__body-wrapper {
  background: var(--background-color) !important;
}

.el-picker-panel__icon-btn .el-icon {
  color: var(--text-color) !important;
}

.plan_lb {
  position: relative;
}


.new_plan .el-carousel__item {
  left: 0px;
}

.new_plan .el-overlay-dialog {
  background: rgba(0, 0, 0, 0.6);
}

.new_plan .el-carousel__arrow--right {
  right: 0 !important;
}

.new_plan .el-carousel__arrow--left {
  left: 0 !important;
}

.new_plan .el-dialog {
  background: var(--background-color) !important;
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.2) !important;
  color: var(--text-color);
  max-width: 1200px;
  min-width: 600px;
}

@media (max-width: 800px) {
  .new_plan .el-dialog {
    width: 100% !important;
  }
}
</style>
<style scoped>
.plan_time {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.plan_time_left {
  width: 50%;
}

.plan_time_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1005px) {
  .plan_time {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .plan_time_left {
    width: 100%;
  }

  .plan_time_right {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.pl_intrs {
  height: 25px;
  line-height: 25px;
  margin: 0;
}

.pl_name {
  height: 25px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 93%;
  margin: 0 auto;
}

.plan-list .el-carousel.el-carousel--horizontal {
  width: 100%;
}

.plan-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66.666%;
}


.modified-label {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  height: 32px;
  width: 32px;
  display: flex;
}

.plan-item-group {
  display: flex;
  justify-content: flex-start;
  margin-top: 6px;
  gap: 10px;
  padding: 0px 18px;
}

.plan-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 25%;
  border: 1px solid #ccc;
  //aspect-ratio: 1 / 1.5;
  height: 280px;
}

.newplan_group {
  cursor: pointer;
  width: 50%;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  z-index: 10;
  height: 280px;
  aspect-ratio: auto;
}




.plan-cover {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  aspect-ratio: 1 / 1;
}

.plan-info {
  text-align: center;
  margin-top: 10px;
  width: 100%;
}


</style>

<template>
  <div class="avatar-uploader" @click="openModal">
    <div class="avatar-container" :style="{ backgroundImage: `url(${avatarUrl})` }">
      <div class="overlay" v-show="showOverlay">
        <div class="icon">
          <svg viewBox="0 0 24 24" width="44" height="44" fill="currentColor" class="x14ctfv xtzzx4i x10l6tqk xwa60dl x11lhmoz">
            <path d="M12 9.652a3.54 3.54 0 1 0 3.54 3.539A3.543 3.543 0 0 0 12 9.65zm6.59-5.187h-.52a1.107 1.107 0 0 1-1.032-.762 3.103 3.103 0 0 0-3.127-1.961H10.09a3.103 3.103 0 0 0-3.127 1.96 1.107 1.107 0 0 1-1.032.763h-.52A4.414 4.414 0 0 0 1 8.874v9.092a4.413 4.413 0 0 0 4.408 4.408h13.184A4.413 4.413 0 0 0 23 17.966V8.874a4.414 4.414 0 0 0-4.41-4.41zM12 18.73a5.54 5.54 0 1 1 5.54-5.54A5.545 5.545 0 0 1 12 18.73z"></path>
          </svg>
        </div>
      </div>
    </div>

    <!-- Modal部分 -->
    <el-dialog
        title="更换头像"
        v-model="modalVisible"
        :style="modalStyle"
        @before-close="beforeCloseModal"
        class="theme_bg avatar_modal_style"
        :append-to-body="true"
    >
      <div>
        <div class="avatar_upload_inner" v-if="!imageUrl">
          <!-- 上传按钮 -->
          <el-button type="primary" @click="triggerUpload">上传图片</el-button>
          <input
              ref="fileInput"
              type="file"
              accept="image/*"
              style="display: none"
              @change="onFileChange"
          />
        </div>
        </div>


      <!-- 裁剪图片展示 -->
      <div v-if="imageUrl" class="cropper-container">
        <img
            ref="image"
            :src="imageUrl"
            alt="裁剪图片"
            style="width: 100%; height: auto;"
        />
      </div>

      <template #footer>
        <!-- 取消按钮和保存按钮只在选择了图片后才显示 -->
        <el-button type="info" v-if="imageUrl" @click="resetUploadState">取消</el-button>
        <el-button v-if="imageUrl" type="primary" @click="saveAvatar">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { ElButton, ElDialog } from 'element-plus';
import {uploadAvatarRequest} from "@/api/api";
import {mapActions} from "pinia";
import {UserStore} from "@/stores/UserStore";

export default {
  components: {
    ElButton,
    ElDialog
  },
  props: {
    user: {
      type: Object,
      default: require,
    },

  },
  data() {
    return {
      modalVisible: false, // 控制模态框显示的状态
      //从UserStore里面读取用户信息中的头像
      avatarUrl: require('@/assets/ava_bg.png'),
      showOverlay: true,
      imageUrl: '', // 上传的图片 URL
      cropper: null, // cropperjs 实例
      modalStyle: {}, // 初始样式
      user_info:this.user,
    };
  },
  computed: {

  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions(UserStore, ['setAvatar', 'setUserInfo','getUserInfo']),
    openModal() {
      this.modalVisible = true; // 打开模态框

    },
    loadData(){
      // this.user_info=this.getUserInfo();
      // console.log(this.user_info);
      this.avatarUrl = this.user_info.avatar;
    },
    closeModal() {
      this.modalVisible = false; // 关闭模态框
    },
    beforeCloseModal(done) {
      done(); // 确保模态框关闭
    },
    triggerUpload() {
      this.$refs.fileInput.click(); // 触发文件上传
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl = reader.result;
          this.$nextTick(() => {
            this.initCropper(); // 在 DOM 更新完成后初始化 Cropper

          });
        };
        reader.readAsDataURL(file);
      }
    },
    initCropper() {
      const image = this.$refs.image;
      const cropperContainer = this.$el.querySelector('.cropper-container'); // 获取裁剪容器
      const maxHeight = cropperContainer ? cropperContainer.offsetHeight : 500; // 获取容器的最大高度，默认500px
      const maxWidth = cropperContainer ? cropperContainer.offsetWidth : 500; // 获取容器的最大宽度，默认500px

      if (image) {
        if (this.cropper) {
          this.cropper.destroy();
        }
        this.cropper = new Cropper(image, {
          aspectRatio: 1,  // 保持1:1的裁剪比例
          viewMode: 2,     // 显示裁剪框外的内容
          autoCropArea: 1, // 自动裁剪框区域
          responsive: true,
          dragMode: 'move',  // 启用拖动图片裁剪模式
          cropBoxResizable: true,  // 允许调整裁剪框大小
          cropBoxMovable: true,    // 允许移动裁剪框
          minCropBoxWidth: 100,    // 设置裁剪框的最小宽度
          minCropBoxHeight: 100,   // 设置裁剪框的最小高度
          maxCropBoxWidth: maxWidth,   // 设置裁剪框的最大宽度
          maxCropBoxHeight: maxHeight, // 设置裁剪框的最大高度
        });
      }
    },
    saveAvatar() {
      if (this.cropper) {
        const canvas = this.cropper.getCroppedCanvas();
        const croppedAvatarUrl = canvas.toDataURL();
        this.avatarUrl = croppedAvatarUrl;  // 更新头像
        //以file形式上传头像
        const file = this.dataURLtoFile(croppedAvatarUrl, 'avatar.png');
        const formData = new FormData();
        formData.append('avatar', file);

        // 上传头像
        uploadAvatarRequest(formData).then(res => {
          if (res.code === 200) {
            this.setUserInfo(res.data);
            this.user_info=res.data;
            localStorage.setItem('user_info',JSON.stringify(res.data))

            this.$message.success('头像上传成功');
          }
        });
      }
      this.resetUploadState();  // 重置上传状态

    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    resetUploadState() {
      this.imageUrl = ''; // 清空裁剪后的图片URL
      this.$nextTick(() => {
        // 确保裁剪组件被销毁
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = null;
        }
      });
      this.closeModal();  // 关闭模态框
    },

  },
};
</script>


<style>
.el-dialog.theme_bg.avatar_modal_style {
  width: 500px;
  min-height: 400px;
}
@media (max-width: 500px) {
  .el-dialog.theme_bg.avatar_modal_style {
    width: 300px;
    min-height: 300px;
  }
}
</style>
<style scoped>

.avatar_upload_inner{
  width: 100%;
  height: 100%;
  float: left;
  text-align: center;
  padding-top: 36%;
}

.avatar-uploader {
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.avatar-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ddd;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
}

.overlay:hover {
  opacity: 1;
}

.avatar-container:hover .overlay {
  opacity: 1;
}

.icon {
  font-size: 24px;
}

.tooltip {
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
}

.cropper-container {
  width: 250px;
  max-height: calc(100vh - 150px); /* 限制裁剪框容器的最大高度 */
  overflow: hidden; /* 防止溢出内容 */
  position: relative;
  margin: 20px auto;
}

.cropper-preview {
  margin-top: 20px;
}
</style>

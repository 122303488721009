<template>
  <div class="advanced-timeline">
    <!-- 中线 -->
    <div class="timeline_mid_line" ref="midLine"></div>

    <!-- 可滚动的时间轴容器 -->
    <div
        class="timeline-container-line"
        ref="scrollContainer"
    >
      <!-- 上部空间填充 -->
      <div class="spacer"></div>

      <div class="timeline">
        <!-- 遍历填充后的上传点 -->
        <div
            v-for="(upload, index) in filledUploads"
            :key="index"
            class="timeline-item"
            :class="{ filler: upload.status === 'filler', marked: upload.status !== 'filler' }"
            :style="{ height: `${upload.height}px` }"
            ref="timelineDots"
        >
          <!-- 悬浮提示 -->
          <el-tooltip :content="upload.date" placement="right">
            <div
                :class="[
                'timeline-dot',
                getStatusClass(upload.status),
                {
                  active: currentUpload && currentUpload.date === upload.date,
                  'no-content': !hasContent(upload),
                },
              ]"
            >
              <!-- 根据状态显示图标 -->
              <div v-if="upload.status === 'missed'" class="icon missed_icon">
                <svg t="1734595810369" class="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="2602">
                  <path
                      d="M720.32 768a45.76 45.76 0 0 1-33.408-14.336l-416.64-416.576c-19.072-19.072-19.072-49.28 0-66.752 19.136-19.136 49.28-19.136 66.816 0l416.64 414.976c19.072 19.072 19.072 49.28 0 66.816a44.288 44.288 0 0 1-33.408 15.872z"
                      fill="" p-id="2603"></path>
                  <path
                      d="M303.68 768a45.76 45.76 0 0 1-33.344-14.336c-19.136-19.072-19.136-49.28 0-66.752l414.976-416.64c19.072-19.072 49.28-19.072 66.816 0 19.072 19.136 19.072 49.28 0 66.816l-415.04 416.64a45.76 45.76 0 0 1-33.408 14.272z"
                      fill="" p-id="2604"></path>
                </svg>
              </div>

              <!-- 可以根据需要添加其他状态的图标 -->
            </div>
          </el-tooltip>

          <!-- 活动线条 -->
          <div class="timeline-content">
            <div
                v-if="currentUpload && currentUpload.date === upload.date"
                class="active-line"
            ></div>
          </div>
        </div>
      </div>

      <!-- 下部空间填充 -->
      <div class="spacer"></div>
    </div>

    <!-- 底部内容 -->
    <div class="timeline-footer">
      <!-- 根据需求填充 -->
    </div>
  </div>
</template>


<script>
import {ElTooltip} from "element-plus";
import {debounce} from "lodash";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
dayjs.extend(timezone)
export default {
  components: {ElTooltip},
  props: {
    // 传递给父组件的事件
    plan_info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plan: this.plan_info,
      uploads: [],
      currentUpload: null, // 当前显示的上传内容
      currentIndex: 0, // 当前选中的点的索引，基于 activeUploads
      isScrolling: false, // 标记是否正在滚动
      // 存储所有 timeline-item 的位置
      timelineItemPositions: [],
      // 是否已经到达第一个时间点
      atTop: false,
      // 是否已经到达最后一个时间点
      atBottom: false,
      timezone: 'utc',
      filteredUploads: [],
      filledUploads:[],
    };
  },
  watch: {
    postId: {
      handler() {
        this.calculateInitialIndex();
      },
      deep: true,
    },
  },
  computed: {
    // 筛选出有内容的标记点，并记录其在 filledUploads 中的索引
    activeUploads() {
      return this.filledUploads.reduce((acc, upload, index) => {
        if (
            upload.status !== "filler" && // 排除 filler 点
            this.hasContent(upload) // 仅包含有内容的点
        ) {
          acc.push({upload, filledIndex: index});
        }
        return acc;
      }, []);
    },

  },
  mounted() {
    this.timezone = dayjs.tz.guess();
    this.initNode();
    this.$nextTick(() => {
      this.calculateTimelineItemPositions();
      this.calculateInitialIndex();

    });
    window.addEventListener("resize", this.calculateInitialIndex);

    // 添加滚轮事件监听器
    this.$refs.scrollContainer.addEventListener("wheel", this.handleWheel, {passive: false});
  },
  created() {

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calculateInitialIndex);

    // 移除滚轮事件监听器
    this.$refs.scrollContainer.removeEventListener("wheel", this.handleWheel);

  },
  methods: {
    initNode() {
      if (this.plan) {
        //根据plan的start_date、update_interval、update_count计算出上传点
        let uploads = [];
        let start_date = dayjs.utc(this.plan.date_start).tz(this.timezone);

        let update_interval = this.plan.update_interval;
        let update_count = this.plan.update_count;
        for (let i = 0; i < update_count; i++) {
          let date = start_date.add(i * update_interval, 'day');
          uploads.push({
            utcDate: date,
            is_planed: true,
            date: date.format('YYYY-MM-DD HH:mm'),
            status: "planed",
            images: [],
            feedbacks: [],
          });
          console.log("计划时间："+date.format('YYYY-MM-DD HH:mm'))
        }

        //遍历plan.nodes.如果已经是上面uploads中item的时间，则标记对应点为已上传。如果不是对应的时间则往按时间顺序插入一个新节点
        this.plan.nodes.forEach(node => {
          let nodeUtcDate = dayjs.utc(node.date_actual).tz(this.timezone);
          //遍历uploads,找到最utcDate小于当前时间的最后一个节点
          let index = -1;
          for (let i = 0; i < uploads.length; i++) {
            if (uploads[i].utcDate.isBefore(nodeUtcDate)) {
              index = i;
            }
          }

          if (index > -1) {
            if (uploads[index].id) {
              //如果最接近的节点已经有id了，说明已经有对应的node了，直接添加一个新节点
              uploads.splice(index + 1, 0, {
                id: node.id,
                utcDate: nodeUtcDate,
                date: nodeUtcDate.format('YYYY-MM-DD HH:mm'),
                status: "late",
                images: ["", ""],
              });
            } else {
              //如果接近的点没有id。判断两个时间差距是否超过24小时。如果在24小时内，则把该计划节点标记为按时更新，否则则标记为超时更新，并且tip带上实际更新时间
              if (nodeUtcDate.diff(uploads[index].utcDate, 'hour') <=24) {
                uploads[index].date = uploads[index].date + "(" + nodeUtcDate.format('YYYY-MM-DD HH:mm') + ")";
                uploads[index].id = node.id;
                uploads[index].status = "uploaded";
                uploads[index].images = ["", ""];
              } else {
                uploads[index].date = uploads[index].date + "(" + nodeUtcDate.format('YYYY-MM-DD HH:mm') + ")";
                uploads[index].id = node.id;
                uploads[index].status = "late";
                uploads[index].images = ["", ""];
              }

            }

          } else {
            let i = 0;
            while (i < uploads.length - 1 && nodeUtcDate.isAfter(uploads[i].utcDate)) {
              i++;
            }
            uploads.splice(i, 0, {
              id: node.id,
              utcDate: nodeUtcDate,
              date: nodeUtcDate.format('YYYY-MM-DD HH:mm'),
              status: "late",
              images: ["", ""],
            });
          }
        })
        let today = dayjs.tz();
        console.log("今天时间："+today.format('YYYY-MM-DD HH:mm'))
        //判断今天是不是已经有对应的激活节点。如果没有，就创建一个新的空白节点
        let i = 0;
        while (i < uploads.length  && today.isAfter(uploads[i].utcDate.tz(this.timezone))) {
          console.log("节点时间："+uploads[i].utcDate.tz(this.timezone).format('YYYY-MM-DD HH:mm'))
          i++;
        }
        if (i <= uploads.length) {
          if(today.diff(uploads[i-1].utcDate.tz(this.timezone), 'hour') <=24) {
            uploads[i-1].status = "uploaded";
            uploads[i-1].utcDate = today;
            uploads[i-1].date = today.format('YYYY-MM-DD HH:mm');
            uploads[i-1].images = ["", ""];
          }else{
            uploads.splice(i, 0, {
              utcDate: today,
              date: today.format('YYYY-MM-DD HH:mm'),
              status: "uploaded",
              images: ["", ""],
            });
          }
        }else{
          uploads.push( {
            utcDate: today,
            date: today.format('YYYY-MM-DD HH:mm'),
            status: "uploaded",
            images: ["", ""],
          });
        }

        //再次遍历uploads，如果日期在今天之前的没有对应的node，则标记为missed
        uploads.forEach(item => {
          if (item.utcDate.isBefore(today) && item.status !== "uploaded" && item.status !== "late") {
            item.status = "missed";
          }
        })
        this.filteredUploads = uploads;

        //第二步处理上传点的状态
        const filled = [];
        for (let i = 0; i < uploads.length; i++) {
          const currentUpload = uploads[i];
          filled.push({...currentUpload, height: 20}); // 标记点高度固定20px

          if (i < uploads.length - 1) {
            const nextUpload = uploads[i + 1];
            //计算两个节点的相差天数

            const daysBetween = nextUpload.utcDate.startOf('day').diff(currentUpload.utcDate.startOf('day'), 'day');
            const numberOfFillers = daysBetween - 1; // 未标记点数量
            if (numberOfFillers > 0) {
              const fillerHeight = 80 / numberOfFillers;
              for (let j = 1; j <= numberOfFillers; j++) {
                let fillDate = currentUpload.utcDate.startOf('day').add(j, 'day');
                filled.push({
                  utcDate: fillDate,
                  date: fillDate.format('YYYY-MM-DD HH:mm'),
                  status: "filler",
                  images: [],
                  feedbacks: [],
                  height: fillerHeight, // 动态计算填充点高度
                });
              }
            } else {
              const fillerHeight = 80;
              filled.push({
                height: fillerHeight, // 动态计算填充点高度
              });
            }
          }
        }
        this.filledUploads= filled;
      }

    },
    // 计算所有 timeline-item 的位置
    calculateTimelineItemPositions() {
      this.$nextTick(() => {
        this.timelineItemPositions = [];
        const items = this.$refs.timelineDots; // 使用 ref="timelineDots" 获取所有 timeline-item

        if (items) {
          // Vue 3 中，多个 ref 会作为数组
          if (Array.isArray(items)) {
            items.forEach((item) => {
              if (item && item.classList.contains("marked")) {
                // 存储每个标记点的中心位置
                this.timelineItemPositions.push(item.offsetTop + item.offsetHeight / 2);
              }
            });
          } else {
            // 单个 ref 的情况
            if (items.classList.contains("marked")) {
              this.timelineItemPositions.push(items.offsetTop + items.offsetHeight / 2);
            }
          }
        }
      });
    },
    // 新增：滚轮事件处理函数
    handleWheel(event) {
      event.preventDefault(); // 阻止默认滚动行为
      const deltaY = event.deltaY;

      if (deltaY > 0) {
        // 向下滚动，移动1个标记节点
        this.moveIndex(1);
      } else if (deltaY < 0) {
        // 向上滚动，移动1个标记节点
        this.moveIndex(-1);
      }
    },
    // 新增：根据步数移动索引
    moveIndex(step) {
      // 找到当前选中的 activeUploads 索引
      const currentActiveIndex = this.activeUploads.findIndex(
          (obj) => obj.filledIndex === this.currentIndex
      );

      if (currentActiveIndex === -1) return; // 如果当前索引无效，则不执行

      let newActiveIndex = currentActiveIndex + step;

      // 确保新索引在有效范围内
      newActiveIndex = Math.max(0, Math.min(newActiveIndex, this.activeUploads.length - 1));

      if (newActiveIndex !== currentActiveIndex) {
        const newUploadObj = this.activeUploads[newActiveIndex];
        this.currentIndex = newUploadObj.filledIndex;
        this.currentUpload = newUploadObj.upload;
        this.scrollToIndex(newUploadObj.filledIndex);
      }
    },
    // 监听滚动事件，使用 debounce 防止频繁触发
    handleScroll: debounce(function () {
      const container = this.$refs.scrollContainer; // 获取容器引用
      const containerRect = container.getBoundingClientRect();
      const containerMidY = containerRect.top + containerRect.height / 2;

      let closestIndex = -1;
      let minDistance = Infinity;

      this.activeUploads.forEach((uploadObj) => {
        const dotElement = this.$refs.timelineDots[uploadObj.filledIndex];
        if (dotElement) {
          const dotRect = dotElement.getBoundingClientRect();
          const dotMidY = dotRect.top + dotRect.height / 2;
          const distance = Math.abs(dotMidY - containerMidY);
          if (distance < minDistance) {
            minDistance = distance;
            closestIndex = uploadObj.filledIndex;
          }
        }
      });

      if (closestIndex !== -1 && closestIndex !== this.currentIndex) {
        this.currentIndex = closestIndex;
        this.currentUpload = this.activeUploads.find(
            (obj) => obj.filledIndex === closestIndex
        ).upload;

        // 滚动到选中的时间点
        this.scrollToIndex(closestIndex);
      }
    }, 100), // debounce 延迟 100ms

    // 加载并显示上传内容
    loadContent(upload) {
      if (this.hasContent(upload)) {
        this.currentUpload = upload; // 设置当前上传内容
      }
    },
    // 根据状态返回对应的 CSS 类
    getStatusClass(status) {
      if (status === "planed") return "planed";
      if (status === "uploaded") return "uploaded";
      if (status === "late") return "late";
      if (status === "finish") return "finish";
      if (status === "missed") return "missed";
      if (status === "filler_hour") return "filler_hour";
      return status === "filler" ? "filler" : "";
    },
    // 判断上传项是否包含内容
    hasContent(upload) {
      return upload.images && upload.images.length > 0; // 判断上传项是否包含内容
    },

    // 滚动到指定索引的上传点
    scrollToIndex(dotIndex) {
      const dotElement = this.$refs.timelineDots[dotIndex];
      const container = this.$refs.scrollContainer;

      if (dotElement && container) {
        const containerHeight = container.clientHeight;
        const dotRect = dotElement.getBoundingClientRect();

        // 计算目标点的相对位置
        const scrollDistance =
            dotElement.offsetTop - containerHeight / 2 + dotRect.height / 2;

        // 执行滚动
        container.scrollTo({
          top: scrollDistance,
          behavior: "smooth",
        });

        // 更新当前展示的上传内容
        const activeUploadObj = this.activeUploads.find(
            (obj) => obj.filledIndex === dotIndex
        );
        if (activeUploadObj) {
          this.currentUpload = activeUploadObj.upload;
        }
      }
    },

    // 计算并初始化当前选中的上传点索引
    calculateInitialIndex() {
      // 找到初始时与中线最近的 activeUpload 点
      this.$nextTick(() => {
        let closestIndex = -1;
        //没有传递当前节点id的情况下。默认选中今天的时间
        const today = dayjs.tz().startOf('day');
        this.activeUploads.forEach((uploadObj) => {
          if (today.isBefore(uploadObj.upload.utcDate)||today.isSame(uploadObj.upload.utcDate,'day')) {
            closestIndex = uploadObj.filledIndex;
            this.currentUpload = uploadObj.upload;
            this.scrollToIndex(closestIndex);
            return;
          }
        });

      });
    },
  },
};
</script>

<style scoped>
.advanced-timeline {
  height: 40vh;
  position: relative;
}

.timeline_mid_line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #505050;
  z-index: 10;
  transform: translateY(-50%);
}

.timeline-container-line {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  overscroll-behavior: contain; /* 防止滚动超出内容边界 */
  scroll-snap-type: y mandatory; /* 启用滚动捕捉 */
}

.spacer {
  width: 40px;
  height: 50vh; /* 上下各一个 spacer，确保滚动能将标记点对齐到中线 */
}

.timeline {
  margin-left: 0px;
  width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* 改为 flex-start 以便填充点按顺序排列 */
}

.timeline-item {
  display: flex;
  align-items: center;
  justify-content: center; /* 确保时间点在容器中居中 */
  /* 高度由内联样式动态设置 */
  scroll-snap-align: center; /* 启用滚动捕捉对齐 */
}

.timeline-item.marked {
  /* 不设置高度，由动态样式控制 */
}

.timeline-item.filler {
  /* 填充点的高度由 inline style 决定 */
}
.timeline-dot.filler_hour {
  background-color: #ffffff; /* Grey */
  width: 2px !important;
  height: 2px !important;
}

.timeline-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  transition: background-color 0.3s, border 0.3s;
  cursor: pointer;
}

.timeline-dot.no-content {
  opacity: 0.5;
  cursor: not-allowed;
  width: 5px;
  height: 5px;
}

.timeline-dot.uploaded {
  width: 14px;
  height: 14px;
  background-color: rgb(25, 222, 166);
  border: 3px solid rgb(3 84 60);
}

.timeline-dot.finish {
  width: 14px;
  height: 14px;
  background-color: rgb(255, 214, 53);
  border: 3px solid rgb(63, 49, 2);
}

.timeline-dot.late {
  width: 14px;
  height: 14px;
  background-color: rgb(25, 222, 166);
  border: 3px solid rgb(3 84 60);
}

.timeline-dot.planed {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 3px solid rgb(176, 176, 176);
}

.timeline-dot.missed {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid rgb(176, 176, 176);
}

.icon.missed_icon svg {
  width: 26px;
  height: 26px;
  fill: rgb(194 0 0);
  position: absolute;
  left: -9px;
  top: -4px;
}

.icon.missed_icon {
  position: relative;
  width: 19px;
  height: 19px;
}

.timeline-dot.filler {
  background-color: #ffffff; /* Grey */
}

.timeline-dot.active {

}

.timeline-content {
  display: flex;
  flex-direction: column;
}

.active-line {
  width: 100%; /* 横线的宽度 */
  height: 2px; /* 横线的高度 */
  background-color: #4CAF50; /* 横线的颜色 */
  margin-top: 5px; /* 横线与时间戳之间的间距 */
}

/* 防止拖动弹跳效果 */
.timeline-container-line {
  -webkit-overflow-scrolling: touch;
}
</style>

<template>
  <div v-loading="homeloading" class="common-layout theme_bg">
<!--    <TopMenu/>-->
    <el-container>
      <LeftSidebar/>
      <el-container>
        <el-header class="index_header">
          <ProfileCarousel/>
        </el-header>
        <el-main class="index_main">
<!--          <RecommendUser/>-->

          <InsPost @dataLoaded="onDataLoaded"/>
          <ResponsiveFooter/>
        </el-main>
      </el-container>

      <el-aside class="right_aside">
        <div class="link_user" style="cursor: pointer;" @click="go_user_center">
          <div class="header_item">
            <div class="header_item_img">
              <img :src="userStore.user_info.avatar" v-if="userStore.user_info.avatar" alt="">
              <svg v-else t="1731673675159" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5345" width="39" height="39"><path d="M1023.878734 511.939367A511.939367 511.939367 0 1 1 511.939367 0a511.939367 511.939367 0 0 1 511.939367 511.939367z" fill="#D9D9D9" p-id="5346"></path><path d="M511.939367 255.969684a191.977263 191.977263 0 1 0 191.977263 191.977262 191.977263 191.977263 0 0 0-191.977263-191.977262z m0 479.943156c-108.787116 0-305.243848 43.834808-365.716685 134.384084a511.939367 511.939367 0 0 0 731.433371 0C817.183215 779.747649 620.726483 735.91284 511.939367 735.91284z" fill="#8C8C8C" p-id="5347"></path></svg>
            </div>
            <div class="user_name">
              <div class="user_name_1" v-if="this.user_info">{{user_info.name}}</div>
              <div class="user_name_1" v-else>登录</div>
            </div>
          </div>
        </div>
        <FollowList/>

      </el-aside>
    </el-container>
    <!--    底部菜单组件-->
<!--    <BottomMenu/>-->
  </div>
</template>
<script>
import ProfileCarousel from '../components/ProfileCarousel.vue';
import FollowList from '../components/FollowList.vue';
import InsPost from '../components/InsPost.vue';
import ResponsiveFooter from '../components/ResponsiveFooter.vue';
// import BottomMenu from "@/components/BottomMenu.vue";
// import TopMenu from "@/components/TopMenu.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";
import { UserStore } from '@/stores/UserStore';
export default {
  name: 'HomePage',
  components: {
    LeftSidebar,
    // TopMenu,
    // BottomMenu,
    ProfileCarousel,
    FollowList,
    InsPost,
    ResponsiveFooter,
  },
  data() {
    return {
      homeloading: true, // 控制页面的加载动画
      planModalVisible: false,
      token: localStorage.getItem('token'),
      user_info: localStorage.getItem("user_info")?JSON.parse(localStorage.getItem('user_info')):{},
    };
  },
  mounted() {
  },
  setup() {
    const userStore = UserStore();
    return { userStore };
  },
  methods:{
    // 子组件通知父组件数据加载完成
    onDataLoaded() {
      this.homeloading = false; // 隐藏加载动画
    },
    go_user_center(){
      if(this.token){
        this.$router.push('/UserProfile');
      }else{
        this.$router.push('/LoginPage');
      }
    },
  }
};
</script>

<style>
@media (max-width: 768px) {
  #app {
    margin-top: 50px;
  }
}

.link_user {
  text-decoration-line: none !important;
  color: #000;
  width: 100%;
}

.header_item {
  width: 320px;width: 100%;
  margin-top: 22px;
  overflow: hidden;
  display: flex;
  font-size: 13px;
  padding-right: 20px;
  padding-left: 10px;
  align-items: center;
}

.user_name {
  color: var(--text-color);
}

.header_item_img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: var(--header-border);
  margin-right: 10px;
  box-shadow: var(--el-drawer-box-shadow);
}

.header_item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}



.right_aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 410px !important;
}

@media (max-width: 1333px) {
  .right_aside {
    width: 350px !important;
  }
}

@media (max-width: 1160px) {
  .right_aside {
    display: none;
  }


}



@media (max-width: 540px) {
  .index_main {
    padding: 0px 0px !important;
  }
}


.index_header {
  height: 100px !important;
  display: flex;
  justify-content: center;
}


.index_main {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
</style>
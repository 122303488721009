<template>
  <div class="new_message theme_bg">
    <el-dialog
        v-model="newMsgVisible"
        title="新消息"
        width="550px"
        @close="closeModal"
    >
      <!-- 搜索输入框 -->
      <div class="search-bar">
        <span class="label">发给：</span>
        <div class="search-input-container">
          <!-- 已选择用户的标签 -->
          <el-tag
              v-for="(user, index) in selectedUsers"
              :key="user.id"
              closable
              @close="removeUser(index)"
              class="user-tag"
          >
            {{ user.name }}
          </el-tag>

          <!-- 实时搜索输入框 -->
          <el-input
              v-model="searchQuery"
              placeholder="搜索..."
              @input="handleSearch"
              v-if="!maxUsersReached"
              class="search-input"
          />
        </div>
      </div>

      <!-- 搜索结果列表 -->
      <div class="user-list">
        <div
            v-for="user in filteredUsers"
            :key="user.id"
            :class="['user-item', { selected: isUserSelected(user) }]"
            @click="newMsgTo(user)"
        >
          <el-avatar :src="user.avatar?user.avatar:ava_bg" size="small"></el-avatar>
          <span class="user-name">{{ user.name }}</span>
        </div>
      </div>
      <div v-if="filteredUsers.length === 0" class="no-user">找不到账户。</div>

      <!-- 底部操作按钮 -->
<!--      <template #footer>-->
<!--        <el-button-->
<!--            :disabled="selectedUsers.length === 0"-->
<!--            type="primary"-->
<!--            @click="createChat"-->
<!--            class="send-button"-->
<!--        >-->
<!--          聊天-->
<!--        </el-button>-->
<!--      </template>-->
    </el-dialog>
  </div>
</template>

<script>
import {friendsRequest} from "@/api/api";
import { modalStore } from "@/stores/modalStore";
import { mapActions, mapState } from 'pinia';
import ava_bg from '@/assets/ava_bg.png';
export default {
  name: "NewMessageModal",
  data() {
    return {
      searchQuery: "", // 搜索关键词
      selectedUsers: [], // 已选择的用户列表
      users: [],
      ava_bg:ava_bg,
    };
  },
  emits: ['start-chat'],
  watch: {

  },
  computed: {
      ...mapState(modalStore, ['isShowNewMsg']),
    newMsgVisible() {
        return this.isShowNewMsg;
      },
    filteredUsers() {
      // 根据搜索关键词过滤用户列表
      return this.users.filter((user) =>
          user.name.includes(this.searchQuery)
      );
    },
    maxUsersReached() {
      // 限制最多只能选择一个用户
      return this.selectedUsers.length >= 1;
    },
  },
  mounted() {
    this.getFriends()
  },
  methods: {
    ...mapActions(modalStore, ['hideNewMsgModal']),
    closeModal() {
      this.hideNewMsgModal();
    },

    newMsgTo(user){
      // this.dialogVisible = false; // 关闭模态框
      this.$emit('start-chat', user);
      this.closeModal();
    },
    getFriends(){
      friendsRequest().then(res=>{
        if(res.code===200){
          this.users=res.data;
        }
      })
    },
    // 处理搜索
    handleSearch() {
      // 此处模拟搜索，可以替换为实际的API请求逻辑
    },
    // 切换用户选择状态
    toggleUserSelection(user) {
      const index = this.selectedUsers.findIndex((selected) => selected.id === user.id);
      if (index !== -1) {
        // 如果用户已经在已选列表中，则移除
        this.selectedUsers.splice(index, 1);
      } else {
        // 如果用户不在已选列表中，则添加
        if (!this.maxUsersReached) {
          this.selectedUsers.push(user);
        }
      }
    },
    // 检查用户是否被选中
    isUserSelected(user) {
      return this.selectedUsers.some((selected) => selected.id === user.id);
    },
    // 移除已选择的用户
    removeUser(index) {
      this.selectedUsers.splice(index, 1);
    },
    // 处理创建聊天的操作
    createChat() {
      // 处理点击“聊天”按钮后的逻辑
      this.dialogVisible = false; // 关闭模态框
    },
    // 打开模态框
    openModal() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style>
.search-input-container .el-tag__content {
  background: rgb(224, 241, 255);
  color: rgb(0, 149, 246);
  font-size: 14px;
  font-weight: 600;
}
.new_message .el-dialog__close {
  background: #666666 !important;
  color: #fff !important;
  top: -7px;
  position: absolute;
  right: -13px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.new_message .el-overlay-dialog {
  background: rgba(0, 0, 0, 0.6);
}

.new_message .el-dialog {
  max-width: 100%;
  background: var(--background-color) !important;
}

.new_message .el-dialog {
  background-color: var(--background-color) !important;
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.2) !important;
  color: var(--text-color);
}
</style>
<style scoped>
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.search-input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.search-input {
  flex: 1;
  min-width: 150px;
}

.user-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}

.user-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px 0;
}

.user-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 60px;
  padding: 8px 16px;
  transition: background-color 0.3s;
}
.user-item .el-avatar.el-avatar--small.el-avatar--circle {
  width: 44px;
  height: 44px;
}

.user-item:hover {
  background-color: var(--new-message-item-hover);
}

.user-item.selected {
  background-color: var(--new-message-item-hover); /* 选中状态的背景颜色 */
}

.search-bar span.label {
  width: 50px;
}
.user-name {
  margin-left: 10px;
}

.no-user {
  color: #888;
  text-align: center;
  padding: 10px 0;
}

.send-button {
  width: 100%;
  height: 44px;
  background: #0095f6;
  border: none;
}
.send-button:disabled {
  width: 100%;
  height: 44px;
  background: rgb(27,72,100);
  border: none;
}
</style>

<template>
  <div class="login-container theme_bg">
    <div class="login-box">
      <div class="logo">
        <svg width="130" class="ml_10" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 1499.81 1499.81">
          <defs></defs>
          <path class="cls-1" d="M350.96,532.43h134.98v59.99h-75.74v150.73h-59.24v-210.72Z"/>
          <path class="cls-1"
                d="M1163.27,773.15h75.74c7.5,7.75,23.17,23.96,23.17,23.96,0,0-33.67,33.78-50.17,50.28h24c5.75,6,11.5,12,17.25,18v15.75h-75.74c-7.75-8.25-15.5-16.5-23.25-24.75,16.5-16.25,50.27-49.52,50.27-49.52,0,0-17.78.03-26.27.03-5-5.25-10-10.5-15-15.75v-18Z"/>
          <path class="cls-1"
                d="M1145.11,908.14h-33.75v-51.74c-12.25-12.25-24.5-24.5-36.75-36.75l24-24c9.75,9.5,19.5,19,29.25,28.5,9.75-9.5,19.5-19,29.25-28.5,8,7.75,16,15.5,24,23.25l-36,36v53.24Z"/>
          <path class="cls-1"
                d="M980.13,796.4c8,7.75,16,15.5,24,23.25-7.75,8-15.5,16-23.25,24-8-7.75-16-15.5-24-23.25,7.75-8,15.5-16,23.25-24Z"/>
          <path class="cls-1"
                d="M501.69,532.43h133.48v59.99h-74.24v15h74.24v59.99h-74.24v15.75h74.24v59.99h-133.48v-210.72Z"/>
          <path class="cls-1"
                d="M785.9,532.43v210.72h-59.24c-.25-19.75-.5-39.5-.75-59.24-9,21.75-18,43.5-27,65.24-18.25-7.5-36.5-15-54.74-22.5,27.49-64.74,55-129.49,82.49-194.23h59.24Z"/>
          <path class="cls-1" d="M801.65,532.43h59.24v210.72h-59.24v-210.72Z"/>
          <path class="cls-1" d="M876.64,683.16h133.48v59.99h-133.48v-59.99Z"/>
          <path class="cls-1" d="M277.47,757.41h133.48v59.99h-133.48v-59.99Z"/>
          <path class="cls-1" d="M425.95,757.41h59.24v59.99h-59.24v-59.99Z"/>
          <path class="cls-1"
                d="M800.9,757.41h134.98v210.72h-134.98v-59.99h75.74v-14.25h-75.74v-59.99h75.74v-16.5h-75.74v-59.99Z"/>
          <path class="cls-1"
                d="M575.18,758.16h210.72v210.72h-59.24v-151.48h-15.75v151.48h-59.24v-151.48h-17.25v151.48h-59.24v-210.72Z"/>
          <path class="cls-1"
                d="M1062.38,820.05c10,9.75,31.74,30.34,31.74,30.34l-23.58,24.27s-20.67-21.03-30.42-31.02c-10.25,10-20.5,20-30.75,30-7.75-7.75-15.5-15.5-23.25-23.25,10-10,20-20,30-30-9.75-9.75-19.5-19.5-29.25-29.25v-2.25c7.5-7.5,15-15,22.5-22.5,10,10,20,20,30,30,10-10,20-20,30-30,8,7.75,16,15.5,24,23.25l-30.99,30.4Z"/>
          <path class="cls-1" d="M500.94,833.9h59.99v133.48h-59.99v-133.48Z"/>
          <path class="cls-1" d="M350.21,834.65h59.99v133.48h-59.99v-133.48Z"/>
        </svg>
      </div>
      <p class="message">请先验证您的邮箱,如未收到邮件请尝试重新发送验证码</p>
      <el-form :model="form" ref="formRef" class="verification-form">

        <el-form-item prop="code" :rules="codeRules">

              <el-input class="get-code-input" v-model="form.code" placeholder="请输入验证码" />

              <el-button
                  :disabled="isCounting"
                  @click="handleGetCode"
                  class="get-code-btn"
                  type="info"
              >
                {{ isCounting ? countdown + 's' : '再次发送' }}
              </el-button>

        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit" :loading="loading" class="submit-btn">
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="download-app">
      <ResponsiveFooter/>
    </div>
  </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage} from 'element-plus';
import ResponsiveFooter from "@/components/ResponsiveFooter.vue";
import {reSendEmailRequest, verifyCodeRequest} from "@/api/api";


export default {
  name: 'EmailVerification',
  components: {
    ResponsiveFooter,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,

  },
  data() {
    return {
      form: {
        email: '',
        code: '',
      },
      loading: false,
      countdown: 0,
      isCounting: false,

      codeRules: [
        {required: true, message: '验证码不能为空', trigger: 'blur'},
        {len: 6, message: '验证码应该是6位', trigger: 'blur'},
      ],
    };
  },
  methods: {
    // 获取验证码
    async handleGetCode() {

      this.isCounting = true;
      this.countdown = 60;

      // 调用后端接口发送验证码
      try {
        reSendEmailRequest().then(response => {
          if (response.code === 200) {
            ElMessage.success('验证码已发送到您的邮箱,请注意查收');
          } else {
            ElMessage.error(response.message || '验证码发送失败');
            this.isCounting = false; // 失败时停止倒计时
          }
        }).catch(error => {
          console.error('发送验证码失败:', error);
          ElMessage.error('发送验证码失败，请稍后再试');
          this.isCounting = false; // 请求失败时停止倒计时
        });

      } catch (error) {
        console.error('发送验证码失败:', error);
        ElMessage.error('发送验证码失败，请稍后再试');
        this.isCounting = false; // 请求失败时停止倒计时
      }

      // 开始倒计时
      const interval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(interval);
          this.isCounting = false;
        } else {
          this.countdown--;
        }
      }, 1000);
    },

    // 提交验证码
    async submit() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          // 调用后端接口验证验证码
          try {
            verifyCodeRequest(this.form).then(response => {
              if (response.code === 200) {
                ElMessage.success('邮箱验证成功');
                // 验证成功后跳转到首页或其他页面
                this.$router.push({name: 'HomePage'});
              } else {
                ElMessage.error(response.message || '验证码错误');
              }
            }).catch(error => {
              console.error('验证验证码失败:', error);
              ElMessage.error('验证验证码失败，请稍后再试');
            }).finally(() => {
              this.loading = false;
            });
          } catch (error) {
            console.error('验证验证码失败:', error);
            ElMessage.error('验证验证码失败，请稍后再试');
          } finally {
            this.loading = false;
          }
        } else {
          ElMessage.error('请输入有效的信息');
        }
      });
    },
  },
};
</script>

<style scoped>
.get-code-btn {
  width: 86px;
  font-size: 13px;
}
.get-code-btn:hover {
  background: #525252;
  font-size: 13px;
}
.get-code-input{
  width: calc(100% - 86px);
}
.message {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 20px;
}

.verification-form {
  width: 100%;
}

.download-app {
  margin-top: 20px;
  text-align: center;
  position: fixed;
  bottom: 50px;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.login-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: var(--background-color);
  text-align: center;
}

.logo {
  font-family: 'Lobster', cursive;
  font-size: 36px;
  margin-bottom: 20px;
}
.logo svg{
  fill: var(--text-color) !important;
}

.login-form {
  width: 100%;
}

.submit-btn {
  width: 100%;
  background-color: #3897f0;
  color: #fff;
  margin-top: 10px;
}

.forgot-password-link {
  margin-top: 15px;
  color: #00376b;
  cursor: pointer;
}

.signup-link {
  margin-top: 20px;
}


.app-links img {
  width: 120px;
  margin: 10px;
}

.el-input.active::placeholder {
  transform: translateY(-20px);
  font-size: 12px;
  color: #5e5e5e;
}
</style>

// src/stores/messageStore.js
import { defineStore } from 'pinia';

export const StatusStore = defineStore('StatusStore', {
    state: () => ({
        UserIsCollect: false,//是否在收藏夹
        need_get_list:false,//是否需要重新获取列表
        need_get_detail:false,//是否需要重新获取详情
    }),
    actions: {
        setNeedGetList(need_get_list){
            this.need_get_list=need_get_list;
        },
        setNeedGetDetail(need_get_detail){
            this.need_get_detail=need_get_detail;
        },
        setUserIsCollect(UserIsCollect){
            this.UserIsCollect=UserIsCollect;
        },
        getUserIsCollect(){
            return this.UserIsCollect;
        },


    },
});

<template>
  <div class="advanced-timeline">
    <div class="timeline-container">
      <div class="timeline" id="picker">
        <PlanTimeline  v-if="postDetail&&postDetail.plan&&postDetail.plan.is_independent===0" :post_id="postDetail.id" :plan_info="postDetail.plan" />
      </div>

      <div class="content-display">
        <div class="art_img_content" v-if="postDetail">
          <div class="art_all_left">
            <div class="pre_item_btn"  @click="prevPlan" v-if="postDetail.prev_plan">
              <svg t="1731034015207" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="14646" width="48" height="48">
                <path
                    d="M620.74 260.842l36.027 36.027-216.303 216.301 216.303 216.301-36.027 36.026-252.329-252.329 252.329-252.329z"
                    p-id="14647" fill="#ffffff"></path>
              </svg>
            </div>
            <div class="next_item_btn"  @click="nextPlan" v-if="postDetail.next_plan">
              <svg t="1731034112258" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="15175" id="mx_n_1731034112259" width="48" height="48">
                <path
                    d="M403.26 763.158l-36.027-36.027 216.303-216.301-216.303-216.301 36.027-36.026 252.329 252.329-252.329 252.329z"
                    p-id="15176" fill="#ffffff"></path>
              </svg>
            </div>
            <button class="pre_btn_style" @click="prevNode" v-if="postDetail.prev_node"
                    :style="{ opacity: preBtnOpacity }">
              <svg t="1730881073372" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="4904" width="32" height="32">
                <path
                    d="M865 733.6l-332.8-270c-11-8.9-26.7-8.9-37.7 0L167.2 728.1c-12.9 10.4-14.9 29.3-4.5 42.2 10.4 12.9 29.3 14.9 42.2 4.5l308.3-249.2 313.9 254.7c5.6 4.5 12.2 6.7 18.9 6.7 8.7 0 17.4-3.8 23.3-11.1 10.5-12.9 8.5-31.8-4.3-42.3z"
                    fill="#409eff" p-id="4905"></path>
                <path
                    d="M204.9 504.8l308.3-249.2 313.9 254.7c5.6 4.5 12.2 6.7 18.9 6.7 8.7 0 17.4-3.8 23.3-11.1 10.4-12.9 8.5-31.8-4.4-42.2l-332.8-270c-11-8.9-26.7-8.9-37.7 0L167.2 458.1c-12.9 10.4-14.9 29.3-4.5 42.2 10.5 12.9 29.3 14.8 42.2 4.5z"
                    fill="#409eff" p-id="4906"></path>
              </svg>
            </button>

            <div class="timeline_img_content" ref="timelineImgContent" @wheel="handleWheel">
              <template v-for="(media, idx) in postDetail.medias">
                <el-image v-if="!isVideo(media.media_url)" class="art_img_item"
                          :key="'image-' + idx"
                          :src="'http://bbs-oss.realtime.xyz/'+media.media_url"
                          style="max-width: 100%; height: auto;max-height: 90vh;min-width: 50%;"
                ></el-image>
                <video v-else class="art_img_item" controls
                       :key="'video-' + idx"
                       :src="'http://bbs-oss.realtime.xyz/'+media.media_url"
                       style="width: 100%; height: auto;"
                       loop="loop"
                       playsinline
                       controlslist="nodownload noplaybackrate" @contextmenu.prevent disablePictureInPicture
                ></video>
              </template>
            </div>

            <button class="next_btn_style" @click="nextNode" v-if="postDetail.next_node"
                    :style="{ opacity: nextBtnOpacity }">
              <svg t="1730881146329" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="6286" id="mx_n_1730881146330" width="32" height="32">
                <path
                    d="M517 543.9c-7.1 0-14.2-2.4-20.1-7.1L143 249.7c-13.7-11.1-15.8-31.2-4.7-44.9 11.1-13.7 31.2-15.8 44.9-4.7L517 471l327.9-265c13.7-11.1 33.8-9 44.9 4.8 11.1 13.7 8.9 33.8-4.8 44.9L537 536.8c-5.8 4.7-12.9 7.1-20 7.1z"
                    fill="#409eff" p-id="6287"></path>
                <path
                    d="M517 831c-7.1 0-14.2-2.4-20.1-7.1L143 536.8c-13.7-11.1-15.8-31.2-4.7-44.9s31.2-15.7 44.9-4.7L517 758.1l327.9-265c13.7-11 33.8-9 44.9 4.8 11.1 13.7 8.9 33.8-4.8 44.9L537 823.9c-5.8 4.7-12.9 7.1-20 7.1z"
                    fill="#409eff" p-id="6288"></path>
              </svg>
            </button>
          </div>
          <div class="art_all_right">
            <div class="close_timeline" @click="goBack()">
              <svg t="1731028520876" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="9753" width="32" height="32">
                <path
                    d="M940.8 83.2c25.6 25.6 25.6 64 0 83.2L595.2 512l345.6 345.6c25.6 25.6 25.6 64 0 83.2-25.6 25.6-64 25.6-83.2 0L512 595.2l-345.6 345.6c-25.6 25.6-64 25.6-83.2 0-25.6-25.6-25.6-64 0-83.2L428.8 512 83.2 166.4c-25.6-25.6-25.6-64 0-83.2s64-25.6 83.2 0L512 428.8l345.6-345.6C876.8 57.6 921.6 57.6 940.8 83.2z"
                    fill="" p-id="9754"></path>
              </svg>
            </div>
            <InstagramComment :postId="post_id"/>
          </div>
        </div>
        <div v-else>
          <p>正在加载中...</p>
        </div>

      </div>


    </div>

  </div>
</template>

<script>
import {ElMessage} from 'element-plus';
import InstagramComment from "@/components/InstagramComment.vue";
import {useRouter} from 'vue-router';
import {PostDetailStore} from "@/stores/PostDetailStore";
import {mapActions, mapState} from 'pinia';
import PlanTimeline from "@/components/PlanTimeline.vue";
import {StatusStore} from "@/stores/StatusStore";
export default {
  setup() {
    const router = useRouter();

    function goBack() {
      router.go(-1);
    }
    return {goBack};
  },
  components: {
    PlanTimeline,
    InstagramComment,
  },
  data() {
    return {
      preBtnOpacity: 0.5,
      nextBtnOpacity: 0.5,
      dimension: '1', // 默认维度为作品
      post_id: this.$route.params.id,

    };
  },
  watch: {
    $route(to) {
      if(this.post_id!=to.params.id){
        // this.clearData();
        this.post_id = to.params.id;
        this.getData();
      }
    },
    need_get_detail_data(val) {
      if (val) {
        this.getData();
      }
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState(PostDetailStore, ['post_info']),
    ...mapState(StatusStore,["need_get_detail"]),
    postDetail() {
      return this.post_info;
    },
    need_get_detail_data() {
      return this.need_get_detail;
    },
  },
  methods: {
    ...mapActions(PostDetailStore, ['clearPostInfo','getDetailInfo','setPostId']),
    clearData(){
      this.clearPostInfo();
    },
    isVideo(url) {
      const videoExtensions = ['mp4', 'webm', 'ogg'];
      const extension = url.split('.').pop().toLowerCase();
      return videoExtensions.includes(extension);
    },
    getData() {
      this.setPostId(this.post_id);
      this.getDetailInfo();
    },

    handleWheel(event) {
      const container = this.$refs.timelineImgContent;
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      const deltaY = event.deltaY;

      // 当滚动到顶部并继续向上滚动
      if (scrollTop === 0 && deltaY < 0) {
        this.preBtnOpacity = 1;
      } else {
        this.preBtnOpacity = 0.2;
      }

      // 当滚动到底部并继续向下滚动
      if (clientHeight <= scrollHeight && deltaY > 0) {
        this.nextBtnOpacity = 1;
      } else {
        this.nextBtnOpacity = 0.2;
      }
    },

    //上一个计划
    prevPlan() {
      if(this.postDetail.prev_plan){
        let prevNodes = this.postDetail.prev_plan.nodes;
        this.$router.replace({name: 'TimeLine', params: {id: prevNodes[prevNodes.length-1].id}});
      }else{
        ElMessage({
          message: '暂无计划可以切换',
          type: 'error',
        });
      }

    },
    //上一个计划
    nextPlan() {
      if(this.postDetail.next_plan){
        let nextNodes = this.postDetail.next_plan.nodes;
        this.$router.replace({name: 'TimeLine', params: {id: nextNodes[nextNodes.length-1].id}});
      }else{
        ElMessage({
          message: '暂无计划可以切换',
          type: 'error',
        });
      }
    },
    //上一个节点
    prevNode() {
      if(this.preBtnOpacity === 1){
        //透明度变成100%才算可以点击
        this.$router.replace({name: 'TimeLine', params: {id: this.postDetail.prev_node.id}});

      }
    },
    nextNode() {
      if(this.nextBtnOpacity === 1){
        //透明度变成100%才算可以点击
        this.$router.replace({name: 'TimeLine', params: {id: this.postDetail.next_node.id}});
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .art_all_left {
    width: calc(100% - 40px) !important;
    height: auto !important;
  }

  .art_all_right {
    width: 100% !important;
    height: auto !important;
  }

  .art_img_content {
    width: 100% !important;
    height: auto !important;
    display: flex;
    justify-content: center !important;
    flex-direction: column !important;
  }

  .next_btn_style {
    bottom: 55px !important;
  }

  .pre_btn_style {
    top: 50px !important;
  }
}

.missed_icon {
  width: 15px;
  height: 15px;
}

.art_img_item {
  margin-bottom: 15px;
  overflow: visible !important;
}

.art_all_left {
  width: calc(100% - 450px);
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
}

.timeline_img_content {
  width: 100%;
  //height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-top: 15px;
}
.timeline_img_content::-webkit-scrollbar {
  display: none;
}
.timeline_img_content{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.pre_item_btn {
  position: absolute;
  left: -15px;
  top: calc(50vh - 30px);
  z-index: 10;
  cursor: pointer;
}

.pre_item_btn svg {
}

.next_item_btn svg {
}

.next_item_btn {
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: calc(50vh - 30px);
  z-index: 10;
}

.art_all_right {
  width: 450px;
  position: relative;
}

.close_timeline {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
}

@media (max-width: 768px) {
  .close_timeline {
    position: fixed;
    right: 5px;
    top: 27px;
  }
}

.close_timeline svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

.art_img_content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.advanced-timeline {
  padding: 0px;
}

.timeline-container {
  display: flex; /* 使时间点和内容区域左右排列 */
}

.timeline {
  margin-left: 0px;
  width: 48px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}

.timeline-item {
  display: flex;
  align-items: center;
  flex-grow: 1; /* 让节点在容器内均匀分布 */
}

.timeline-item {
  min-height: 10px; /* 设置最小高度 */
  max-height: 15px; /* 设置最大高度 */
}

.timeline-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.timeline-dot.no-content {
  opacity: 0.5;
  cursor: not-allowed;
  width: 5px;
  height: 5px;
}

.timeline-dot.uploaded {
  background-color: #3d44ad; /* Green */
}

.timeline-dot.late {
  background-color: #a37a00; /* Orange */
}



.timeline-dot.missed {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-dot.filler {
  background-color: #B0BEC5; /* Grey */
}

.timeline-dot.active {
  border: 2px solid #f904ea; /* Green border for active */
}

.timeline-content {
  display: flex;
  flex-direction: column;
}

.active-line {
  width: 100%; /* 横线的宽度 */
  height: 2px; /* 横线的高度 */
  background-color: #4CAF50; /* 横线的颜色 */
  margin-top: 5px; /* 横线与时间戳之间的间距 */
}

.content-display {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: calc(100% - 48px);
  position: relative;
}

.pre_btn_style {
  position: absolute;
  z-index: 10;
  top: 0px;
  background: #363636;
  border: none;
  height: 41px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.next_btn_style {
  position: absolute;
  z-index: 10;
  bottom: 0px;
  background: #363636;
  border: none;
  height: 41px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.next_btn_style:hover {
  opacity: 1;
}
.pre_btn_style:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .next_btn_style {
    position: absolute;
    z-index: 0;
  }
}

.content-container {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

button {
  margin: 10px; /* 按钮间距 */
  padding: 10px; /* 按钮内边距 */
}
</style>

<template>
  <el-container style="height: 100vh;" class="theme_bg">
    <!-- 侧边栏菜单 -->
    <el-aside class="sidebar set_side">
      <el-menu
          default-active="EditProfile"
          class="el-menu-vertical-demo theme_bg"
          text-color="#fff"
          active-text-color="#409EFF"
          @select="handleMenuSelect"
      >
        <el-menu-item index="EditProfile">
          <i class="el-icon-user-solid"></i>
          <span>个人资料</span>
        </el-menu-item>
        <el-menu-item index="PasswordSet">
          <i class="el-icon-bell"></i>
          <span>安全与密码</span>
        </el-menu-item>

        <el-menu-item index="wechatBind">
          <i class="el-icon-bell"></i>
          <span>微信授权绑定</span>
        </el-menu-item>
        <el-menu-item index="PhoneBind">
          <i class="el-icon-bell"></i>
          <span>手机号绑定</span>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 主内容区域 -->
    <el-container>
<!--      <el-header class="content-header">-->
<!--        <div>{{ headerTitle }}</div>-->
<!--      </el-header>-->
      <el-main class="content-body">
        <!-- 使用 v-if 显示不同的组件 -->
        <div v-if="activeComponent === 'EditProfile'">
          <EditProfile />
        </div>
        <div v-if="activeComponent === 'PasswordSet'">
          <el-header class="content-header">
            <div>{{ headerTitle }}</div>
          </el-header>
          <PasswordReset/>
        </div>
        <div v-if="activeComponent === 'wechatBind'">
          <el-header class="content-header">
            <div>{{ headerTitle }}</div>
          </el-header>
          <WechatBind />
        </div>
        <div v-if="activeComponent === 'PhoneBind'">
          <el-header class="content-header">
            <div>{{ headerTitle }}</div>
          </el-header>
          <HandleGetCaptcha/>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import EditProfile from '@/components/EditProfile.vue';
import PasswordReset from '@/components/PasswordReset.vue';
import WechatBind from '@/components/WechatBind.vue';
import HandleGetCaptcha from "@/components/handleGetCaptcha.vue";
// import Notifications from '@/components/Notifications.vue';
// import PrivacySettings from '@/components/PrivacySettings.vue';

export default {
  name: 'UserProfile',
  components: {
    HandleGetCaptcha,
    EditProfile,
    PasswordReset,
    WechatBind
    // PrivacySettings,
  },
  data() {
    return {
      activeComponent: 'EditProfile', // 默认显示编辑主页
      headerTitle: '编辑主页', // 标题对应的文字
    };
  },
  methods: {
    handleMenuSelect(index) {
      this.activeComponent = index;
      switch (index) {
        case 'EditProfile':
          this.headerTitle = '编辑主页';
          break;
        case 'PasswordSet':
          this.headerTitle = '修改密码';
          break;
        case 'wechatBind':
          this.headerTitle = '微信授权绑定';
          break;
        case 'PhoneBind':
          this.headerTitle = '手机号授权绑定';
          break;
      }
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo li.el-menu-item {
  border-radius: 8px;
}
.el-menu-vertical-demo li.el-menu-item:hover{
  background-color: var(--hover-li);
}
.el-menu-vertical-demo li.el-menu-item.is-active {
  background-color: var(--hover-li);
}
.el-menu{
  border-right: none !important;
}
.set_side{
  width: 200px;
  height: 100vh;
  border-right: 1px solid #3f3f3f;
  padding: 10px;
}
.sidebar {
  background-color: var(--background-color);
  color: #fff;
}

.content-header {
  font-size: 19px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 40px;
}

.content-body {
  padding: 20px;
}
</style>

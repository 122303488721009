<template>
  <div class="follow-component">
    <!-- 用户头像和基本信息 -->
    <div class="header" @click="goUser(user)" style="cursor: pointer;">
      <el-avatar :src="user.avatar?user.avatar:ava_bg" size="large"/>
      <div class="user-info">
        <p class="user-name">{{ user.name }}
          <el-icon><i class="el-icon-check"></i></el-icon>
        </p>
        <p class="user-fullname">{{ user.fullName }}</p>
      </div>
    </div>

    <!-- 数据统计部分 -->
    <div class="stats">
      <div class="stat-item">
        <p>{{ user.plans_count }}</p>
        <p>作品</p>
      </div>
      <div class="stat-item">
        <p>{{ user.followers_count }}</p>
        <p>粉丝</p>
      </div>
      <div class="stat-item">
        <p>{{ user.following_count }}</p>
        <p>已关注</p>
      </div>
    </div>

    <!-- 用户图片展示部分 -->
    <div class="user-images" v-if="user.recent_nodes.length > 0">
      <img style="cursor: pointer;" v-for="(item, index) in user.recent_nodes" :key="index" :src="'https://bbs-oss.realtime.xyz/'+item.cover"
           class="image-item" @click="go_detail(item)"/>
    </div>
    <div v-else class="user-images_none">
      <svg aria-label="Reels" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="40" role="img"
           viewBox="0 0 24 24" width="40"><title>Reels</title>
        <line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="2.049" x2="21.95"
              y1="7.002" y2="7.002"></line>
        <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              x1="13.504" x2="16.362" y1="2.001" y2="7.002"></line>
        <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              x1="7.207" x2="10.002" y1="2.11" y2="7.002"></line>
        <path
            d="M2 12.001v3.449c0 2.849.698 4.006 1.606 4.945.94.908 2.098 1.607 4.946 1.607h6.896c2.848 0 4.006-.699 4.946-1.607.908-.939 1.606-2.096 1.606-4.945V8.552c0-2.848-.698-4.006-1.606-4.945C19.454 2.699 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.546 2 5.704 2 8.552Z"
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
        <path
            d="M9.763 17.664a.908.908 0 0 1-.454-.787V11.63a.909.909 0 0 1 1.364-.788l4.545 2.624a.909.909 0 0 1 0 1.575l-4.545 2.624a.91.91 0 0 1-.91 0Z"
            fill-rule="evenodd"></path>
      </svg>
      还没发布作品
    </div>

    <!-- 按钮部分，动态切换关注和已关注按钮 -->
    <div class="action-buttons">
      <el-button
          :class="userData.is_followed_by_me ? 'message_btn' : 'message_btn_none'"
          type="primary"
          size="large"
          class=""
          @click="message_to_user(userData)"
      >
        发送消息
      </el-button>
      <el-button
          :class="userData.is_followed_by_me ? 'followed_style' : 'unfollowed_style'"
          :type="userData.is_followed_by_me ? 'info' : 'primary'"
          size="large"
          class="follow-button"
          @click="toggleFollow">
        {{ userData.is_followed_by_me ? '已关注' : '关注' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import {userFollowRequest} from "@/api/api";
import {ElMessage} from "element-plus";
import {mapActions} from "pinia";
import {useMessageStore} from "@/stores/messageStore";
import ava_bg from "@/assets/ava_bg.png";
export default {
  name: 'FollowComponent',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: this.user,
      ava_bg:ava_bg,
    };
  },
  methods: {
    ...mapActions(useMessageStore, ['selectChat', 'setUser']),
    message_to_user(user) {
      this.selectChat(user.id);
      this.setUser(user);
      this.$router.push({name: 'MessagePage'});
    },
    goUser(user) {
      this.$router.push({name: 'User', params: {username: user.user_name}});
    },
    //跳转详情
    go_detail(item) {
      this.$router.push({name: 'TimeLine', params: {id: item.nodes[0].id}});
    },
    // 点击关注按钮，设置为已关注状态
    toggleFollow() {
      let type = 1;
      if (this.userData.is_followed_by_me) {
        //已经关注提交取消
        type = 0;
      } else {
        type = 1;
      }
      userFollowRequest(this.userData.id, {type: type}).then(response => {
        if (response.code === 200) {
          this.userData.is_followed_by_me = !this.userData.is_followed_by_me;
          ElMessage({
            message: type === 1 ? '已关注' : '已取消关注',
            type: type === 1 ? 'success' : 'info',
          });
        } else {
          ElMessage({
            message: response.message,
            type: 'error',
          });
        }
      });
    },

  },
};
</script>

<style scoped>
.message_btn_none {
  display: none;
}

.message_btn {
  display: block;
  width: calc(50% - 5px);
  border: none;
}

.message_btn:hover {
}

.follow-button {
  border: none;

  width: calc(50% - 5px);
}



.follow-button:hover {

}

.follow-component {
  width: 300px;
  padding: 10px 0px;
  background-color: var(--el-popover-background);
  border-radius: 8px;
  //box-shadow: 0 2px 13px rgb(255 255 255 / 44%);
  box-sizing: border-box;
  margin-left: -12px;
  margin-top: -20px;
  height: auto;
}

/* 用户头像和信息布局 */
.header {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.user-info {
  margin-left: 10px;
}

.user-name {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.user-fullname {
  font-size: 13px;
  color: #888;
  margin-top: -9px;
}

/* 统计信息部分 */
.stats {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.stat-item {
  text-align: center;
  width: 33.333%;
}

.stat-item p:first-child {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -13px;
}

/* 用户图片展示部分 */
.user-images {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 1%;
}

.user-images_none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0px 25px 0px;
  gap: 10px;
}

.image-item {
  width: 32.6%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0px;
  aspect-ratio: 1 / 1;
}

/* 按钮布局 */
.action-buttons {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}


</style>

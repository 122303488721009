<template>
  <div class="calendar-container">
    <div class="calendar-header">
      <div @click="changeMonth(-1)" class="calendar-nav-btn">
        <!-- 左箭头 SVG -->
        <svg
            t="1732508630460"
            class="icon"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2326"
            width="30"
            height="30"
        >
          <path
              d="M56.889 512c0-251.364 203.747-455.111 455.111-455.111S967.111 260.636 967.111 512 763.364 967.111 512 967.111 56.889 763.364 56.889 512z m532.11 179l-179-179 179-179a28.444 28.444 0 0 0-40.22-40.221l-199.111 199.11a28.444 28.444 0 0 0 0 40.221l199.11 199.111A28.444 28.444 0 0 0 589 691.001z"
              fill=""
              p-id="2327"
          ></path>
        </svg>
      </div>
      <div class="monthLabel">
        {{ monthLabel }}
        <div class="total-days">
          预计总天数：{{ totalDays }} 天
        </div>
      </div>
      <div @click="changeMonth(1)" class="calendar-nav-btn">
        <!-- 右箭头 SVG -->
        <svg
            t="1732508696392"
            class="icon"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2962"
            width="30"
            height="30"
        >
          <path
              d="M967.111 512c0 251.364-203.747 455.111-455.111 455.111S56.889 763.364 56.889 512 260.636 56.889 512 56.889 967.111 260.636 967.111 512z m-532.11-179l179 179-179 179a28.444 28.444 0 0 0 40.22 40.221l199.111-199.11a28.444 28.444 0 0 0 0-40.221l-199.11-199.111A28.444 28.444 0 0 0 435 332.999z"
              fill=""
              p-id="2963"
          ></path>
        </svg>
      </div>
    </div>
    <!-- 显示预计总天数 -->

    <div class="calendar-body">
      <!-- 星期标题行 -->
      <div class="calendar-weekdays">
        <div class="calendar-weekday">星期日</div>
        <div class="calendar-weekday">星期一</div>
        <div class="calendar-weekday">星期二</div>
        <div class="calendar-weekday">星期三</div>
        <div class="calendar-weekday">星期四</div>
        <div class="calendar-weekday">星期五</div>
        <div class="calendar-weekday">星期六</div>
      </div>

      <!-- 日历日期 -->
      <div v-for="(week, weekIndex) in calendarDates" :key="weekIndex" class="calendar-week">
        <div
            v-for="(date, dayIndex) in week"
            :key="dayIndex"
            :class="['calendar-date', isMarked(date) ? 'marked' : '']"
        >
          <!-- 处理空白单元格 -->
          <span v-if="date">{{ date.split('-')[2] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  name: 'TimeComponent',
  props: {
    startDate: {
      type: String,
      required: true, // startDate 是可选的
      default: '',
    },
    uploadCount: {
      type: Number,
      required: true,
    },
    intervalDays: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      baseDate: dayjs.utc(), // 基准日期，用于计算标记日期
      currentDate: dayjs.utc(), // 当前显示月份的日期
      markedDates: [],
      calendarDates: [],
      monthLabel: null,
      totalDays: 0,
      timezone: 'utc',
    };
  },
  mounted() {
    this.timezone=dayjs.tz.guess();
    this.initBaseDate();
  },
  watch: {
    startDate: {
      immediate: true,
      handler(newStartDate) {
        let parsedDate = null;
        console.log('startDate:', newStartDate);
        if (newStartDate && newStartDate.trim()!== '') {
          parsedDate = dayjs.tz(newStartDate).startOf('day'); // 解析并设置为当天的起始时间（00:00:00）
          console.log('startDate:', parsedDate.format('YYYY-MM-DD'));
        }

        if (parsedDate) {
          this.baseDate = parsedDate;
        } else {
          this.baseDate = dayjs.tz().startOf('day'); // 使用今天的日期起始时间（00:00:00）
        }

        // 设置 currentDate 为 baseDate 的月份
        this.currentDate = this.baseDate.clone();

        this.generateMarkedDates();
        this.generateCalendarDates();
      },
    },
    uploadCount: {
      immediate: true,
      handler() {
        this.generateMarkedDates();
      },
    },
    intervalDays: {
      immediate: true,
      handler() {
        this.generateMarkedDates();
      },
    },
  },
  created() {
    // 初始生成由 watch 处理
  },
  methods: {
    initBaseDate() {
      const defaultDate = dayjs.tz().startOf('day');
      this.baseDate = defaultDate;
      this.currentDate = defaultDate;
    },
    /**
     * 格式化月份标签为 "YYYY年MM月"
     */
    formatMonthLabel(date) {
      return date.format('YYYY年MM月');
    },
    /**
     * 生成标记日期数组
     */
    generateMarkedDates() {
      if (this.uploadCount && this.intervalDays) {
        const dates = [];
        let currentDate = this.baseDate.clone(); // 使用 baseDate 作为起点
        for (let i = 0; i < this.uploadCount; i++) {
          const formattedDate = currentDate.format('YYYY-MM-DD');
          dates.push(formattedDate);
          currentDate = currentDate.add(this.intervalDays, 'day');
        }
        this.markedDates = dates;
        this.totalDays = this.intervalDays * (this.uploadCount - 1) + 1;
      }
    },
    /**
     * 生成日历日期数组，按周分组
     */
    generateCalendarDates() {
      if (!this.currentDate) {
        this.currentDate = dayjs.tz().startOf('day');
      }
      // 通过dayjs获取当前月份的第一天
      const firstDayOfMonth = this.currentDate.startOf('month').day(); // 0 (星期日) 到 6 (星期六)
      const lastDayOfMonth = this.currentDate.endOf('month');
      const daysInMonth = lastDayOfMonth.date();

      this.monthLabel = this.formatMonthLabel(this.currentDate);

      // 直接使用 firstDayOfMonth，不再将星期日调整为7
      const firstWeekday = firstDayOfMonth;

      const weeks = [];
      let week = [];

      // 添加前导空白单元格
      for (let i = 0; i < firstWeekday; i++) { // 修改循环条件为 i < firstWeekday
        week.push(null);
      }

      for (let day = 1; day <= daysInMonth; day++) {
        const date = this.currentDate.date(day).format('YYYY-MM-DD');
        week.push(date);

        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }

      // 添加后导空白单元格
      if (week.length > 0) {
        while (week.length < 7) {
          week.push(null);
        }
        weeks.push(week);
      }

      this.calendarDates = weeks;
    },

    /**
     * 切换月份
     * @param {number} offset - 偏移量，-1为上一个月，1为下一个月
     */
    changeMonth(offset) {
      this.currentDate = this.currentDate.add(offset, 'month');
      this.monthLabel = this.currentDate.format('YYYY年MM月');
      this.generateCalendarDates();

    },
    /**
     * 检查日期是否被标记
     * @param {string|null} date - 日期字符串 "YYYY-MM-DD" 或 null
     * @returns {boolean}
     */
    isMarked(date) {
      return date && this.markedDates.includes(date);
    },
  },
};
</script>

<style scoped>
.calendar-container {
  font-family: 'Arial', sans-serif;
  width: 100%;
  max-width: 320px;
  background-color: var(--time-component-container);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: var(--time-component-header);
  font-size: 16px;
  font-weight: 600;
  color: #333;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.calendar-nav-btn {
  font-size: 18px;
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-nav-btn svg {
  fill: var(--btn-time-component);
}

.calendar-nav-btn:hover {
  color: #007BFF;
}

.total-days {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--text-color);
  height: 29px;
  margin-top: 0px;
  line-height: 35px;
}

.monthLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calendar-body {
  padding: 12px 5px;
}

.calendar-weekdays {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.calendar-weekday {
  width: 40px; /* 确保与日期单元格宽度一致 */
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 4px;
}

.calendar-week {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.calendar-date {
  width: 40px; /* 确保与星期标题宽度一致 */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: var(--time-component-header);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.calendar-date.marked {
  background-color: #ff6347;
  color: white;
}

.calendar-date:hover {
  background-color: #a12803;
}

.calendar-date span {
  display: block;
}

@media (max-width: 400px) {
  .calendar-container {
    max-width: 100%;
  }

  .calendar-weekday,
  .calendar-date {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}
</style>

<template>
  <div id="app">
    <router-view/>
    <CreatePostFlow />
    <NewPlanModal />
    <EditPlanModal />
    <NewPlanMenuModal />
    <FloatingMenu/>
    <EditPost/>
  </div>
</template>

<script>
import { modalStore } from './stores/modalStore';
import CreatePostFlow from './components/CreatePostFlow.vue';
import NewPlanModal from './components/NewPlanModal.vue';
import EditPlanModal from './components/EditPlanModal.vue';
import NewPlanMenuModal from './components/NewPlanMenuModal.vue';
import FloatingMenu from "@/components/FloatingMenu.vue";
import EditPost from "@/components/EditPost.vue";
export default {
  name: 'App',
  components: {
    FloatingMenu,
    CreatePostFlow,
    NewPlanModal,
    NewPlanMenuModal,
    EditPlanModal,
    EditPost,
  },
  setup() {
    const commModalStore = modalStore();
    return {
      commModalStore,
    };
  },


};
</script>

<style src="./assets/theme.css"></style>

<style>
html, body {
  min-width: 768px;
}
@media (max-width: 768px) {
  html, body {
    width: 768px;
  }
}
.theme_bg{
  background-color: var(--background-color);
  color: var(--text-color);
}
/* 定义过渡动画 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.el-message--success .el-message__content {
  color: #000000 !important;
  overflow-wrap: break-word;
}

/* 全局隐藏滚动条 */

.timeline-container-line {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.timeline-container-line::-webkit-scrollbar {
  display: none;
}
.step3_all_left {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.step3_all_left::-webkit-scrollbar {
  display: none;
}
/* 特定 div 显示滚动条 */
.img_container {
  scrollbar-width: auto !important;  /* Firefox */
  -ms-overflow-style: auto !important; /* IE */
}

.img_container::-webkit-scrollbar {
  display: block !important;  /* 显示滚动条 */
}



</style>
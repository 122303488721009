<template>
  <el-dialog
      v-model="feedbackVisible"
      title="意见反馈"
      :before-close="handleBeforeClose"
      :modal="true"
      class="feedbackmodal"
  >
    <el-form :model="form" ref="feedbackForm" label-width="80px" class="feedback-form">
      <el-form-item label="标题" prop="title" :rules="titleRules">
        <el-input v-model="form.title" placeholder="请输入标题" :maxlength="500" show-word-limit></el-input>
      </el-form-item>

      <el-form-item label="内容" prop="content" :rules="contentRules">
        <el-input
            v-model="form.content"
            type="textarea"
            placeholder="请输入反馈内容"
            rows="4"
            :maxlength="500" show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="上传图片" prop="images">
        <!-- 图片上传组件 -->
        <el-upload
            action="#"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :auto-upload="false"
            :file-list="form.images"
            :on-change="handleImageChange"
            :before-upload="beforeUpload"
            accept="image/*"
            multiple
        >
          <template #trigger>
            <el-icon><Plus /></el-icon>
          </template>
        </el-upload>

        <!-- 图片预览弹出框 -->
        <el-dialog v-model="dialogVisible" width="50%">
          <img :src="dialogImageUrl" alt="图片预览" style="width: 100%" />
        </el-dialog>
      </el-form-item>

      <div class="dialog-footer">
        <el-button type="info" @click="handleBeforeClose">取消</el-button>
        <el-button type="primary" @click="submitFeedback">提交</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { Plus } from '@element-plus/icons-vue';
import {ElLoading, ElMessage, ElMessageBox} from 'element-plus';
import {feedbackRequest} from "@/api/api";

export default {
  components: {
    Plus,
  },
  data() {
    return {
      feedbackVisible: false, // 控制模态框显示
      form: {
        title: '',
        content: '',
        images: [], // 上传的图片列表
      },
      dialogVisible: false, // 控制图片预览弹窗
      dialogImageUrl: '', // 当前预览的图片 URL
      titleRules: [{ required: true, message: '请输入标题', trigger: 'blur' }],
      contentRules: [{ required: true, message: '请输入反馈内容', trigger: 'blur' }],
      acceptedFormats: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'], // 允许的图片格式
    };
  },
  methods: {
// 上传前过滤文件类型
    beforeUpload(file) {
      const isImage = file.type.startsWith('image/'); // MIME 类型判断
      const fileExtension = file.name.split('.').pop().toLowerCase(); // 获取文件扩展名

      if (!isImage || !this.acceptedFormats.includes(fileExtension)) {
        ElMessage.error('只允许上传图片文件！(格式: JPG, JPEG, PNG, GIF, BMP, WEBP)');
        return false; // 阻止上传
      }
      return true; // 允许上传
    },
    // 关闭模态框前的提示
    handleBeforeClose() {
      if (this.form.title || this.form.content || this.form.images.length > 0) {
        ElMessageBox.confirm(
            '您正在编辑内容，确认要关闭吗？关闭后已填写的内容将丢失。',
            '提示',
            {
              confirmButtonText: '确认关闭',
              cancelButtonText: '继续编辑',
              type: 'info',
            }
        )
            .then(() => {
              this.resetForm();
              this.feedbackVisible = false;
            })
            .catch(() => {
              // 用户取消关闭操作
            });
      } else {
        this.resetForm();
        this.feedbackVisible = false;
      }
    },
    // 提交反馈
    submitFeedback() {
      this.$refs.feedbackForm.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append('title', this.form.title);
          formData.append('content', this.form.content);
          this.form.images.forEach((file, index) => {
            formData.append(`images[${index}]`, file.raw);
          });
          const loading = ElLoading.service({
            lock: true,
            text: '提交中',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          feedbackRequest(formData).then(() => {
            ElMessage.success('反馈提交成功！感谢您的意见。');
            this.resetForm();
            this.feedbackVisible = false;
          }).finally(() => {
            this.$nextTick(() => {
              loading.close()
            });
          });

        } else {
          ElMessage.error('请填写必填项：标题和内容');
        }
      });
    },
    // 重置表单数据
    resetForm() {
      this.form = {
        title: '',
        content: '',
        images: [],
      };
    },
    // 移除图片
    handleRemove(file) {
      const index = this.form.images.findIndex((item) => item.uid === file.uid);
      if (index !== -1) this.form.images.splice(index, 1);
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url || URL.createObjectURL(file.raw);
      this.dialogVisible = true;
    },
    // 图片上传变化
    handleImageChange(file, fileList) {
      this.form.images = fileList;
    },
  },
};
</script>

<style>

.el-message-box .el-button[type="button"]:first-child {
  background-color: #363636;
  color: #fff;
  border: none;
}
.el-message-box{
  background-color: var(--background-color) !important;
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.2) !important;
}
.el-message-box__message p{
  color: var(--text-color) !important;
}
.el-overlay.is-message-box {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.feedbackmodal{
  width: 600px !important;
}
@media (max-width: 600px) {
  .feedbackmodal{
    width: 95% !important;
  }
}
.el-upload-list--picture-card .el-upload-list__item {
  background-color: var(--background-color) !important;
}
.el-upload-list__item.is-success:hover {
  background-color: var(--background-color) !important; /* 修改为你需要的颜色 */
}
.el-upload--picture-card {
  background-color: var(--background-color) !important;
}
</style>

<style scoped>

.dialog-footer {
  text-align: center;
}

</style>

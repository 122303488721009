<template>
  <div class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main class="set_main">
        <ChatComponent/>


      </el-main>
    </el-container>

    <!--    底部菜单组件-->
    <BottomMenu/>
  </div>
</template>

<script>

// import AvatarUploader from "@/components/AvatarUploader.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import ChatComponent from "@/components/ChatComponent.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";

export default {
  name: 'MessagePage',
  components: {
    LeftSidebar,
    ChatComponent,
    BottomMenu,

  },
  data() {
    return {

    };
  },


};
</script>

<style scoped>
.set_main{
  height: 100vh;
  padding: 0px !important;
}



</style>
<template>
<!--  <div v-infinite-scroll="load_more">-->
  <div >
    <div class="ins-post-container" v-for="(item, index) in post_list" :key="item.id">
      <!-- 头部区域：头像、用户名、更多图标 -->
      <div class="ins-header">
        <UserNamePopover :user="item.plan.user" :post="item" @click="goUser(item.plan.user)" />
        <div class="post_date" v-if="item.plan.is_independent === 0">
<!--          <div class="post_date_1" v-if="item.plan.status === 1">-->
<!--            {{ item.node_position }}/{{ item.plan.update_count }}-->
<!--          </div>-->
          <div class="post_date_2" v-if="item.plan.status === 0">新创建</div>
          <div class="post_date_2" v-if="item.plan.status === 1">更新中</div>
          <div class="post_date_comp" v-if="item.plan.status === 2">完成</div>
<!--          <div class="post_date_1" v-if="item.plan.status === 0">{{ item.total_nodes }}</div>-->
        </div>
        <div class="post_date" v-else>
          <div class="post_date_2" v-if="item.plan.status === 2">Completed!</div>
        </div>
      </div>

      <!-- 图片轮播 -->
      <el-carousel
          style="cursor: pointer"
          indicator-position="outside"
          height="400px"
          class="carousel"
          @click="go_detail(item)"
          :arrow="item.medias && item.medias.length > 1 ? 'always' : 'never'"
      >
        <template v-if="item.medias && item.medias.length > 0">
          <el-carousel-item v-for="(media, index) in item.medias" :key="index">
            <template v-if="isVideo(media.media_url)">
              <video
                  :src="'http://bbs-oss.realtime.xyz/' + media.media_url"
                  class="post-media"
                  controls
                  muted="false"
                  loop="true"
                  playsinline
                  controlslist="nodownload noplaybackrate"
                  @contextmenu.prevent
                  disablePictureInPicture
                  @click.prevent
              ></video>
            </template>
            <template v-else>
              <img :src="'http://bbs-oss.realtime.xyz/' + media.media_url" class="post-media" />
            </template>
          </el-carousel-item>
        </template>
        <template v-else>
          <el-carousel-item v-for="(img, index) in images" :key="index">
            <img :src="img" class="post-image" />
          </el-carousel-item>
        </template>
      </el-carousel>

      <!-- 操作按钮：点赞、评论、收藏 -->
      <div class="ins-actions">
        <svg aria-label="取消赞" @click="toggleLike(item,index)"
             class="icon action-icon like-icon"
             :class="{ liked: item.is_liked_by_me, animate: isLikedAnimated }" v-if="item.is_liked_by_me"  fill="currentColor" height="24" role="img"
             viewBox="0 0 48 48" width="24"><title>取消赞</title>
          <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
        </svg>
        <svg aria-label="赞" @click="toggleLike(item,index)"
             class="icon action-icon like-icon"
             :class="{ liked: item.is_liked_by_me, animate: isLikedAnimated }" v-else fill="currentColor" height="24"
             role="img" viewBox="0 0 24 24" width="24"><title>赞</title>
          <path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path>
        </svg>


        <svg aria-label="评论" class="icon action-icon icon_size" @click="go_detail(item)" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>评论</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path></svg>
        <svg aria-label="分享" class="icon action-icon icon_size" @click="copyLink(item)" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>分享</title><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon></svg>

        <el-icon :title="item.is_favorited_by_me ? '取消收藏' : '收藏'" @click="toggleCollect(item,index)" class="icon collect-icon icon_size" :class="{ collected: item.is_favorited_by_me }">
          <svg v-if="item.is_favorited_by_me" aria-label="移除" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>移除</title><path d="M20 22a.999.999 0 0 1-.687-.273L12 14.815l-7.313 6.912A1 1 0 0 1 3 21V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1Z"></path></svg>
          <svg  v-else aria-label="收藏" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>收藏</title><polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon></svg>
        </el-icon>
      </div>

      <!-- 点赞和描述 -->
      <div class="ins-content">
        <span class="likes-count">{{ item.likes }} 次赞</span>
        <span class="description"><strong>{{ item.plan.user.name }}:</strong> <TextOverflow :text="item.description" :maxLines="2"/></span>
      </div>

      <!-- 评论区 -->
      <div class="ins-comments" @click="go_detail(item)">
        <span class="view-comments">查看全部 {{ item.comment_count}} 条评论</span>
      </div>
    </div>

    <p v-if="noMore">没有更多数据了</p>
    <p v-else class="display_center" style="text-align: center; cursor: pointer; position: relative;" @click="load_more">
      查看更多数据
      <span v-if="loading" class="loading-spinner"></span>
    </p>
  </div>
</template>

<script>
import {ElLoading, ElMessage} from "element-plus";
import UserNamePopover from "@/components/UserNamePopover.vue";
import { getPostListRequest, postFavoriteRequest, postLikeRequest } from "@/api/api";
import TextOverflow from "@/components/TextOverflow.vue";
import {mapState} from "pinia";
import {StatusStore} from "@/stores/StatusStore";

export default {
  name: "InsPost",
  components: {
    TextOverflow,
    UserNamePopover,
  },
  data() {
    return {
      loading: true, // 加载状态
      noMore: false, // 是否还有更多数据
      post_list: [], // 数据列表
      images: [require("@/assets/hwk.png")],
      isLikedAnimated: false, // 点赞动画状态
      page:1,
    };
  },
  computed: {
    ...mapState(StatusStore, ['need_get_list']),
    need_get_list_data() {
      return this.need_get_list;
    },
  },
  mounted() {
    this.page=1;
    this.getData();
  },
  watch: {
    need_get_list_data(newVal) {
      console.log("监听是否需要刷新："+newVal)
      if(newVal){
        this.loading = true;
        this.page=1;
        this.post_list=[];
        this.getData(false);
      }

    },
  },
  methods: {
    // 加载更多数据
    load_more() {
      if (!this.loading && !this.noMore) {
        this.loading = true;
        this.page++;
        this.getData(false);
      }
    },
    isVideo(url) {
      const videoExtensions = ["mp4", "webm", "ogg"];
      const extension = url.split(".").pop().toLowerCase();
      return videoExtensions.includes(extension);
    },
    // 获取数据
    getData(showOverlay = true) {
      let loadingInstance;
      if (showOverlay) {
        loadingInstance = ElLoading.service({
          lock: true,
          text: '加载中...',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      }
      getPostListRequest({page:this.page,limit:20}).then((response) => {
        if (response.code === 200) {
          this.loading = false;
          this.noMore = response.data.length === 0; // 判断是否还有数据
          this.post_list = [...this.post_list, ...response.data];

          // 通知父组件数据加载完成
          this.$emit("dataLoaded");
        } else {
          this.loading = false; // 请求失败也取消loading状态
        }
      }).finally(() => {
        if (showOverlay && loadingInstance) {
          loadingInstance.close();
        }
      });
    },
    goUser(user) {
      this.$router.push({ name: "User", params: { username: user.user_name } });
    },
    go_detail(item) {
      this.$router.push({ name: "TimeLine", params: { id: item.id } });
    },
    toggleLike(item, index) {
      let type = item.is_liked_by_me ? 0 : 1;
      this.isLikedAnimated = true;
      postLikeRequest(item.id, { type }).then((response) => {
        if (response.code === 200) {
          item.is_liked_by_me = !item.is_liked_by_me;
          this.isLikedAnimated = false;
          this.post_list[index].likes = response.data.likes;
          ElMessage({
            message: type === 1 ? "已点赞" : "取消点赞",
            type: type === 1 ? "success" : "info",
          });
        }else{
          ElMessage({
            message:response.message ,
            type: "info",
          });
        }
      });
    },
    toggleCollect(item, index) {
      let type = item.is_favorited_by_me ? 0 : 1;
      postFavoriteRequest(item.id, { type }).then((response) => {
        if (response.code === 200) {
          item.is_favorited_by_me = !item.is_favorited_by_me;
          this.post_list[index].favorites = response.data.favorites;
          ElMessage({
            message: type === 1 ? "已收藏" : "取消收藏",
            type: type === 1 ? "success" : "info",
          });
        }else{
          ElMessage({
            message:response.message ,
            type: "info",
          });
        }
      });
    },
    copyLink(item) {
      const link = window.location.host + "/Post/" + item.id;
      navigator.clipboard
          .writeText(link)
          .then(() => {
            ElMessage({ message: "链接已复制", type: "success" });
          })
          .catch(() => {
            ElMessage({ message: "复制失败，请重试", type: "error" });
          });
    },
  },
};
</script>




<style scoped>

.ins-comment-input .el-button>span {
  color: var(--text-color) !important;
}
.post-media {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
@media (max-width: 540px) {
  .pl_content{
    max-width: 240px !important;
    height: auto;
    word-break: break-all;
  }
}
@media (max-width: 400px) {
  .pl_content{
    max-width: 220px !important;
    height: auto;
    word-break: break-all;
  }
}
.comment_username{
  max-width: 100px;
  word-break: break-all;
  margin-right: 5px;
  color: var(--text-color);
  font-weight: 600;
}
.pl_content{
  max-width: 400px;
  height: auto;
  word-break: break-all;
}
.x_icon{
  margin: 0px 5px;
}
.up_time{
  color:#909399;
  font-size: 12px;
  font-weight: normal;
}
.icon_size {
  width: 24px !important;
  height: 24px !important;
}

@media (max-width: 540px) {
  .ins-post-container {
    max-width: 540px;
    width: 100% !important;
    border-radius: 5px;
    margin: 20px auto;
    background-color: var(--background-color);
  }
}

.ins-post-container {
  width: 500px;
  border-radius: 5px;
  margin: 10px auto;
  background-color: var(--background-color);
}

.ins-header {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 0px;
  position: relative;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .ins-header{
    padding: 15px 10px;
  }
  .ins-content{
    padding: 15px 10px !important;
  }
  .ins-comments{
    padding: 15px 10px !important;
  }

}
.post_date_2 {
  font-size: 12px;
  color: #00c37f;
}
.post_date_comp {
  font-size: 12px;
  color: #b28e03;
}
.post_date_1 {
  text-align: right;
  font-size: 15px;
  color: #00c37f;
}
.username {
  margin-left: 15px;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.more-wrapper {
  position: relative;
  margin-left: auto;
}

.more-icon-container {
  padding: 10px;
  cursor: pointer;
}

.more-options {
  position: absolute;
  top: 30px;
  right: 0;
  border: 1px solid #dbdbdb;
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 8px;
  box-shadow: var(--content-box-shadow);
  z-index: 10;
  width: 90px;
  transition: opacity 0.3s ease;
}

.more-option-item {
  display: block;
  padding: 5px 0;
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: #000;
}
.more-option-item:hover{
  color:#909399 !important;
}

.carousel {
  margin: 0 auto;
}

.post-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-bottom: 1px solid #dbdbdb;
}

.ins-actions {
  display: flex;
  padding: 10px 0px;
}
@media (max-width: 767px) {
  .ins-actions {
    padding: 10px 10px;
  }
}

.icon {
  cursor: pointer;
  font-size: 28px;
  margin-right: 15px;
}

.like-icon {
  transition: color 0.3s, transform 0.3s;
}

.liked {
  color: rgb(253, 48, 64);
}

.animate {
  animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.comment-like-icon {
  margin-left: 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.comment-like-icon.liked {
  color: rgb(253, 48, 64);
}

.collect-icon {
  margin-left: auto;
  margin-right: 0px !important;
}

.collected {
  color: var(--text-color);
}

.ins-content {
  padding: 10px 0px;
  font-size: 12px;
}

.likes-count {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.description {
  display: block;
}

.ins-comments {
  padding: 10px 0px;
  border-top: none;
  font-size: 12px;
}

.view-comments {
  color: #7b7878;
  margin-bottom: 7px;
  font-size: 13px;
  width: 100%;
  float: left;
  cursor: pointer;
}

.comment {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  float: left;
  width: 100%;
  position: relative;
}
.pl_icon {
  position: absolute;
  right: 0px;
  top: 2px;
}

.ins-comment-input {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #dbdbdb;
}

.el-input {
  flex: 1;
}

.el-button {
  margin-left: 10px;
}
</style>

<template>
  <div>
    <!-- 显示部分文字，根据 isExpanded 状态控制是否展开或收起 -->
    <div :class="['text-clamp', { 'expanded': isExpanded }]" ref="textContainer">
      {{ text }}
    </div>

    <!-- 控制显示状态的按钮 -->
    <div class="check_more_btn" v-if="isTextOverflow" @click="toggleExpand">
      {{ isExpanded ? '收起' : '查看更多' }}
      <svg v-if="isExpanded" t="1735822283851" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7609" width="16" height="16"><path d="M838.116 732.779 877.7 693.195 511.979 327.549 146.3 693.195 185.883 732.779 512.003 406.652Z" p-id="7610"></path></svg>
      <svg v-else t="1735822360664" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8699" width="16" height="16"><path d="M185.884 327.55 146.3 367.133 512.021 732.779 877.7 367.133 838.117 327.55 511.997 653.676Z" p-id="8700"></path></svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    maxLines: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      isExpanded: false, // 控制文本是否展开
      isTextOverflow: false, // 控制是否显示查看更多按钮
    };
  },
  watch: {
    // 监听文本内容，判断是否超出 maxLines
    text() {
      this.checkOverflow();
    },
  },
  mounted() {
    this.checkOverflow();
  },
  methods: {
    // 检查文本是否超出指定行数
    checkOverflow() {
      const container = this.$refs.textContainer;
      if (container && container.scrollHeight > container.clientHeight) {
        this.isTextOverflow = true;
      }
    },
    // 切换展开和收起
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
.check_more_btn svg {
  fill: var(--text-color);
}
.check_more_btn {
  margin-top: 6px;
  color: var(--text-color);
  cursor: pointer;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}
.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* 默认限制三行 */
  height: auto;
  line-height: 1.5;
  text-overflow: ellipsis;
  word-wrap: break-word; /* 强制换行 */
  overflow-wrap: break-word;
}

.text-clamp.expanded {
  -webkit-line-clamp: unset; /* 展开时显示全部 */
}


</style>
